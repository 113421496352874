.full-width{
  width: 100%;
}
.pulls{
  &-left{
    float: left;
  }
  &-right{
    float: right;
  }
}
.no-decoration{
  text-decoration: none;
}
.no-outline{
  outline: none;
}
.list-inline{
  @include list-inline;
}
.txt-or{
  @extend .relative;
  @include set-margin(0 auto $margin-15);
  @include font-size($text-size);
  color: $primary-text;
  @extend .text-center;
  max-width: 80%;
  &:before{
    @extend .absolute;
    content: '';
    background: $divider;
    @extend .full-width;
    @include height(1px);
    @include position(11px,0,initial,initial);
    left: 0;
    top: 11px;
  }
  span{
    @include list-inline();
    background: $white;
    @include set-padding(0 $padding-10);
    @extend .relative;
    @include z-index(2);
  }
}
.shows{
  display: block;
}
.hides{
  display: none;
}
.img{
  @extend .full-width;
  height: auto;
}
.portlet{
  background-color: #fff !important;
  padding: 10px 15px ;
  /*border: 1px solid #2b9a5b;*/
  margin-bottom: 10px;
  border-radius: 4px !important;
  transition: all 0.25s ease 0s !important;
  /*box-shadow: 0px 1px 1px #2b9a5b;*/
  -webkit-box-shadow: 0 0 6px #999 !important;
  box-shadow: 0px 0px 6px rgb(153, 153, 153) !important;
  &-parrent{
    background-color: $white;
    @include set-padding(10px 15px);
    @include set-margin(0px 0px 10px 0px);
    @include box-shadow(0px 0px 6px rgb(153, 153, 153));
    @extend .child-transition;
  }
}
.no-boxshadow{
  box-shadow: $none;
}
.no-background{
  background: $none;
}
.no-opacity{
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
.opacity-1{
  filter: alpha(opacity=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.no-border{
  border: none;
}
.relative{
  position: $relative;
}
.absolute{
  position: $absolute;
}
.fixed{
  position: fixed;
}
.list-none{
  padding: 0;
  margin: 0;
  list-style: none;
}
.tooltip_templates{
  @extend .hides;
}
.not-imp-show{
  @extend .shows;
}
.primary-color{
  color: $primary-color;
  &-bg{
    background: $primary-color;
  }
  &-op-7{
    color: $primary-color-op-7;
  }
  &-op-7-bg{
    background: $primary-color-op-7;
  }
  &-mid{
    color: $primary-color-mid;
  }
  &-mid-bg{
    background: $primary-color-mid;
  }
}
.text-color{
  color: $text-color;
}
.custom_field_temp{
  @extend .shows;
  @extend .full-width;
  @extend .white-bg;
  color: $black;
}
.white{
  color: $white;
}
.white-bg{
  background: $white;
}
.black{
  color: $black;
}
.black-bg{
  background: $black;
}
.text-c-dark{
  color: $dark-text;
}
.d-table{
  display: table;
}
.fake-label{
  @extend .inline-b;
  @extend .v-align-middle;
  @extend .no-margin;
  @extend .white;
  cursor: pointer;
}
.progress-striped-green{
  background-color: $primary-color;
}
.progress-striped-brown{
  background-color: $secondry-color;
}
.spreadsheet{
  background-image: $spreadsheet;
  @include width(15px);
  @include height(19px);
  @extend .shows;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
  @include set-border(1px solid $divider);
  height: 40px;
  font-weight: 500;
}
.cookie-note{
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.48);
}
.checkbox{
  label{
    input[type="checkbox"]{
      @extend .hides;
      &:checked{
        &+.cr{
          >.cr-icon{
            -moz-transform: scale(1) rotateZ(0deg);
            -o-transform: scale(1) rotateZ(0deg);
            -ms-transform: scale(1) rotateZ(0deg);
            -webkit-transform: scale(1) rotateZ(0deg);
            transform: scale(1) rotateZ(0deg);
            opacity: 1;
          }
        }
      }
      &+.cr{
        >.cr-icon{
          -moz-transform: scale(3) rotateZ(-20deg);
          -o-transform: scale(3) rotateZ(-20deg);
          -ms-transform: scale(3) rotateZ(-20deg);
          -webkit-transform: scale(3) rotateZ(-20deg);
          transform: scale(3) rotateZ(-20deg);
          @extend .no-opacity;
          transition: all .3s ease-in;
        }
      }
    }
  }
  .cr{
    @extend .relative;
    @extend .inline-b;
    @include set-border(1px solid $primary-color);
    @include border-radius(.25em);
    @include width(16px);
    @include height(16px);
    @extend .pulls-right;
    cursor: pointer;
    .cr-icon{
      @extend .absolute;
      @include font-size(.8em);
      line-height: 0;
      @include set-position(50%,top);
      @include set-position(15%,right);
    }
  }
}
.input_temp{
  @include height($height);
}
.stars{
  @extend .inline-b;
  @include font-size(1px);
  @include set-padding($padding-5,left);
}
@media only screen and (min-width: 1024px){
  @include transition-with-condition(false);
}
@media only screen and (max-width: 800px){
  @include transition-with-condition(false);
}
.text-elipse{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.no-padding{
@include set-padding(0);
}
.no-scroll{
  overflow:hidden;
}
.scroll{
  overflow: auto;
}
.no-border-style{
  border-style: none;
}
.no-border-rad{
  border-radius: 0;
}
.margin-btm{
  @include set-margin($margin-15,bottom);
}
.margin-btm-x2 {
  margin-bottom: 25px;
}
.margin-bottom-45{
  @include set-margin($margin-30+15 !important,bottom);
}
.margin-class{
  margin: 12px 0;
}
.ltr{
  direction: ltr;
}
.gcc-{
  &selector{
    @extend .relative;
    min-width:60px;
    @include line-height(0);
  }
  &select{
    @extend .no-border;
    background: $bg-of-white;
    @include height(34px);
    @extend .absolute;
    @include z-index(1);
    @extend .no-opacity;
    @extend .full-width;
    -webkit-appearance: menulist-button;
    @include line-height(30px);
  }
  &option{
    @include set-padding($padding-5 - 3 $padding-10);
  }
  &basic-select{
    @extend .inline-b;
    @include line-height(32px);
    @extend .relative;
    @extend .full-width;
    @extend .no-scroll;
    white-space: nowrap;
    background: $bg-of-white;
    &::before{
      @extend .absolute;
      @include set-position(0,top);
      content: '';
      @extend .second-color-bg;
      @include width(24px);
      @include height(100%);
    }
    &::after{
      @extend .absolute;
      @include set-position(15px,top);
      @include set-border(5px solid $white,top);
      content: "";
    }
  }
}
.c-select{
  &-right{
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @extend .no-border-rad;
      @extend .shows;
      @extend .full-width;
      height: 40px;
      line-height: 1.75;
      @extend .black;
      @extend .white-bg;
      @extend .no-background;
      -ms-word-break: normal;
      word-break: normal;
    }
    &:after{
      content: '\f107';
      font: normal normal normal 17px/1 FontAwesome;
      @extend .white;
      top: 0;
      height: 40px;
      padding: 10px 7px;
      @extend .absolute;
      pointer-events: none;
      background-color: $secondry-color;
    }
  }
  &-left{
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @extend .no-border-rad;
      @extend .shows;
      @extend .full-width;
      height: 40px;
      line-height: 1.75;
      @extend .black;
      @extend .white-bg;
      @extend .no-background;
      -ms-word-break: normal;
      word-break: normal;
    }
    &:after{
      content: '\f107';
      font: normal normal normal 17px/1 FontAwesome;
      @extend .white;
      top: 0;
      height: 40px;
      padding: 10px 7px;
      @extend .absolute;
      pointer-events: none;
      background-color: $secondry-color;
    }
  }
}
.no-margin-left{
  @include set-margin(0,left);
}
.no-margin-right{
  @include set-margin(0,right);
}
.margin-right-15{
  @include set-margin($margin-15,right);
}
.margin-left-15{
  @include set-margin($margin-15,left);
}
.btn{
  &:hover, .hotel-nav-sticky {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    /*transform: translateY(-1px);*/
  }
  -moz-user-select: none;
  background-image: none;
  @include set-border(1px solid transparent);
  @include border-radius(4px);
  cursor: pointer;
  @extend .inline-b;
  @include font-size($text-size);
  @include font-weight($font-normal);
  line-height: 1.42857;
  @include set-margin(0,bottom);
  @include set-padding(($padding-5+1) ($padding-10+2));
  @extend .text-center;
  @extend .v-align-middle;
  white-space: nowrap;
}
a{
  @extend .no-decoration;
  @extend .no-outline;
  &:hover{
    @extend .no-decoration;
  }
}
.second-color{
  color: $secondry-color;
}
.second-color-bg{
  background-color: $secondry-color;
}
.hotel-title-temp{
  @include font-size($text-size-24);
  @include set-margin($margin-10 0);
  @extend .second-color;
}
.unq-hover{
  @extend .parent-transition;
  &:hover{
    @extend .child-transition;
    @include box-shadow(1px 2px 20px rgba(0, 0, 0, 0.48));
  }
}
.flip-horizental{
  @include horizen_flip(-1,1);
}
.unq-btn{
  &:hover{
    @include box-shadow(1px 2px 20px rgba(0, 0, 0, 0.48));
    @extend .child-transition;
    background-color: $secondry-color;
    @extend .white;
  }
  @extend .parent-transition;
  @extend .white-bg;
  color: $secondry-color;
  @include border-radius(3px);
  @include set-border(1px solid $secondry-color);
}
.tripadvisor-wrap {
  color: #73b143;
  @include font-size($text-size);
  @include font-weight($font-normal);
  i.triv-advisor-icn{
    line-height: normal;
  }
  .tA-review{
    color: $semi-black;
    @include font-size($text-size - 1);
    @extend .v-align-middle;
  }
}
.active-hotel-temp{
  background: $active-hotel-color;
}
.active-hotel{
  @extend .parent-transition;
}
.active-hotel{
  @extend .child-transition;
  @include box-shadow(1px 2px 20px rgba(0, 52, 154, 0.78));
}

/******go to top link css*****/

.cd-top {
  @extend .inline-b;
  @include height(40px);
  @include width(40px);
  @extend .fixed;
  @include set-position(40px,bottom);
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.05));
  /* image replacement properties */
  @extend .no-scroll;
  text-indent: 100%;
  white-space: nowrap;
  @include border-radius(40px);
  visibility: hidden;
  @extend .no-opacity;
  @include z-index(2);
  @include transition(opacity .3s 0s unquote(',')visibility 0s .3s);
}
.cd-top{
  &.cd-is-visible{
    /* the button becomes visible */
    visibility: visible;
    @extend .opacity-1;
  }&.cd-fade-out,.no-touch &:hover{
  @include transition(opacity .3s 0s unquote(',') visibility 0s .3s);
  }
  &.cd-fade-out{
    /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
    opacity: .5;
  }
}
.primary-hover{
  background: $primary-color none repeat scroll 0 0;
  @extend .white;
}
.hotel-detail-masonary{
  background-color: #F0F0F0;
  @extend .relative;
  @extend .no-scroll;
  @extend .v-align-middle;
  cursor: pointer;
}
.hotel-page {
  .b-lazy {
    opacity:0;
    transform: scale(3);
    transition: all 500ms;
  }
  .b-loaded {
    opacity:1;
    transform: scale(1);
  }
}
.form-error{
  color: #a94442;
}
.hpadding20{
  @include set-padding(0 $padding-20 0 $padding-20);
}
.masonary-img{
  @include transform(translate(-50%,-50%));
  @extend .absolute;
  @include set-position(50%,top);
  @include set-position(50%,left);
  @extend .full-width;
  @include height(100%);
  font-family: "object-fit:cover";
  object-fit: cover;
}
.dark-overlay-temp{
  background-color: $dark-overlay;
}
.dark-overlay-information-temp{
  @include set-position(0,bottom);
  @extend .opacity-1;
}
.rooms-container-icon{
  @include width(18px);
  @include height(18px);
  @extend .shows;
  background-image: $room-container-icon;
  @include transition(.2s);
}

.collapsearrow-bg{
  background-image: $collapse-arrow;
}
.list-image{
  list-style-image: $list-image;
  list-style-position: outside;
  @include set-padding($padding-5);
}
.flex{
  display: inline-flex;
}
.review-circle{
  background: $review-circle no-repeat;
}
.font{
  &-ltr{
    font-family:$font-ltr;
  }
  &-rtl{
    font-family:$font-rtl;
  }
}
.line4 {
  background: $bg-of-white;
  @include height(1px);
  @include set-margin(($margin-15+2) 0 $margin-15 0);
  @extend .no-padding;
  @extend .not-imp-show;
}
.line2 {
  background: $bg-of-white;
  @include height(1px);
  @include set-margin(($margin-10+2) 0 $margin-5+1 0);
  @extend .no-padding;
  @extend .not-imp-show;
}
.bordertype5 {
  @include set-border(1px solid $bg-of-white,right);
  color: $secondry-text;
}
.green{
  color: $primary-color-mid;
}
.green-bg{
  background: $primary-color-mid;
}
.margin-0-auto{
  @include set-margin(0 auto);
}
.no-margin{
  @include set-margin(0);
}
.inline-b{
  display: $inline-view;
}
.v-align-middle{
  vertical-align: $vert-align;
}
.btn-success{
  background-color: transparent;
  border-color: #00349a;
  border: 1px solid;
  &:hover,&:active,
  &:focus,&:active:focus,
  &:active:hover,
  .open > &.dropdown-toggle:hover,
  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle:focus{
    color: #fff;
    background-color: #00349a;
    border-color: #0e1d9a;
  }
  color: #00349a;
}
.btn-acent{
  @extend .second-color-bg;
  @extend .white;
  @include set-padding($padding-5);
  @extend .parent-transition;
  &:hover{
    @extend .primary-hover;
  }
}
.progress-bar-success{
  @extend .second-color-bg;
}
.can-section {
  @extend .relative;
  @extend .full-width;
  height: 100%;
  background: -webkit-radial-gradient(circle, $primary-color, $secondry-color);
  background: radial-gradient(circle, $primary-color, $secondry-color);
  #beerCanvas {
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .coming_content {
    @extend .absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    @include width(70%);
    @extend .margin-0-auto;
    @extend .white;
    @extend .text-center;
    @include z-index(101);
    h1{
      @include font-size(5.625em);
      @extend .no-margin;
      letter-spacing: 2px;
      @extend .text-center;
      @extend .white;
    }
    .separator_container{
      &:before{
        @extend .d-table;
        content: '';
      }
      @extend .full-width;
      @extend .not-imp-show;
      @extend .text-center;
      @extend .relative;
      @include set-margin($margin-10+2 0);
      .separator{
        @extend .white;
        @extend .margin-0-auto;
        @include set-margin(1em,bottom);
        @include width(11em);
      }
      .line_separator{
        &:before{
          @extend .not-imp-show;
          @include width(40%);
          content: " ";
          @include set-margin(14px,top);
          @include set-border(1px solid $white);
          @extend .pulls-left;
        }
        svg{
          @include width(30px);
          @include height(20px);
        }
        &:after{
          @extend .not-imp-show;
          @include width(40%);
          content: " ";
          @include set-margin(14px,top);
          @include set-border(1px solid $white);
          @extend .pulls-right;
        }
      }
      &:after{
        @extend .separator_container:before;
        clear: both;
      }
    }
    h3{
      font-family: "Montserrat", sans-serif;
      letter-spacing: 2px;
      @include line-height(2);
      @include font-size(1em);
      @include font-size($font-bold);
      @extend .text-center;
      @extend .no-margin;
    }
  }
}
.text{
  &-center{
    text-align: center;
  }
  &-left{
    text-align: left;
  }
  &-right{
    text-align: right;
  }
}
.modal {
  text-align: center;
  padding: 0!important;
  &:before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  &-content{
    border-radius: 0;
  }
  &-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

/*================================== date holder style start ===================================*/
.search-form .date-left:before,
.search-form .date-right:before{
  content: "\f073 ";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: rgba(255, 255, 255, .2);
  font-size: 18px;
  /*padding-right: 0.5em;*/
  position: absolute;
  top: 36px;
  /*right: 0px;*/
  color: $secondry-color;
  margin: 0;
}
.search-form .date-left:after,
.search-form .date-right:after{
  content: "\f054 ";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: rgba(255, 255, 255, .2);
  font-size: 18px;
  /*padding-right: 0.5em;*/
  position: absolute;
  top: 36px;
  /*left: 12px;*/
  color: $secondry-color;
  margin: 0;
  font-size: 12px;
  transform: rotate(90deg);
}
/*================================== image overlay style start ===================================*/
.mt-element-overlay .mt-overlay-3 .mt-info,
.mt-element-overlay .mt-overlay-3 h2 {
  @include transition(all .4s cubic-bezier(.88,-.99,0,1.81));
  @extend .white;
  text-transform: uppercase;
}
.mt-element-overlay {
  .mt-overlay-3{
    @extend .full-width;
    height: 100%;
    @extend .pulls-left;
    @extend .no-scroll;
    @extend .relative;
    @extend .text-center;
    cursor: default;
    &:hover{
      .mt-overlay{
        background-color: rgba(48,152,157,.7);
      }
      h2{
        @include transform(translateY(5px));
      }
      .mt-info{
        opacity: 1;
        filter: alpha(opacity=100);
        @include transform(scale(1));
      }
    }
    img{
      @extend .shows;
      @extend .relative;
      @extend .full-width;
      height: auto;
    }
    .mt-overlay {
      @extend .full-width;
      height: 100%;
      @extend .absolute;
      @extend .no-scroll;
      top: 0;
      left: 0;
      background-color: rgba(75,75,75,.7);
      @include transition(all .4s cubic-bezier(.88,-.99,0,1.81));
    }
    h2 {
      @extend .text-center;
      @extend .relative;
      font-size: 17px;
      padding: 10px;
      background: rgba(0,0,0,.6);
      @include transform(translateY(165px));
      @include transition(all .4s cubic-bezier(.88,-.99,0,1.81));
    }
    @media only screen and (max-width: 800px){
      h2{
        @include transform(translateY(130px));
      }
    }
    .mt-info {
      @extend .inline-b;
      text-decoration: none;
      border: 1px solid #fff;
      background-color: transparent;
      @extend .no-opacity;
      filter: alpha(opacity=0);
      @include transform(scale(0));
      @include transition(all .4s cubic-bezier(.88,-.99,0,1.81));
      font-weight: 400;
      @extend .absolute;
      top: 15px;
      bottom: 15px;
      left: 15px;
      right: 15px;
      margin: auto;
      padding: 45% 0 0;
    }
  }
}

/*------------->>>>>>>>>>>>>>>>> cross browser compatible checkbox <<<<<<<<<<<<<<<<<-----------------*/
%controller {
  position: relative;
  cursor: pointer;
  /*padding: 1em;*/

  &::selection {
    background: transparent;
  }

  input + span {
    background: white;
    content: "";
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0;
    vertical-align: middle;
    width: 1.5em;
    height: 1.5em;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    &::after {
      content: "";
      display: block;
      transform: scale(0);
      /*transition: transform .2s;*/
    }
  }

  @media screen and (min-width: 768px) {
    &:hover input + span {
      box-shadow: 0 2px 4px rgba(#000, .15);
    }
  }

  input:active + span {
    box-shadow: 0 4px 8px rgba(#000, .15);
  }

  input:focus + span {
    box-shadow: 0 0 0 3px lightblue;
  }

  input:checked + span{
    background-image: -webkit-linear-gradient(top, $primary-color 0%, $secondry-color 100%);
    background-image: -o-linear-gradient(top, $primary-color 0%, $secondry-color 100%);
    background-image: linear-gradient(to bottom, $primary-color 0%, $secondry-color 100%);
    &::after {
      transform: scale(1);
      background-color: #fff;
      -webkit-mask: url(images/tick.svg) no-repeat 50% 50%;
      mask: url(images/tick.svg) no-repeat 50% 50%;
    }
  }

  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }
}

.cross-checkbox {
  @extend %controller;

  input + span {
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    &::after {
      /*background: url(images/tick.svg) no-repeat center;
      background-size: contain;*/
      width: 1.5em;
      height: 1.5em;
      /*-webkit-mask:url(images/tick.svg) no-repeat 45% 60%;
      mask:url(images/tick.svg) no-repeat 45% 60%;*/
    }
  }
}
/*------------->>>>>>>>>>>>>>>>> loading animation start <<<<<<<<<<<<<<<<<-----------------*/
.bookshelf_wrapper {
  position: relative;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.books_list {
  margin: 0 auto;
  width: 300px;
  padding: 0;
}

.book_item {
  position: absolute;
  top: -120px;
  box-sizing: border-box;
  list-style: none;
  width: 40px;
  height: 120px;
  opacity: 0;
  background-color: $secondry-color;
  border: $thickness solid white;
  transform-origin: bottom left;
  transform: translateX(300px);
  animation: travel #{$duration}ms linear infinite;

  &.first {
    top: -140px;
    height: 140px;

    &:before,
    &:after {
      content:'';
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: $thickness;
      background-color: white;
    }

    &:after {
      top: initial;
      bottom: 10px;
    }
  }

  &.second,
  &.fifth {
    &:before,
    &:after {
      box-sizing: border-box;
      content:'';
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: $thickness*3.5;
      border-top: $thickness solid white;
      border-bottom: $thickness solid white;
    }

    &:after {
      top: initial;
      bottom: 10px;
    }
  }

  &.third {
    &:before,
    &:after {
      box-sizing: border-box;
      content:'';
      position: absolute;
      top: 10px;
      left: 9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: $thickness solid white;
    }

    &:after {
      top: initial;
      bottom: 10px;
    }
  }

  &.fourth {
    top: -130px;
    height: 130px;

    &:before {
      box-sizing: border-box;
      content:'';
      position: absolute;
      top: 46px;
      left: 0;
      width: 100%;
      height: $thickness*3.5;
      border-top: $thickness solid white;
      border-bottom: $thickness solid white;
    }
  }

  &.fifth {
    top: -100px;
    height: 100px;
  }

  &.sixth {
    top: -140px;
    height: 140px;

    &:before {
      box-sizing: border-box;
      content:'';
      position: absolute;
      bottom: 31px;
      left: 0px;
      width: 100%;
      height: $thickness;
      background-color: white;
    }

    &:after {
      box-sizing: border-box;
      content:'';
      position: absolute;
      bottom: 10px;
      left: 9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: $thickness solid white;
    }
  }

  &:nth-child(2) {
    animation-delay: #{$delay*1}ms;
  }

  &:nth-child(3) {
    animation-delay: #{$delay*2}ms;
  }

  &:nth-child(4) {
    animation-delay: #{$delay*3}ms;
  }

  &:nth-child(5) {
    animation-delay: #{$delay*4}ms;
  }

  &:nth-child(6) {
    animation-delay: #{$delay*5}ms;
  }

}

.shelf {
  width: 300px;
  height: $thickness;
  margin: 0 auto;
  background-color: white;
  position: relative;

  &:before,
  &:after {
    content:'';
    position : absolute;
    width: 100%;
    height: 100%;
    @include polka(10px, 30%, $secondry-color, rgba(255,255,255,0.5));
    top: 200%;
    left: 5%;
    animation: move #{$duration/10}ms linear infinite;
  }

  &:after {
    top: 400%;
    left: 7.5%;
  }

}
/*------------->>>>>>>>>>>>>>>>> loading animation end <<<<<<<<<<<<<<<<<-----------------*/