@mixin give_padding($top,$right:null,$bottom:null,$left:null){
    padding: $top $right $bottom $left;
}
@mixin give_margin($top,$right:null,$bottom:null,$left:null){
    margin: $top $right $bottom $left;
}
@mixin set-padding($size, $direction:null){
  @if($direction == top){
    padding-top: $size;
  }
  @elseif($direction == left){
    padding-left: $size;
  } @elseif($direction == bottom){
    padding-bottom: $size;
  } @elseif($direction == right){
    padding-right: $size;
  } @else{
    padding: $size;
  }
}
@mixin set-margin($size, $direction:null){
  @if($direction == top){
    margin-top: $size;
  }
  @elseif($direction == left){
    margin-left: $size;
  } @elseif($direction == bottom){
    margin-bottom: $size;
  } @elseif($direction == right){
    margin-right: $size;
  } @else{
    margin: $size;
  }
}
@mixin box-shadow($top, $left:null, $blur:null, $color:null, $inset:null) {
  -webkit-box-shadow: $top $left $blur $color $inset;
  -moz-box-shadow:    $top $left $blur $color $inset;
  box-shadow:         $top $left $blur $color $inset;
}
@mixin headline ($size,$color: #8a211b) {
  color: $color;
  font-size: $size;
}
@mixin font-size($size){
  font-size: $size;
}
@mixin line-height($value){
  line-height: $value;
}
@mixin font-weight($value){
  font-weight: $value;
}
@mixin link($color,$visit,$hover,$active){
  a{
    color: $color;
    &:visited{
      color: $visit;
    }
    &:hover{
      color: $hover;
    }
    &:active{
      color: $active;
    }
  }
}
@mixin transform($transform) {
  -moz-transform: $transform;
  -o-transform: $transform;
  -ms-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}
@mixin horizen_flip($val,$val2){
  @include transform(scale($val, $val2));
}
@mixin _spreadsheet($position){
    @extend .spreadsheet;
    background-position: $position;
}
@mixin z-index($value){
  z-index: $value;
}
@mixin list-inline($display:inline-block,$align:middle,$list-style:none){
  display: $display;
  vertical-align: $align;
  list-style: $list-style;
}
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
  url($font-file+'.woff') format('woff'),
  url($font-file+'.ttf') format('truetype'),
  url($font-file+'.svg#aller') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

@mixin border-radius($topleft, $topright:null, $bottomright:null, $bottomleft:null) {
  border-radius: $topleft $topright $bottomright $bottomleft;
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  -moz-border-radius: $topleft $topright $bottomright $bottomleft;
}
@mixin _progress_active($animation,$duration:null,$transition:null,$loop:null){
  -webkit-animation: $animation $duration $transition $loop;
  -moz-animation: $animation $duration $transition $loop;
  -ms-animation: $animation $duration $transition $loop;
  -o-animation: $animation $duration $transition $loop;
  animation: $animation $duration $transition $loop;
}
@mixin _appearance($value){
  -webkit-appearance: $value;
  -moz-appearance: $value;
  -ms-appearance: $value;
  -o-appearance: $value;
  appearance: $value;
}
@mixin transition($value){
  -webkit-transition: $value;
  -moz-transition: $value;
  transition: $value;
}
@mixin set-border($size, $direction:null){
  @if($direction == top){
  border-top: $size;
}
  @elseif($direction == left){
  border-left: $size;
} @elseif($direction == bottom){
  border-bottom: $size;
} @elseif($direction == right){
  border-right: $size;
} @else{
  border: $size;
}
}
@mixin set-border-width($size, $direction:null){
  @if($direction == top){
    border-top-width: $size;
  }
  @elseif($direction == left){
    border-left-width: $size;
  } @elseif($direction == bottom){
    border-bottom-width: $size;
  } @elseif($direction == right){
    border-right-width: $size;
  } @else{
    border-width: $size;
  }
}
@mixin set-border-color($size, $direction:null){
  @if($direction == top){
    border-top-color: $size;
  }
  @elseif($direction == left){
    border-left-color: $size;
  } @elseif($direction == bottom){
    border-bottom-color: $size;
  } @elseif($direction == right){
    border-right-color: $size;
  } @else{
    border-color: $size;
  }
}
@mixin loaderBefore($direction,$beforePos:21px,$color:rgb(121, 196, 228),$color2: rgba(121, 196, 228,0.3),$beforeMar:-18px){
  &:before{
    content: '';
    @extend .absolute;
    @include height(30px);
    @include width(30px);
    @include set-position(58%,top);
    @include set-margin(-18px,top);
    @include set-position($beforePos,$direction) ;
    @include set-margin($beforeMar,$direction);
    border-width: 4px 0px;
    border-style: solid;
    border-color: $color $color2;
    @include border-radius(50%);
    box-sizing:border-box;
    @include _progress_active(spin 3.45s infinite);
  }
}
@mixin loaderAfter($direction,$afterPos:17px,$color:rgb(121, 196, 228),$afterMar:-4px){
  &:after{
    content: '';
    @extend .absolute;
    @include height(10px);
    @include width(10px);
    @include set-position(48%,top);
    @include set-margin(-4px,top);
    @include set-position($afterPos,$direction) ;
    @include set-margin($afterMar,$direction);
    background-color: $color;
    @include border-radius(50%);
    box-sizing:border-box;
    @include _progress_active((pulse 6.9s infinite, borderPulse 6.9s infinite));
  }
}
@mixin clearfix(){
  &:after,
  &:before{
    content: "";
    display: table;
  }
  &:after{
    clear: both;
  }
}
@mixin clear-fix(){
  &:after{
    display:block;
    clear:both;
    content:'';
  }
}
/*@mixin animation(){
  animation: spin 3.45s infinite;
  -o-animation: spin 3.45s infinite;
  -ms-animation: spin 3.45s infinite;
  -webkit-animation: spin 3.45s infinite;
  -moz-animation: spin 3.45s infinite;
}*/
@mixin width($width){
  width: $width;
}
@mixin height($height){
  height: $height;
}
@mixin position($top:initial,$right:initial,$bottom:initial,$left:initial){
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin set-position($size, $direction:null,$top:null,$right:null,$bottom:null,$left:null){
  @if($direction == top){
    top: $size;
  }
  @elseif($direction == left){
    left: $size;
  } @elseif($direction == bottom){
    bottom: $size;
  } @elseif($direction == right){
    right: $size;
  } @else{
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}

@mixin transition-with-condition($flag:true){
  @if $flag == true{
    .parent-transition{
      -o-transition: all 0.85s ease 0s;
      -ms-transition: all 0.85s ease 0s;
      -webkit-transition: all 0.85s ease 0s;
      -moz-transition: all 0.85s ease 0s;
      transition: all 0.85s ease 0s;
    }
    .child-transition{
      -o-transition: all 0.25s ease 0s;
      -ms-transition: all 0.25s ease 0s;
      -webkit-transition: all 0.25s ease 0s;
      -moz-transition: all 0.25s ease 0s;
      transition: all 0.25s ease 0s;
    }
  }@else if $flag == false{
    .parent-transition{
      transition: initial;
    }
    .child-transition{
      transition: initial;
    }
}
}
@mixin transition-with-condition-in-mediaquery($flag:true,$transitionType:child-transition){
  @if $flag == true{
    @if $transitionType == parent-transition{
      -o-transition: all 0.85s ease 0s;
      -ms-transition: all 0.85s ease 0s;
      -webkit-transition: all 0.85s ease 0s;
      -moz-transition: all 0.85s ease 0s;
      transition: all 0.85s ease 0s;
    }@else{
      -o-transition: all 0.25s ease 0s;
      -ms-transition: all 0.25s ease 0s;
      -webkit-transition: all 0.25s ease 0s;
      -moz-transition: all 0.25s ease 0s;
      transition: all 0.25s ease 0s;
    }

  }@else if $flag == false{
    -o-transition: initial;
    -ms-transition: initial;
    -webkit-transition: initial;
    -moz-transition: initial;
    transition: initial;
  }
}
@mixin dynamic-transition-with-condition($media,$transition){
  @if $media == 1024{
    /*-o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);*/
  }@else if $media == 800{
    -o-transition: $transition;
    -ms-transition: $transition;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
  }
}
@mixin polka($size, $dot, $base, $accent){
  background: $base;
  background-image: radial-gradient($accent $dot, transparent 0);
  background-size:$size $size;
  background-position: 0 -2.5px;
}