$primary-color: #79c4e4;
$primary-color-mid: #3a9fca;
$primary-color-op-7: rgba(121, 196, 228, 0.7);
$secondry-color: #00349a;
$text-color: #074b9a;
$secondry-color-light: rgba(0,52,154, 0.5);
$active-hotel-color: rgba(0, 154, 0, 0.08);
$home-icon-1: #7bbd65;
$home-icon-2: #355e97;
$home-icon-3: #aea6d3;
$home-icon-4: #c41c17;
$home-icon-5: #f78200;
$tripadvisor-color: #73b143;
$breadcrumb-bg:rgba(0, 0, 0, 0) none repeat scroll 0 0;
$fb-bg: #3b579d;
$g-plus:#ea4436;
$spreadsheet:url("/web/images/map-sprite.png");
$room-container-icon: url("/web/images/sprite.png");
$collapse-arrow: url('/web/images/collapsearrow.png');
$list-image:url('/web/images/check.png');
$review-circle: url('/web/images/circle.png');
$uparrow: url('/web/images/uparrow.png');
$progress-animation:progress-bar-stripes;
$progress-duration:.5s;
$progress-transition:linear;
$progress-loop: infinite;
$dark-overlay:rgba(0,0,0,.7);
$see-all-overlay:rgba(0,0,0,.5);
$still-interested-heading-bg: #e6edf6;
$primary-background: $primary-color;
$primary-footer:#f2f4f8;
$primary-text:#767676;
$dark-text:#666;
$primary-text-dark:#838383;
$secondry-text:#adadad;
$light-divider:#eee;
$divider:#ced0d3;
$dark-divider:#999;
$light-border:#ddd;
$white: #fff;
$black: #000;
$semi-black:#3e3d3d;
$primary-h2:#fff;
$primary-label:#fff;
$bg-of-white: #F0F0F0;
$bg-body-color: #f5f4f4;
$transparent: transparent;
$block: block;
$font-rtl:"Noto kufi Arabic",'Noto Kufi Arabic-bold';
$font-ltr:'Roboto', Arial, Helvetica, sans-serif;
$text-size-13: 13px;
$text-size: 15px;
$text-md-size:18px;
$text-lg-size:20px;
$text-xlg-size:22px;
$text-size-24: 24px;
$full-width: 100%;
$max-width-full:100%;
$relative: relative;
$absolute: absolute;
$font-lighter: lighter;
$font-normal: normal;
$font-bold: bold;
$font-bolder: bolder;
$z1:1;
$z2:2;
$z3:3;
$z4:4;
$z5:5;
$z6:6;
$margin-5:5px;
$margin-10:10px;
$margin-15:15px;
$margin-20:20px;
$margin-25:25px;
$margin-30:30px;
$padding-5:5px;
$padding-10:10px;
$padding-15:15px;
$padding-20:20px;
$padding-25:25px;
$padding-30:30px;
$no-overflow:hidden;
$inline-view:inline-block;
$vert-align: middle;
$right: right;
$left: left;
$none: none;
$unset: unset;
$height:40px;
$adham-primary: #8a6d35;
$thickness : 5px;
$duration : 2500;
$delay : $duration/6;