@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?mmdxmw");
  src: url("../fonts/icomoon.eot?mmdxmw#iefix") format("embedded-opentype"),
  url("../fonts/icomoon.woff?mmdxmw") format("woff"),
  url("../fonts/icomoon.ttf?mmdxmw") format("truetype"),
  url("../fonts/icomoon.svg?mmdxmw#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.no-scroll{
  overflow:hidden;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.svg?mmdxmw#icomoon") format("svg");
  }
}
.fi:before ,:not(.fi.icon-){
  font-family: "Flaticon";
}
[class^="icon-"], [class*=" icon-"]{
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-smoothing: antialiased;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend .primary-color;
  cursor: help;
}
.icon-foyer:before {
  content: "\f100";
}
.icon-bar:before {
  content: "\f107";
}
.icon-confrence:before {
  content: "\f103";
}
.icon-casino:before {
  content: "\f101";
}
.icon-child-playground:before {
  content: "\f10b";
}
.icon-Restaurant-air-conditioning-:before {
  content: "\f106";
}
.icon-cafe:before {
  content: "\f10a";
}
.icon-parking:before {
  content: "\f10d";
}
.icon-Restaurant:before {
  content: "\f108";
}
.icon-game-room:before {
  content: "\f102";
}
.icon-meeting:before {
  content: "\f104";
}
.icon-shop:before {
  content: "\f109";
}
.icon-high-chair:before {
  content: "\f105";
}
.icon-24-security:before {
  content: "\f10c";
}
.icon-warning:before {
  content: "\e002";
}
.icon-queue:before {
  content: "\e03c";
}
.icon-volume_off:before {
  content: "\e04f";
}
.icon-add_to_queue:before {
  content: "\e05c";
}
.icon-ring_volume:before {
  content: "\e0d1";
}
.icon-stay_primary_landscape:before {
  content: "\e0d5";
}
.icon-report:before {
  content: "\e160";
}
.icon-access_alarms:before {
  content: "\e191";
}
.icon-airplanemode_inactive:before {
  content: "\e194";
}
.icon-network_cell:before {
  content: "\e1b9";
}
.icon-network_wifi:before {
  content: "\e1ba";
}
.icon-signal_cellular_4_bar:before {
  content: "\e1c8";
}
.icon-signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.icon-signal_cellular_null:before {
  content: "\e1cf";
}
.icon-signal_cellular_off:before {
  content: "\e1d0";
}
.icon-signal_wifi_4_bar:before {
  content: "\e1d8";
}
.icon-signal_wifi_4_bar_lock:before {
  content: "\e1d9";
}
.icon-signal_wifi_off:before {
  content: "\e1da";
}
.icon-attach_money:before {
  content: "\e227";
}
.icon-router:before {
  content: "\e328";
}
.icon-sim_card:before {
  content: "\e32b";
}
.icon-adjust:before {
  content: "\e39e";
}
.icon-camera_roll:before {
  content: "\e3b3";
}
.icon-nature:before {
  content: "\e406";
}
.icon-nature_people:before {
  content: "\e407";
}
.icon-add_a_photo:before {
  content: "\e439";
}
.icon-directions_bus:before {
  content: "\e530";
}
.icon-directions_car:before {
  content: "\e531";
}
.icon-directions_railway:before {
  content: "\e534";
}
.icon-directions_transit:before {
  content: "\e535";
}
.icon-directions_walk:before {
  content: "\e536";
}
.icon-flight:before {
  content: "\e539";
}
.icon-local_bar:before {
  content: "\e540";
}
.icon-local_convenience_store:before {
  content: "\e543";
}
.icon-restaurant_menu:before {
  content: "\e561";
}
.icon-directions_run:before {
  content: "\e566";
}
.icon-person_pin_circle:before {
  content: "\e56a";
}
.icon-restaurant:before {
  content: "\e56c";
}
.icon-adb:before {
  content: "\e60e";
}
.icon-network_locked:before {
  content: "\e61a";
}
.icon-sim_card_alert:before {
  content: "\e624";
}
.icon-airline_seat_individual_suite:before {
  content: "\e632";
}
.icon-wifi:before {
  content: "\e63e";
}
.icon-rv_hookup:before {
  content: "\e642";
}
.icon-cake:before {
  content: "\e7e9";
}
.icon-domain:before {
  content: "\e7ee";
}
.icon-group_add:before {
  content: "\e7f0";
}
.icon-location_city:before {
  content: "\e7f1";
}
.icon-notifications:before {
  content: "\e7f4";
}
.icon-notifications_none:before {
  content: "\e7f5";
}
.icon-notifications_off:before {
  content: "\e7f6";
}
.icon-notifications_active:before {
  content: "\e7f7";
}
.icon-notifications_paused:before {
  content: "\e7f8";
}
.icon-people:before {
  content: "\e7fb";
}
.icon-people_outline:before {
  content: "\e7fc";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-person_add:before {
  content: "\e7fe";
}
.icon-person_outline:before {
  content: "\e7ff";
}
.icon-accessibility:before {
  content: "\e84e";
}
.icon-account_balance:before {
  content: "\e84f";
}
.icon-account_balance_wallet:before {
  content: "\e850";
}
.icon-account_box:before {
  content: "\e851";
}
.icon-account_circle:before {
  content: "\e853";
}
.icon-add_shopping_cart:before {
  content: "\e854";
}
.icon-alarm:before {
  content: "\e855";
}
.icon-alarm_add:before {
  content: "\e856";
}
.icon-cached:before {
  content: "\e86a";
}
.icon-flip_to_front:before {
  content: "\e883";
}
.icon-perm_data_setting:before {
  content: "\e8a4";
}
.icon-perm_device_information:before {
  content: "\e8a5";
}
.icon-perm_scan_wifi:before {
  content: "\e8a9";
}
.icon-schedule:before {
  content: "\e8b5";
}
.icon-goat:before {
  content: "\e900";
}
.icon-home2:before {
  content: "\e901";
}
.icon-home22:before {
  content: "\e902";
}
.icon-home3:before {
  content: "\e903";
}
.icon-office:before {
  content: "\e904";
}
.icon-accessible:before {
  content: "\e914";
}
.icon-pets:before {
  content: "\e91d";
}
.icon-rowing:before {
  content: "\e921";
}
.icon-equalizer2:before {
  content: "\e992";
}
.icon-equalizer22:before {
  content: "\e993";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-happy:before {
  content: "\e9df";
}
.icon-happy2:before {
  content: "\e9e0";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-smile2:before {
  content: "\e9e2";
}
.icon-tongue:before {
  content: "\e9e3";
}
.icon-tongue2:before {
  content: "\e9e4";
}
.icon-sad:before {
  content: "\e9e5";
}
.icon-sad2:before {
  content: "\e9e6";
}
.icon-wink:before {
  content: "\e9e7";
}
.icon-wink2:before {
  content: "\e9e8";
}
.icon-grin:before {
  content: "\e9e9";
}
.icon-grin2:before {
  content: "\e9ea";
}
.icon-cool:before {
  content: "\e9eb";
}
.icon-cool2:before {
  content: "\e9ec";
}
.icon-angry:before {
  content: "\e9ed";
}
.icon-angry2:before {
  content: "\e9ee";
}
.icon-evil:before {
  content: "\e9ef";
}
.icon-evil2:before {
  content: "\e9f0";
}
.icon-shocked:before {
  content: "\e9f1";
}
.icon-shocked2:before {
  content: "\e9f2";
}
.icon-baffled:before {
  content: "\e9f3";
}
.icon-baffled2:before {
  content: "\e9f4";
}
.icon-confused:before {
  content: "\e9f5";
}
.icon-confused2:before {
  content: "\e9f6";
}
.icon-neutral:before {
  content: "\e9f7";
}
.icon-neutral2:before {
  content: "\e9f8";
}
.icon-hipster:before {
  content: "\e9f9";
}
.icon-hipster2:before {
  content: "\e9fa";
}
.icon-wondering:before {
  content: "\e9fb";
}
.icon-wondering2:before {
  content: "\e9fc";
}
.icon-sleepy:before {
  content: "\e9fd";
}
.icon-sleepy2:before {
  content: "\e9fe";
}
.icon-frustrated:before {
  content: "\e9ff";
}
.icon-frustrated2:before {
  content: "\ea00";
}
.icon-crying:before {
  content: "\ea01";
}
.icon-crying2:before {
  content: "\ea02";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-info2:before {
  content: "\ea0c";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-ac_unit:before {
  content: "\eb3b";
}
.icon-airport_shuttle:before {
  content: "\eb3c";
}
.icon-all_inclusive:before {
  content: "\eb3d";
}
.icon-beach_access:before {
  content: "\eb3e";
}
.icon-child_friendly:before {
  content: "\eb42";
}
.icon-free_breakfast:before {
  content: "\eb44";
}
.icon-hot_tub:before {
  content: "\eb46";
}
.icon-pool:before {
  content: "\eb48";
}
.icon-room-service:before {
  content: "\eb49";
}
.icon-smoke_free:before {
  content: "\eb4a";
}
.icon-smoking_rooms:before {
  content: "\eb4b";
}
.icon-transfer:before {
  content: "\f10e";
}
.icon-skier-skiing:before {
  content: "\f10f";
}
.icon-car-trip:before {
  content: "\f110";
}
.icon-internet-socket:before {
  content: "\f111";
}
.icon-babysitter-and-child:before {
  content: "\f112";
}
.icon-helipad:before {
  content: "\f113";
}
.icon-private-garage:before {
  content: "\f114";
}
.icon-laundry-service:before {
  content: "\f115";
}
.icon-bike:before {
  content: "\f116";
}
.icon-room-service:before {
  content: "\f117";
}
.icon-childcare:before {
  content: "\f118";
}
.icon-air-conditioning:before {
  content: "\f119";
}
.icon-wheelchair-access:before {
  content: "\f11a";
}
.icon-security-shield:before {
  content: "\f11b";
}
.icon-hotel-coat-check:before {
  content: "\f11c";
}
.icon-shopping-cart:before {
  content: "\f11d";
}
.icon-repair-mechanism:before {
  content: "\f11e";
}
.icon-bike-parking-signal:before {
  content: "\f11f";
}
.icon-private-swimming-pool-sign:before {
  content: "\f120";
}
.icon-signal-strength-bars-phone-interface-symbol:before {
  content: "\f121";
}
.icon-female-hair-shape-and-face-silhouette:before {
  content: "\f122";
}
.icon-moneybox:before {
  content: "\f123";
}
.icon-electrical-kettle-tool-side-view:before {
  content: "\f124";
}
.icon-international-calling-service-symbol:before {
  content: "\f125";
}
.icon-wifi-connection-signal-symbol:before {
  content: "\f126";
}
.icon-golf-hole-and-flag:before {
  content: "\f127";
}
.icon-parking-sign:before {
  content: "\f128";
}
.icon-medical-kit:before {
  content: "\f129";
}
.icon-dumbbells:before {
  content: "\f12a";
}
.icon-terrace:before {
  content: "\f12b";
}
.icon-toaster:before {
  content: "\f12c";
}
.icon-condom:before {
  content: "\f12d";
}
.icon-bicycle:before {
  content: "\f12e";
}
.icon-bedroom:before {
  content: "\f12f";
}
.icon-no-fire:before {
  content: "\f130";
}
.icon-smoke-detector:before {
  content: "\f131";
}
.icon-newspaper:before {
  content: "\f132";
}
.icon-dryer:before {
  content: "\f133";
}
.icon-sun-umbrella:before {
  content: "\f134";
}
.icon-travel:before {
  content: "\f135";
}
.icon-bellboy:before {
  content: "\f136";
}
.icon-bench:before {
  content: "\f137";
}
.icon-staff:before {
  content: "\f138";
}
.icon-animal-prints:before {
  content: "\f139";
}
.icon-cat:before {
  content: "\f13a";
}
.icon-delivery:before {
  content: "\f13b";
}
.icon-groceries:before {
  content: "\f13c";
}
.icon-elevator:before {
  content: "\f13d";
}
.icon-barbecue:before {
  content: "\f13e";
}
.icon-doctor:before {
  content: "\f13f";
}
.icon-arrows:before {
  content: "\f140";
}
.icon-microwave-oven:before {
  content: "\f141";
}
.icon-books:before {
  content: "\f142";
}
.icon-check-in:before {
  content: "\f143";
}
.icon-check-out:before {
  content: "\f144";
}

/*Facilites Icons End*/
