@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "variables";
@import "../mixin";
@import "../fonts";
@import "utilities";
@import "../animation";

$isRtl : true;    /*A boolean to show what the current text direction */
$textDirection : right;    /*A string representing the current text direction*/
$textDirectionOpposite: left;    /*A string representing the opposite of the current direction*/
$textDirectionCode: rtl;    /*A string representing the opposite of the current direction*/
/*================================ fonts css start =====================================*/
@include font('Noto Naskh Arabic','../fonts/arabic/NotoNaskhArabic');
@include font('Noto Naskh Arabic-bold','../fonts/arabic/NotoNaskhArabic-Bold');
@include font('Noto Kufi Arabic','../fonts/arabic/NotoKufiArabic');
@include font('Noto Kufi Arabic-bold','../fonts/arabic/NotoKufiArabic-Bold');
@include font('icomoon','../fonts/icomoon');
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.svg?mmdxmw#icomoon") format("svg");
  }
}
/*[class^="icon-"], [class*=" icon-"] {
  !* use !important to prevent issues with browser extensions that change fonts *!
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-smoothing: antialiased;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  !* Better Font Rendering =========== *!
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondry-color;
  cursor: help;
}*/


/*================================ fonts css start =====================================*/
@include font('MyriadPro-Regular','../fonts/MyriadPro-Regular');
@include font('MyriadPro-Bold','../fonts/MyriadPro-Bold');
@include font('icomoon','../fonts/icomoon');

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.svg?mmdxmw#icomoon") format("svg");
  }
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-smoothing: antialiased;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondry-color;
  cursor: help;
}

.main-font{
  @extend .font-#{$textDirectionCode};
}
/*================================ fonts css end =====================================*/
/*================================ loader aniation css start =====================================*/
@if $isRtl == true{
  .bookshelf_wrapper{
    direction: $textDirectionCode;
  }
}
/*================================ loader aniation css end =====================================*/
/*================================ mobile menu css start =====================================*/

/*
 * Just a quick hamburger
 */
.mobile-menu{
  #menuToggle{
    @extend .shows;
    @extend .relative;
    top: 45px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    input{
      @extend .shows;
      width: 40px;
      height: 32px;
      @extend .absolute;
      top: -7px;
      #{$textDirectionOpposite}: 25px;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
    div.ham{
      @extend .absolute;
      #{$textDirectionOpposite}: 25px;
      top: -5px;
      &>span{
        @extend .shows;
        width: 30px;
        height: 3px;
        margin-bottom: 5px;
        @extend .relative;
        background: $secondry-color;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
        &:first-child{
          transform-origin: 0% 0%;
        }
        &span:nth-last-child(2){
          transform-origin: 0% 100%;
        }
      }
    }

    i{
      float: #{$textDirectionOpposite};
    }
  }
  .oppenned{
    div.ham > span{
      background: $white;
    }
  }
}



/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked + div.ham > span
{
  opacity: 1;
  transform: rotate(45deg) translate(-25px, -18px);
  @extend .white-bg;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked + div.ham > span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked + div.ham > span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -10px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  @extend .absolute;
  width: 100%;
  margin: -100px 0 0 0;
  padding: 40px;
  padding-top: 125px;
  @extend .text-#{$textDirection};
  background: rgba(0, 52, 154, 0.9);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  @if $isRtl == true{
    transform: translate(100%, 0);
  }@else{
    transform: translate(-100%, 0);
  }
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 16px;
  float: #{$textDirection};
  display: block;
  width: 100%;
  @extend .white;
  &:hover,&:focus,&:active{
    background: #fff;
    color: #00349a;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    padding: 10px;
  }
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}
/*================================ mobile menu css end =====================================*/
*{
  padding: 0;
  margin: 0;
}
* {
  max-height: 1000000px;
}

/**, *:before, *:after {
  box-sizing: inherit;
}*/
.deal-bg{
  background:url('/web/images/bg-deals.jpg') no-repeat scroll 50% 0 / cover ;
  width:100%;
  background-attachment:fixed;
  height:auto;
}
html{
  direction: $textDirectionCode;
  body {
    @extend .no-margin;
    height:100%;
    min-width:320px;
    @extend .font-#{$textDirectionCode};
    overflow-y: scroll;
    -webkit-text-size-adjust:100%;
    -ms-text-size-adjust:none;
  }
}
/*================================ cookie css start =====================================*/
.cookie-container-bar{
  background: rgba(7, 75, 154,0.7);
  @extend .fixed;
  #{$textDirectionOpposite}: 0;
  #{$textDirection}: 0;
  bottom: 0;
  margin-bottom: 0;
  z-index: 1030;
  .cookie-note{
    padding: 5px 0px;
  }
  .cookie-text{
    color: #fff;
  }
  .cookie-btn{
    a{
      color: $white;
      border: 1px solid #fff;
      padding: 3px 10px;
      @extend .parent-transition;
      &:hover{
        color: $white;
        @extend .child-transition;
        box-shadow: 0 0px 14px rgba(0, 0, 0, 0.19), 0 1px 10px rgba(0, 0, 0, 0.23);
      }
    }
  }
}

/*================================ cookie css end =====================================*/
/*================================ Modals css start =====================================*/

#booking_confirm .modal-dialog {
  @extend .full-width;
}
#combine-page-map .modal-dialog {
  @extend .full-width;
}
.loader-search{
  width: 60%;
}
.modal-title{
  line-height: 23px;
  @include font-size($text-lg-size);
  color: $primary-color;
  @extend .text-center;
}
.social-sign{
  @include set-padding($padding-20 0 0);
  @include set-margin(0 0 $margin-20);
  list-style: $none;
  @extend .text-center;
  @include font-size($text-size+1);
  line-height: 19px;
  li{
    @include list-inline();
    @include set-margin(0);
    &:nth-of-type(2){
      a{
        color: $white;
        background: $g-plus;
      }
    }
    a{
      @extend .shows;
      min-width: 120px;
      @include set-padding($padding-5+1 $padding-15);
      color: $white;
      background: $fb-bg;
      @extend .text-#{$textDirection};
    }
  }
  //social sign clearfix
  @include clearfix();
}
.signpop-form{
  @extend .relative;
  max-width: 80%;
  @include set-margin(0 auto);
  label{
    color: $primary-text;
    @extend .shows;
    @include font-weight($font-normal);
  }
  .custom-field{
    @extend .custom_field_temp;
    @include set-margin(0 0 $margin-15);
    @include set-padding($padding-5+3 $padding-10+2);
    @include border-radius(2px);
    @include set-border(1px solid $secondry-text);
  }
  .btn-submit{
    @extend .shows;
    @extend .no-border;
    @extend .no-outline;
    background: $primary-color;
    color: $white;
    @include border-radius(2px);
    @include set-padding($padding-10 $padding-10+2);
    @include font-weight($font-bold);
    @extend .full-width;
    text-transform: capitalize;
    @include font-weight($font-bold);
    line-height: 20px;
    @extend .text-center;
  }
}
.search-img{
  padding-top: 20%;
}
.loader-search .modal-content{
  @extend .full-width;
}
.popup-overlay{
  z-index: 1;
  @extend .fixed;
  top: -100%;
  #{$textDirectionOpposite}: 0;
  @extend .full-width;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  @extend .no-scroll;
  &:before {
    content: '';
    @extend .absolute;
    top: 0;
    #{$textDirectionOpposite}: 0;
    bottom: 0;
    #{$textDirection}: 0;
    margin: auto;
    background: rgba(0, 52, 154, 0.98);
    @extend .full-width;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    -webkit-transform: scale(0.04), translateY(9999px);
    transform: scale(0.04), translateY(9999px);
    @extend .no-scroll;
  }
}
.open-overlay .popup-overlay {
  top: 0;
  &:before {
    opacity: 0.5;
    filter: alpha(opacity=50);
    -webkit-animation: menu-animation 0.8s ease-out forwards;
    animation: menu-animation 0.8s ease-out forwards;
  }
}
.thecube {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  margin-top: 50px;
  @extend .relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  .cube {
    &:before{
      content: '';
      @extend .absolute;
      top: 0;
      #{$textDirection}: 0;
      @extend .full-width;
      height: 100%;
      background-color: $secondry-color;
      -webkit-animation: foldthecube 2.4s infinite linear both;
      animation: foldthecube 2.4s infinite linear both;
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }
    @extend .relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
    @extend .pulls-#{$textDirection};
    width: 50%;
    height: 50%;
    @extend .relative;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .c2 {
    &:before {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
  }
  .c3 {
    &:before {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
  }
  .c4 {
    &:before {
      -webkit-animation-delay: 0.9s;
      animation-delay: 0.9s;
    }
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
  }
}

/*================================ Modal css end =====================================*/

.mobile-menu{
  @extend .hides;
}
#header{
  @extend .relative;
  @extend .white-bg;
  .top-header{
    @extend .relative;
    @include height(50px);
    .top-container{
      @extend .relative;
      @include set-margin(0 auto);
      .logo{
        @extend .pulls-#{$textDirection};
        max-width: 250px;
        @extend .relative;
        @include set-position(-25px,$textDirection);
      }
      .logo a{
        @extend .shows;
        @include set-padding(3px);
      }
      .logo a img{
        @extend .shows;
        @extend .img;
        height: 80px;
      }
      /*===================================top menu user profile div start====================================*/
      .top-nav{
        @extend .pulls-#{$textDirectionOpposite};
        .top-menus{
          @extend .no-padding;
          margin: 5px 0 0 0;
          font-size: 12px;
          @extend .list-none;
          @extend .inline-b;
          @extend .v-align-middle;
          >li{
            &.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
            &.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:focus{
              @extend .no-decoration;
              @extend .list-none;
              color:$white;
            }
            @extend .pulls-#{$textDirection};
            @extend .relative;
            @include set-margin(15px,$textDirection);
            #header_currency .btn {
              padding: 6px 20px;
            }
            &:after{
              @extend .absolute;
              content: '';
              $textDirection: 0;
              top: 0;
              height: 50px;
              width: 1px;
            }
            &:last-child:before{
              @extend .absolute;
              content: '';
              $textDirectionOpposite: 0;
              top: 0;
              height: 50px;
              width: 1px;
            }
            .dropdown-menu{
              >li{
                >a{
                  @extend .shows;
                  padding: 3px 20px;
                  clear: both;
                  font-weight: normal;
                  line-height: 1.42857143;
                  color: $semi-black;
                  white-space: nowrap;
                  cursor: pointer;
                  &:hover{
                    color: $white;
                  }
                }
              }
            }
            @if $isRtl == true{
              .flagstrap{
                &-icon{
                  margin-left: 10px !important;
                }
                span.caret{
                  margin-right: 10px !important;
                }
              }
            }
          }
        }
        .dropdown-menu{
          li{
            a:hover{
              background-color: $primary-color;
              color: $white;
            }
          }
        }
        .log-list{
          padding: 0 0 0 5px;
          margin: 0;
          font-size: 15px;
          @extend .list-none;
          @extend .inline-b;
          @extend .v-align-middle;
          @extend .relative;
          li{
            float: #{$textDirection};
            @extend .relative;
            &:first-child{
              a:last-child{
                img{
                  height: 20px;
                  width: 21px;
                  margin-top: 10px;
                  margin-#{$textDirection}: 13px;
                }
              }
            }
            a{
              border-radius: 0;
              min-width: 70px;
              outline: none;
              @extend .text-#{$textDirection};
              @extend .shows;
              padding: 5px 20px;
              color: $secondry-color;
            }
          }
          /*============login user==============*/
          .login{
            margin-top: 4px;
            li{
              &:not(:first-child){
                margin-#{$textDirection}: 10px;
              }
              @include box-shadow(0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23));
              border-radius: 4px;
              &:first-child{
                i:first-child{
                  font-size: 14px;
                }
              }
              i{
                margin-#{$textDirectionOpposite}: 4px;
              }
              &:hover{
                a{
                  color: $white;
                }
              }
            }
          }
          /*============login end==============*/
          /*============logged user==============*/
          .loged-user{
            > li.dropdown{
              .dropdown-menu{
                @extend .no-border-rad;
                @extend .no-padding;
                > li{
                  @extend .full-width;
                  &:first-child{
                    background: $primary-color;
                    height:3px;
                  }
                }
              }
            }
          }
          .profile-holder {
            margin-top: 10px;
            .p_image {
              @extend .inline-b;
            }
            .username,i{
              color: $secondry-color;
              &:hover{
                color: $secondry-color;
              }
            }
          }
          .full-profile > a.dropdown-toggle{
            @extend .no-decoration;
            @extend .no-outline;
          }
          /*============logged user end==============*/
          //log-list after
          @include clearfix();
        }

        //log-list after
        @include clearfix();
      }
      //top-container after
      @include clearfix();
    }
    #nav{
      @extend .relative;
      @include give_margin(-30px auto 0);
      line-height: 19px;
      @include font-weight(bold);
      @include set-padding(100px,$textDirection);
      @extend .text-#{$textDirectionOpposite};
      ul{
        @extend .list-none;
      }
      li{
        @extend .inline-b;
        @extend .v-align-middle;
        @include set-margin(10px,$textDirectionOpposite);
        &:last-child{
          @include set-margin(0px,$textDirectionOpposite);
          a{
            @include set-padding(0px,$textDirectionOpposite);
          }
        }
        & > a{
          @extend .text-color;
          @include set-padding($padding-5 $padding-15);
        }
        & > a:hover{
          color: $white;
          @extend .primary-color-bg;

        }
      }
    }
    //#top-header after
    @include clearfix();
  }
  //#header after
  @include clearfix();
}

/*================================ AutoComplete css start =====================================*/
.ui-datepicker-calendar .highlight_range.ui-state-disabled,
.ui-datepicker-calendar .highlight_range.ui-state-disabled span,
.ui-datepicker-calendar .highlight_range.ui-datepicker-current-day:not(.ui-datepicker-days-cell-over) > a{
  background-color: $secondry-color;
  color: $white;
}
.ui-datepicker-calendar .highlight_range > a,
.ui-datepicker-calendar .highlight > a{
  background-color: $primary-color;
  color: $white;
}
.ui-datepicker-calendar .highlight_range.ui-state-disabled{
  opacity: 1;
}
/*Auto complete css new Start*/
.ui-menu-item {
  @include set-border(1px solid #ccc,bottom);
  @include set-border(1px solid #ccc,top);
  padding: 5px 15px;
}
.highlight-search-text{
  background: $primary-color none repeat scroll 0 0;
  @include set-border(1px solid transparent);
  color: $white;
  padding: 4px 6px;
}
.ui-autocomplete .ui-menu-item .ui-state-active.ui-menu-item-wrapper{
  background: transparent;
}
.ui-autocomplete .ui-menu-item:not(:hover) .ui-state-active:not(.ui-menu-item-wrapper){
  color: $secondry-color;
}
/*Auto complete css new End*/
/*================================ AutoComplete css end =====================================*/
/*================================ general css start =====================================*/
.loading {
  @include loaderBefore($textDirectionOpposite,24px);
  @include loaderAfter($textDirectionOpposite,20px);
}
#signup,#signin{
  .modal-footer{
    max-width:80%;
    @extend .margin-0-auto;
    @extend .text-#{$textDirection};
  }
}
.no-padding-left{
  @include set-padding(0,#{$textDirection});
}
.no-padding-right{
  @include set-padding(0,#{$textDirectionOpposite});
}
.c-select{
  &-right{
    &:after{
      #{$textDirectionOpposite}:0;
    }
  }
  &-left{
    &:after{
      #{$textDirectionOpposite}:0;
    }
  }
}
.c-popup{
  .modal-content{
    &.hotel-remarks{
      @include set-padding(30px,top);
      @include set-padding(40px,#{$textDirectionOpposite});
      @include set-padding(15px,bottom);
      @include set-padding(15px,#{$textDirection});
    }
    @extend .no-scroll;
    @extend .no-border-rad;
  }
  .mfp-close{
    &:before{
      background-color: $secondry-color;
      content: '';
      @extend .shows;
      @extend .absolute;
      width: 90px;
      height: 90px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      top: -45px;
      #{$textDirectionOpposite}: -45px;
      z-index:1;
    }
    color: $primary-text-dark;
    @extend .absolute;
    background-color: transparent;
    width: 45px;
    height: 45px;
    top: 0;
    #{$textDirectionOpposite}: 0px;
    @extend .no-scroll;
    font-size: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    &:after{
      color: #ffffff;
      content: "×";
      @extend .shows;
      @extend .absolute;
      top: 0px;
      #{$textDirection}: 10px;
      @extend .text-center;
      width: 35px;
      font-size: 28px;
      line-height: 34px;
      z-index: 2;
    }
  }
}
.not-img{
  width: 380px;
  img{
    @extend .img;
  }
}
.cd-top{
  @include set-position(10px,#{$textDirectionOpposite});
}
.bootstrap-select{
  &.btn-group{
    .dropdown-toggle{
      .filter-option{
        @extend .text-#{$textDirection};
      }
      .caret{
        #{$textDirectionOpposite}:12px;
        #{$textDirection}:initial;
      }
    }
  }
  >.dropdown-toggle{
    @include set-padding(25px,#{$textDirectionOpposite});
  }
}
@for $i from 1 through 12{
  .col-md-#{$i}{
    @extend .col-md-#{$i};
  }
}
@for $i from 1 through 12{
  .col-sm-#{$i}{
    @extend .col-sm-#{$i};
  }
}
@for $i from 1 through 12{
  .col-xs-#{$i}{
    @extend .col-xs-#{$i};
  }
}
/*================================ general css end =====================================*/
/*======================= user profile css start==========================*/
.fav-property-cards{
  display: flex;
  flex-wrap:wrap;
  .fav-property-card{
    @include set-margin(1%,#{$textDirection});
    @include set-margin(0,#{$textDirectionOpposite});
    @include set-margin($margin-15,bottom);
    @include set-margin(0,top);
    @include width(115%);
    cursor: pointer;
    vertical-align: text-top;
    @include set-border(1px solid #DDD);
    color: rgba(0,0,0,.8);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    .card-img{
      @extend .relative;
      @extend .full-width;
      flex-grow: 0;
      i.fa{
        @extend .absolute;
        @include z-index(1);
        @include set-position(10px,top);
        @include set-position(10px,top);
        @include set-position(10px,#{$textDirectionOpposite});
        @include font-size($text-size-24+1);
        @extend .green;
        opacity: .9;
      }
      img{
        @extend .full-width;
        max-height: 200px;
        min-height: 200px;
      }
    }
    .card-content{
      @include set-padding($padding-10+2 ($padding-15 - 1) $padding-10+1);
      display: flex;
      flex: 0 1 auto;
      flex-grow: 0;
      flex-direction: column;
      flex-grow: 1;
    }
    .hotel-name{
      @include font-size($text-size - 1);
      @include font-weight($font-bold);
      @extend .second-color;

    }
    .area-name{
      color: rgba(0,0,0,.4);
    }
    .stars{
      &,.item .stars{
        img{
          width: unset;
          max-width: 100%;
          @extend .inline-b;
        }
      }
      @include font-size(1px);
      @extend .inline-b;
      @include set-padding(5px,right);
      vertical-align: text-top;
    }
    .card-review{
      @include set-border(1px #DDD solid,top);
      @include set-border(1px #DDD solid,bottom);
      @include set-padding($padding-5+2 0);
      @include set-margin($margin-10+2 0 $margin-10 - 2);
      .rating{
        &-score{
          @include font-weight($font-bold);
        }
        &-text{
          color: rgba(0,0,0,.4);
        }
      }
      .review{
        &-count{
          @extend .rating-score;
        }
        &-text{
          @extend .rating-text;
        }
      }
    }
    .bottom-content{
      @extend .relative;
      @extend .input_temp;
    }
    .card-content{
      .flex-parent{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: flex-end;
        @extend .second-color;
      }
    }
    .prices{
      @extend .text-#{$textDirection};
      align-self: flex-end;
      flex-grow: 1;
      .currency{
        color: rgba(0,0,0,.4);
      }
      .price{
        @include font-size($text-lg-size);
        @extend .second-color;
        @include line-height(1.1);
      }
    }
  }
}
.nice_see {
  .hi{
    .hi_img{
      @include height(130px);
      @extend .absolute;
      @include set-position(0,#{$textDirectionOpposite});
      @include set-position(0,top);
      @include width(130px);
      @if $isRtl == true{
        @extend .flip-horizental;
      }
    }
  }
}
/*======================= user profile css end==========================*/
/*================================ homepage css start =====================================*/
.search-deals {
  padding: 15px 0 0;
  .left-content {
    @extend .pulls-#{$textDirection};
    width: 49%;
    /**** search-holder *****/
    .search-holder {
      @extend .relative;
      @extend .primary-color-bg;
      border-radius: 3px;
      box-shadow: 0px 0px 6px rgb(153, 153, 153);
      .search-content {
        position: relative;
        padding: 20px 15px;
        /**** nav-tabs *****/
        .nav-tabs{
          border-bottom: 5px solid $secondry-color;
          @extend .no-padding;
          &>li{
            &.active{
              &>a,&>a:hover,&>a:focus{
                @extend .no-border;
                @extend .no-outline;
                color: $secondry-color;
                @extend .no-background;
              }
            }
            &:last-child{
              @extend .no-margin;
            }
            @if $isRtl == true{
              @extend .pulls-#{$textDirection};
              @include set-margin(1%,#{$textDirectionOpposite});
            }@else{
              @include set-margin(3%,#{$textDirectionOpposite});
            }
            &>a{
              color: $secondry-color;
              font-size: 15px;
              font-weight: bold;
              padding: 5px 20px;
              @extend .no-border;
              @extend .no-background;
            }
          }
        }
        .tab-content{
          .tab-holder{
            @extend .relative;
            padding: 10px 0 0;
            h2{
              margin: 0 0 7px;
              color: $secondry-color;
              font-size: 20px;
              line-height: 24px;
            }
            .search-form{
              @extend .relative;
              label{
                @extend .shows;
                color: $secondry-color;
              }
              .custom-field{
                @extend .shows;
                @extend .full-width;
                border: 1px solid rgba(0,54,153,0.7);
                padding: 6px 8px;
                @extend .white-bg;
                @extend .black;
                margin: 0 0 10px;
              }
              .dates-holder{
                @extend .relative;
                .date-left{
                  @extend .pulls-#{$textDirection};
                  width: 49%;
                  @extend .relative;
                  &:before{
                    #{$textDirection}: 8px;
                  }
                  &:after{
                    #{$textDirectionOpposite}: 12px;
                  }
                  .custom-field{
                    @include set-padding(6px 0);
                    @include set-padding(8px,$textDirectionOpposite);
                    @include set-padding(30px,$textDirection);
                  }
                }
                .date-right{
                  @extend .pulls-#{$textDirectionOpposite};
                  width: 49%;
                  @extend .relative;
                  &:before{
                    #{$textDirection}: 8px;
                  }
                  &:after{
                    #{$textDirectionOpposite}: 12px;
                  }
                  .custom-field{
                    @include set-padding(6px 0);
                    @include set-padding(8px,$textDirectionOpposite);
                    @include set-padding(30px,$textDirection);
                  }
                }
                //dates-holder after
                @include clear-fix();
              }
              .counter-container {
                .day-counter, .night-counter {
                  display: inline-block;
                  margin-#{$textDirection}: 30%;
                }
                border: 1px dashed;
                padding: 10px;
              }
              .selects-holder {
                @extend .relative;
                margin: 0 0 10px;
                .c-select-#{$textDirectionOpposite}{
                  &:after{
                    #{$textDirectionOpposite}:0px;
                  }
                }
                .select-col{
                  &.drawChildAges {
                    @include set-margin(10px,top);
                    @extend .full-width;
                  }
                  @extend .pulls-#{$textDirection};
                  @extend .no-margin;
                  @include set-margin(5px,$textDirectionOpposite);
                  &-childern {
                    @extend .pulls-#{$textDirection};
                    width: 24.28%;
                    @extend .no-margin;
                    @include set-margin(5px,$textDirectionOpposite);
                    &:last-child{
                      @include set-margin(0px,$textDirectionOpposite);
                    }
                  }
                }
                //select-holder after
                @include clear-fix();
              }
              //search-form after
              @include clear-fix();
            }
            .btn-search{
              @extend .pulls-#{$textDirectionOpposite};
              @extend .no-outline;
              @extend .no-border;
              background: $secondry-color;
              @extend .white;
              padding:7px 15px;
              min-width:150px;
              @extend .text-center;
              font-size:20px;
              line-height:25px;
              -webkit-border-radius:2px;
              -moz-border-radius:2px;
              border-radius:2px;
              @extend .parent-transition;
              &:hover{
                @extend .white;
                background-color: $primary-color;
                border-color: $primary-color-mid;
                @extend .child-transition;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
              }
            }
            //tab-holder after
            @include clear-fix();
          }
        }
      }
      .search-footer{
        @extend .relative;
        @include set-padding(20px 15px);
        color: $white;
        @extend .second-color-bg;
        @extend .parent-transition;
        a{
          color: $white;
          cursor: pointer;
        }
        .icon-holder{
          @include set-margin(15px,$textDirectionOpposite);
          @extend .pulls-#{$textDirection};
          max-width: 35px;
          img{
            @extend .img;
          }
        }
        strong{
          @extend .shows;
          @include font-weight(normal);
          @include font-size($text-md-size);
        }
        .subscribe-form{
          @include set-margin(20px 0 0);
          @extend .relative;
          .custom-field{
            @extend .custom-field;
            @extend .shows;
            @extend .full-width;
            @include set-padding(6px 8px);
            color: $black;
            @include set-margin(0 0 10px);
          }
          .btn-signup{
            @extend .absolute;
            @extend .no-border;
            @extend .no-outline;
            @include font-size(15px);
            @extend .second-color-bg;
            @extend .white;
            @include set-position(3px,$textDirectionOpposite);

            @include set-position(2px,bottom);
            @if $isRtl == true{
              @include set-padding(0px 12px);
              @include set-position(2px,top);
            }@else{
              @include set-padding(7px 12px);
              @include set-position(2px,top);
            }
          }
        }
        //search-footer clearfix
        @include clearfix();
        &:hover{
          @include box-shadow(1px 2px 20px rgba(0, 0, 0, 0.48));
          @extend .child-transition;
        }
      }
    }
    .info-text-free{
      @extend .relative;
      &.portlet{
        @extend .portlet-parrent
      }
      h3{
        @include font-size($text-xlg-size);
        line-height: 34px;
        @extend .no-margin;
        color: $secondry-color;
      }
      //info text free clearfix
      @include clearfix();
    }
    .just-booked-holder{
      @extend .no-scroll;
      @extend .relative;
      max-height: 380px;
      min-height: 380px;
      &.portlet-home{
        @extend .portlet-parrent;
        background-color: $bg-of-white;
      }
      .just-booked-slider{
        @extend .relative;
        background: $bg-of-white;
        @include set-padding($padding-15);
      }
      h2{
        @include set-margin(0 0 $margin-15);
        @extend .second-color;
        @include font-size($text-lg-size);
        line-height: 28px;
        @include font-weight($font-bold);
      }
      .bx-wrapper{
        @extend .no-margin;
        .bx-viewport{
          @include set-position(0,#{$textDirectionOpposite});
          @extend .no-boxshadow;
          @extend .no-border;
          @extend .no-background;
          ul{
            @extend .no-margin;
          }
          .booked-post{
            background: $white;
            @extend .no-scroll;
            @extend .relative;
            @include set-margin(0 0 $margin-15);
            @include set-border(1px solid #e5e3e3);
            @include set-border(3px solid $primary-color,$textDirection);
            @extend .child-transition;
            &:before{
              content: '';
              @extend .absolute;
              @include set-position(50%,top);
              @include set-position(0,$textDirection);
              @extend .child-transition;
              @include set-margin(-$margin-10 0 0);
              border-style: solid;
              @include set-border-width(10px,top);
              @include set-border-width(0,#{$textDirectionOpposite});
              @include set-border-width(10px,bottom);
              @include set-border-width(5px,#{$textDirection});
              @include set-border-color(transparent,top);
              @include set-border-color(transparent,#{$textDirectionOpposite});
              @include set-border-color(transparent,bottom);
              @include set-border-color($primary-color,#{$textDirection});
            }
            &:hover{
              background: #fdf9f9;
              @include set-border(3px solid $secondry-color,$textDirection);
            }
            &:hover:before{
              @include set-border-color(transparent,top);
              @include set-border-color($secondry-color,#{$textDirectionOpposite});
              @include set-border-color(transparent,bottom);
              @include set-border-color(transparent,#{$textDirection});
            }
            a{
              @extend .show;
              @extend .no-scroll;
              @include set-padding($padding-15);
              color: $primary-text;
            }
            .pic-holder{
              @extend .pulls-#{$textDirection};
              @extend .no-margin;
              @include set-margin($margin-15,#{$textDirectionOpposite});
              img{
                @extend .show;
                @extend .img;
              }
            }
            .booked-post-content{
              @extend .relative;
              line-height: 17px;
              @include font-size($text-size);
              @include set-padding(($padding-5 - 2) 0 0);
              h3{
                line-height: 19px;
                @include font-size($text-size+1);
                @include font-weight($font-bold);
                @include set-margin(0);
              }
              p{
                @include set-margin(0);
              }
              span{
                color: $primary-color;
              }
            }
          }
        }
        .bx-controls{
          @extend .hidden;
        }
      }
    }
  }
  .right-content{
    @extend .pulls-#{$textDirectionOpposite};
    @include width(50%);
    .packages-holder{
      @extend .relative;
      .package-post{
        &.portlet-home{
          @extend .portlet-parrent;
          @extend .relative;
          @include set-margin(0 0 ($margin-15 - 1));
          @extend .no-padding;
        }
        &.package-post:before,
        &.package-post:after{
          content: '';
          background: $bg-of-white;
          @include set-position(80%,top);
          @include set-position(10px,$textDirection);
          @include set-position(15px,bottom);
          @include set-position(initial,$textDirectionOpposite);
          max-width: 300px;
          @extend .absolute;
          transform: rotate(-3deg);
          @include width(50%);
          @include z-index(-1);
          @extend .child-transition;
          @include box-shadow(0 15px 10px #ccc);
        }
        .txt-still-question{
          @extend .relative;
          background: $still-interested-heading-bg;
          @include font-size($text-size);
          line-height: 21px;
          color: $black;
          @include font-weight($font-bold);
          @include set-margin(0 0 $margin-10);
          @include set-padding($padding-10);
        }
        .package-content{
          @extend .relative;
          &:before{
            content: '';
            @extend .absolute;
            background: rgba(0,0,0,.5);
            @include position(0,0,0,0);
            @include z-index(1);
          }
          .pic-holder{
            @extend .relative;
            a{
              @extend .shows;
              @extend .relative;
              @include z-index(1);
            }
            img{
              @extend .shows;
              @extend .img;
              max-height: 238px;
            }
          }
          .package-over{
            @extend .absolute;
            content: '';
            @include set-position(0,top);
            @include set-position(0,$textDirection);
            @extend .full-width;
            @include z-index(2);
            @include set-padding($padding-15);
            @extend .child-transition;
            .package-info{
              @extend .relative;
              @extend .pulls-#{$textDirection};
              h2{
                @include set-margin(0);
                @include font-weight($font-bold);
                line-height: 40px;
                @include font-size($text-xlg-size + 13);
                color: $white;
              }
              strong{
                @extend .show;
                color: $white;
                line-height: 21px;
                @include font-size($text-md-size - 1);
              }
            }
            .package-list{
              @extend .list-none;
              @include set-padding($padding-10 0 0 $padding-5);
              @extend .pulls-#{$textDirectionOpposite};
              li{
                @extend .inline-b;
                @extend .v-align-middle;
                @extend .no-margin;
                @include set-margin(($margin-5 + 3),$textDirection);
                a{
                  background: $primary-color;
                  color: $white;
                  @include width(45px);
                  @include height(45px);
                  @include border-radius(50%);
                  @extend .text-center;
                  @extend .shows;
                  .fa{
                    @extend .shows;
                    @include font-size($text-xlg-size);
                    line-height: 46px;
                  }
                }
                &:last-child > a{
                  background: $white;
                  color:#e4001b;
                }
              }
            }
          }
          //package content clearfix
          &:after{
            @extend .show;
            clear: both;
            content:'';
          }
        }
        .package-footer{
          @extend .relative;
          @include set-border(1px solid #ccc);
          @include give_padding($padding-10 + 2 $padding-15);
          color: $primary-color;
          @include font-size($text-size);
          line-height: 21px;
          background: $white;
          @extend .child-transition;
          strong{
            @extend .show;
            a{
              color: $primary-color;
              @extend .child-transition;
            }
          }
        }

        &:hover .package-over{
          @include box-shadow(0 18px 27px $secondry-color inset);
        }
        &:hover .package-footer{
          @include set-border(1px solid $secondry-color);
          background: #fdf9f9;
          a{
            color: $secondry-color;
          }
        }
        //package post clearfix
        @include clearfix();
      }
      //package holder clearfix
      @include clearfix();
    }
    //right content clearfix
    @include clearfix();
  }
}
.listings-holder{
  @include set-padding($padding-20 0);
  .love-travels-holder{
    @extend .relative;
    @extend .pulls-#{$textDirection};
    @include width(49%);
    &.portlet{
      @extend .portlet-parrent;
    }
    h2{
      @include set-margin(0 0 $margin-15);
      @include font-size($text-size-24 + 4);
      line-height: 32px;
      color: $secondry-color;
    }
    .love-list{
      @extend .list-none;
      @include set-margin(0 0 $margin-15);
      @extend .no-scroll;
      & li{
        @include set-padding($padding-10 0);
        @extend .relative;
        //love list clearfix
        @include clearfix();
      }
      & li:nth-of-type(2){
        .icon-holder{
          background: $home-icon-2;
        }
      }
      & li:nth-of-type(3){
        .icon-holder{
          background: $home-icon-3;
        }
      }
      & li:nth-of-type(4){
        .icon-holder{
          background: $home-icon-4;
        }
      }
      & li:nth-of-type(5){
        .icon-holder{
          background: $home-icon-5;
        }
      }
      .icon-holder{
        @extend .pulls-#{$textDirection};
        @include width(80px);
        @include height(80px);
        @include border-radius(50%);
        background: $home-icon-1;
        @include set-padding(($padding-25 + 3) 0);
        @extend .no-margin;
        @include set-margin($margin-15,$textDirectionOpposite);
        img{
          @extend .shows;
          @extend .img;
          max-width: 25px;
          @extend .margin-0-auto;
        }
      }
      .love-content{
        @extend .relative;
        @include font-size($text-size);
        line-height: 18px;
        @include set-padding($padding-10 0 0);
        @extend .second-color;
        h3{
          @include set-margin(0);
          color: $black;
          @include font-size($text-size + 3);
          line-height: 23px;
          @include font-weight(500);
        }
        a{
          @include font-size($text-size);
          color: $primary-color;
          @extend .no-decoration;
          @include font-weight($font-bold);
        }
      }
    }
    .rating-holder{
      @extend .relative;
      @include set-margin(0 0 $margin-20);
      @include set-padding($padding-10 $padding-15);
      @include box-shadow(0 0 6px #999);
      h2{
        color: $secondry-color;
        @include font-size($text-xlg-size);
        line-height: 30px;
        @include set-margin(0 0 $margin-15);
      }
      img{
        @extend .shows;
        @extend .img;
      }
      //rating holder clearfix
      @include clearfix();
    }
    .just-booked-holder{
      @extend .relative;
      @extend .no-scroll;
      .just-booked-slider{
        @extend .relative;
        background: #f0f0f0;
        @include set-padding($padding-15);
        &.portlet-home{
          @extend .portlet-parrent;
          @extend .no-padding;
        }
        .bx-wrapper{
          @extend .no-margin;
          .bx-viewport{
            @include set-position(0,$textDirection);
            @extend .no-boxshadow;
            @extend .no-border;
            @extend .no-background;
            min-height: 400px;
            ul{
              @extend .no-padding;
            }
            .booked-post{
              background: $white;
              @extend .no-scroll;
              @extend .relative;
              @include set-margin(0 0 $margin-15);
              @include set-border(1px solid #e5e3e3);
              @include set-border(3px solid $primary-color,$textDirection);
              @extend .child-transition;
              &:before{
                content: '';
                @extend .absolute;
                @include set-position(50%,top);
                @include set-position(0,$textDirection);
                @extend .child-transition;
                @include set-margin(-$margin-10 0 0);
                border-style: solid;
                @include set-border-width(10px,top);
                @include set-border-width(0,#{$textDirectionOpposite});
                @include set-border-width(10px,bottom);
                @include set-border-width(5px,#{$textDirection});
                @include set-border-color(transparent,top);
                @include set-border-color(transparent,#{$textDirectionOpposite});
                @include set-border-color(transparent,bottom);
                @include set-border-color($primary-color,#{$textDirection});
              }
              &:hover{
                background: #fdf9f9;
                @include set-border(3px solid $secondry-color,$textDirection);
              }
              &:hover:before{
                @include set-border-color(transparent,top);
                @include set-border-color(transparent,#{$textDirectionOpposite});
                @include set-border-color(transparent,bottom);
                @include set-border-color($secondry-color,#{$textDirection});
              }
              p{
                a{
                  @extend .inline-b;
                  vertical-align: $vert-align;
                  @include set-padding(0);
                  color: $primary-color;
                }
              }
              .pic-holder{
                @extend .pulls-#{$textDirection};
                @include set-margin(0 0 0 $margin-15);
                img{
                  @extend .shows;
                  @extend .img;
                }
              }
              .booked-post-content{
                @extend .relative;
                line-height: 17px;
                @include font-size($text-size);
                @include set-padding(($padding-5 - 2) 0 0);
                h3{
                  line-height: 19px;
                  @include font-size(($text-size+1));
                  @include font-weight($font-bold);
                  @extend .no-margin;
                }
                p{
                  @extend .no-margin;
                }
                span{
                  color: $primary-color;
                }
              }
            }
          }
          .bx-controls{
            @extend .hidden;
          }
        }
      }
      &.recent-reviews{
        h2{
          @include font-size($text-lg-size);
          line-height: 23px;
        }
        .bookd-post-content{
          @include set-padding($padding-10 $padding-15);
          color: $home-icon-2;
        }
        .bx-controls{
          @extend .hidden;
        }
      }
    }
    //love travel holder clearfix
    @include clearfix();
  }
  .other-packages{
    @extend .pulls-#{$textDirectionOpposite};
    @include width(50%);
    &.portlet{
      @extend .portlet-parrent;
    }
    .other-post{
      @extend .no-scroll;
      @extend .relative;
      @include set-border(1px solid $primary-color);
      @include set-padding($padding-10);
      @include set-margin(0 0 $margin-5);
      @extend .child-transition;
      &:before{
        content: "\f053 ";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        @include font-size($text-md-size);
        @extend .no-padding;
        @include set-padding(0.5em,$textDirection);
        @extend .absolute;
        @include position(45px,top);
        @include set-position(8px,$textDirectionOpposite);
        color: $primary-color;
        @extend .no-margin;
        @extend .child-transition;
      }
      .pic-holder{
        @extend .pulls-#{$textDirection};
        max-width: 100px;
        @extend .no-margin;
        @include set-margin($margin-15,$textDirectionOpposite);
        img{
          @extend .shows;
          @extend .img;
        }
      }
      .other-post-content{
        @extend .relative;
        color: $secondry-text;
        @include font-size($text-md-size);
        line-height: 17px;
        @extend .no-scroll;
        h3{
          @extend .no-margin;
          color: $primary-color;
          @include font-size($text-size-24 + 1);
          line-height: 29px;
          @include font-weight(700);
          @extend .inline-b;
          @extend .v-align-middle;
          @extend .child-transition;
          a{
            @extend .child-transition;
            color: $primary-color;
          }
        }
        .tool-info{
          @extend .inline-b;
          @extend .v-align-middle;
          @extend .no-scroll;
          @extend .list-none;
          & li:first-child{
            @include border-radius(50%);
            a{
              background: $primary-color;
              i{
                color: $white;
              }
            }
          }
          & li{
            @extend .inline-b;
            @extend .v-align-middle;
            @extend .no-margin;
            @include set-margin(($margin-5 - 3),$textDirectionOpposite);
            a.tooltip {
              @extend .opacity-1;
              @include font-size($text-size);
              @extend .relative;
            }
            a{
              @include width(24px);
              @include height(24px);
              @include border-radius(50%);
              @extend .shows;
              @extend .text-center;
              @include set-border(1px solid $primary-color);
              line-height: 24px;
              @extend .child-transition;
              i{
                color: $primary-color;
              }
            }
          }
        }
        .tag-list{
          @extend .list-none;
          @include set-padding($padding-15 0 0);
          @extend .no-scroll;
          @include font-size($text-size);
          line-height: 18px;
          @include font-weight($font-bold);
          & li{
            @extend .inline-b;
            vertical-align: top;
            a{
              color: $primary-color;
              @extend .child-transition;
            }
          }
        }
      }
      //other post clearfix
      @include clear-fix();
      &:hover{
        @include set-border(1px solid $secondry-color);
        background: #fdf9f9;
        &:before{
          color: $secondry-color;
        }
      }
    }
    .more-destination{
      color: $primary-color;
      @include font-weight($font-bold);
      @include font-size($text-size);
      line-height: 21px;
      @extend .relative;
      @extend .pulls-#{$textDirectionOpposite};
      &:hover{
        color: $secondry-color;
      }
    }
  }
}
//discover slider css
.still-deciding-holder{
  @extend .container;
  @extend .relative;
  &.portlet{
    @extend .portlet-parrent;
    @include set-margin(0 auto);
  }
  h2{
    @include font-weight($font-bold);
    line-height: 28px;
    @include font-size($text-lg-size);
    @extend .second-color;
    @extend .no-margin;
  }
  .discover-carusel-holder{
    @extend .relative;
    @include set-padding($padding-10 0 0);
    .item{
      @extend .relative;
      transition-duration: 0.3s;
      width: 75%;
      @include set-margin(0 $margin-20);
      &:hover{
        .discover-over{
          transform: scale(.98);
        }
      }
    }
    .pic-holder{
      @extend .relative;
      img{
        @extend .shows;
        @extend .img;
      }
      .discover-over{
        @extend .absolute;
        @include set-position(10px,10px,10px,10px);
        background: rgba(0, 0, 0, .75);
        @include border-radius(10%);
        transform: scale(0);
        transition-duration: 0.3s;
        transition-property: transform;
        transition-timing-function: ease-out;
        color:$white;
        @include font-size($text-size);
        line-height: 17px;
        @include set-padding(($padding-30 + 35) $padding-15 $padding-15);
        @extend .text-center;
        p{
          @extend .no-margin;
        }
      }
    }
    .discover-text{
      color: $primary-color;
      @include font-size($text-size+1);
      @include font-weight($font-bold);
      line-height: 20px;
      @include set-padding($padding-10 0 0);
      @extend .text-center;
      a{
        color: $primary-color;
      }
    }
    p{
      text-overflow: ellipsis;
      white-space: nowrap;
      @extend .no-scroll;
    }
  }
  .owl-nav{
    & > .owl-{
      @extend .absolute;
      @include set-position(50%,top);
      @extend .btn-success;
      @include border-radius(50%);
      @include set-padding(0px $padding-5 - 1);
      @extend .parent-transition;
      &prev{
        @extend .owl-;
        @include set-position(-10px,$textDirection);
      }
      &next{
        @extend .owl-;
        @include set-position(-10px,$textDirectionOpposite);
      }
    }
  }
  //discover holder clearfix
  @include clearfix();
}
/*================================ homepage css end =====================================*/

/*============================= search index style start==============================*/
.ui-autocomplete {
  .ui-menu{
    &-item {
      &:not(:hover){
        .ui-state-active{
          @extend .black;
        }
      }
      .ui-state-active:not(.ui-menu-item-wrapper){
        background: transparent;
      }
    }
  }
  &.ui-menu{
    li{
      &:hover{
        background: $primary-color;
      }
    }
  }
}
.breadcrumbs-wrapper{
  @include set-padding(0,top);
  @include box-shadow(0 4px 8px rgba(0, 0, 0, 0.06));
  @include set-margin($margin-15,bottom);
  .breadcrumb{
    background: $breadcrumb-bg;
    @include set-margin(0,bottom);
  }
  //breadcrumbs wrapper clearfix
  @include clearfix();
}
.search-form{
  &.hotel-listing{
    box-shadow: 0 4px 4px -2px rgb(153, 153, 153);
    -moz-box-shadow: 0 4px 4px -2px rgb(153, 153, 153);
    -webkit-box-shadow: 0 4px 4px -2px rgb(153, 153, 153);
    z-index: 1;
    @extend .relative;
  }
  @extend .relative;
  @extend .main-font;
  .top_seach_panel{
    @extend .shows;
    @include set-padding($padding-10 0 $padding-15);
    .input-group{
      @include set-border(medium none);
      @include border-radius(2px);
      @include box-shadow(none);
      @extend .full-width;
      .input-group-addon{
        background-color: $white;
        @include set-border(1px solid $divider);
        @include set-border(none,$textDirectionOpposite);
        .hotel_icon_location{
          @include _spreadsheet(-383px -826px);
        }
        .hotel-date-icon{
          @include _spreadsheet(-403px -827px);
          @include width(16px);
          @include height(17px);
        }
        &:first-child{
          @include border-radius(0,3px,3px,0);
        }
      }
      .form-control{
        background: $white;
        @include z-index(1);
        &:last-child{
          @if $isRtl == true{
            @include border-radius(3px,0,0,3px);
          }@else{
            @include border-radius(0px 3px 3px 0px);
          }
        }
      }
    }
    input[type="text"]{
      @include height(40px);
    }
    .form-control{
      @include font-size($text-size);
    }
    .room-occupanc{
      .btn-group{
        &.ddl{
          @extend .full-width;
          @include set-border(medium none);
          @extend .full-width;
          @extend .no-boxshadow;
          @include border-radius(2px);
          .text{
            &.arrow-,&.arrow-:hover,&.arrow-:focus{
              @extend .no-boxshadow;
              @include height(40px);
              @include set-margin(0,bottom);
              background-color: $white;
              @include width(80%);
              @extend .no-scroll;
              @extend .no-outline;
              @include set-border(1px solid $divider);
              @include set-margin(0,top);
            }
          }
          .dropdown-toggle{
            &.arrow-,&.arrow-:hover,&.arrow-:focus{
              @include set-border(1px solid $divider);
              @include box-shadow($none);
              @include height(40px);
              background-color: $white;
              @include width(20%);
            }
          }
          .hotel_icon_occupancy{
            @include _spreadsheet(-426px -825px);
            @include height(25px);
            @include width(17px);
            @extend .no-margin;
            @include set-margin($margin-5+2,$textDirection);
            @include set-padding(0 $padding-10);
            @extend .pulls-#{$textDirectionOpposite};
            @extend .inline-b;
          }
        }

      }
      .customize-form{
        &:before{
          border-color: rgba(204, 204, 204, 0);
          border-bottom-color: rgba(204, 204, 204, 0);
          border-bottom-color: #ccc;
          border-width: 12px;
          @include set-margin(-12px,right);
        }
        @extend .absolute;
        @extend .white-bg;
        @include set-border(1px solid #ccc);
        @include set-margin($margin-10,top);
        @include width(420px);
        @include set-position(0,$textDirectionOpposite);
        overflow-y: auto;
        @include set-position(auto,$textDirection);
        @extend .no-scroll;
        max-height: 300px;
        label{
          @include font-size($text-size - 2);
          @extend .show;
          @extend .text-c-dark;
          @include font-weight($font-normal);
          @extend .text-#{$textDirection};
        }
        .holidaymselect_W{
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          @extend .white-bg;
          background-image: $uparrow;
          @if $isRtl == true{
            background-position: 10% center;
          }@else{
            background-position: 90% center;
          }
          background-repeat: no-repeat;
          @include set-border(1px solid #fff);
          @include border-radius(3px);
          @extend .text-c-dark;
          @include font-size($text-size);
          @include set-margin($margin-5,$textDirectionOpposite);
          @include set-padding($padding-5 $padding-5);
          text-indent: 0.01px;
          text-overflow: "";
          @include width(85px);
          @include set-border(1px solid $divider);
        }
        &:after,&:before{
          bottom: 100%;
          @include set-position(50%,$textDirection);
          @include set-border(solid transparent);
          border-top-width: medium;
          border-right-width: medium;
          border-bottom-width: medium;
          border-left-width: medium;
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
          content: " ";
          @include height(0);
          @include width(0);
          @extend .absolute;
          pointer-events: none;
        }
        &:after{
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #fff;
          border-width: 11px;
          @include set-margin(-12px,$textDirection);
        }
      }
    }
    .btn-pink{
      @include font-weight($font-bold);
      @include font-size($text-size);
      @include height(40px);
      @extend .text-uppercase;
      @extend .full-width;
      background-color: $primary-color;
      @include border-radius(2px);
      color: $white;
    }
  }
}
#container-floating{
  @extend .hides;
}
.ae-mobile{
  .container-floating-parrent {
    z-index: 2;
    & > #container-floating{
      z-index: 50;
      @extend .hidden-sm;
      @extend .hidden-md;
      @extend .hidden-lg;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      @extend .relative;
      @extend .inline-b;
      @extend .pulls-#{$textDirection};
      width: 16.66%;
      &:hover {
      }
      #floating-button{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        width: 70px;
        height: 40px;
        @extend .second-color-bg;
        #{$textDirection}: 15px;
        cursor: pointer;
        box-shadow: 0px 2px 5px #666;
        top:10px;
        .plus{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
          @extend .white;
          @extend .text-center;
          font-size: 16px;
          @extend .relative;
          top:8px;
        }
      }
    }
  }
}
#listing-page{
  &.main-content{
    @extend .relative;

    @include set-padding($padding-15,bottom);
  }
  &.container{
    @extend .container;
  }
  #sidebar{
    @extend .pulls-#{$textDirection};
    @include width(24%);
    @include box-shadow(0px 0px 6px rgb(153, 153, 153));
  }
  @media only screen and (max-width: 800px){
    #sidebar{
      #{$textDirection}:0 !important;
    }
  }
  .widget-filter{
    @extend .relative;
    @include set-border(1px solid $primary-color);
    @include border-radius(3px);
    @include set-margin(0 0 $margin-15);
    .Hotel-total-count-wrap{
      background: $primary-color;
      color: #fff;
      @include font-size($text-size);
      letter-spacing: 0.2px;
      @include set-padding($padding-10 $padding-15);
      @include border-radius(3px 3px 0 0);
      line-height: 18px;
      strong{
        @include font-size($text-md-size);
      }
    }
    //widget filter head clearfix
    @include clear-fix();
  }
  .filter-head{
    @include set-border(1px solid $divider);
    color: $secondry-color;
    @include font-weight($font-bold);
    @include font-size($text-size+1);
    @include set-padding($padding-10 $padding-10+2);
    @extend .no-margin;
    background-color: $white;
    >a{
      color: $secondry-color;
      @extend .pulls-#{$textDirectionOpposite};
      @include font-weight(400);
      @include font-size($text-size - 1);
      cursor: pointer;
    }
  }
  .filter-toggle-btn{
    &.hidden{
      @extend .hidden;
    }
  }
  .seached_tag{
    &.hidden{
      @extend .hidden;
    }
  }
  .hotel-search-input{
    @include set-padding(10px);
    background-color: $white;
    .filter-title{
      color: $secondry-color;
      @include font-weight($font-bold);
      @include set-padding($padding-10,bottom);
      @include font-size($text-size);
    }
    .filter-box{
      @include set-padding(0,bottom);
      @include set-margin($margin-10,bottom);
      @include set-border(1px solid $divider,bottom);
      .search-htl{
        &.ui-autocomplete-input{
          @include set-padding($padding-10);
          @extend .full-width;
          @include border-radius(2px);
          @include set-border(0px solid $divider);
        }
      }
    }
    .hotel-name{
      @extend .relative;
      & > a > i{
        @extend .absolute;
        @include set-position(5%,#{$textDirectionOpposite});
        @include set-position(30%,top);
        @include font-size($text-md-size);
        cursor: pointer;
      }
    }
  }
  .widget-content{
    @extend .relative;
    .panel{
      @extend .no-border;
      @extend .no-boxshadow;
      @include set-margin(0,bottom);
      text-decoration: $unset;
      .panel-heading{
        @extend .no-border-rad;
        background: $white;
        @extend .no-border;
        color: $secondry-color;
        font-weight: $font-bold;
        .fa{
          @extend .no-margin;
          @include set-margin($margin-5,$textDirectionOpposite);
        }
      }
      .panel-body{
        @extend .no-padding;
        .checks-list{
          @extend .list-none;
          @extend .child-transition;
          li{
            @extend .list-none;
            @extend .relative;
            .cross-checkbox {
              @extend %controller;
              input + span {
                float: #{$textDirectionOpposite};
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
                &::after {
                  /*background: url(images/tick.svg) no-repeat center;
                  background-size: contain;*/
                  width: 1.5em;
                  height: 1.5em;
                }
              }
              .name{
                word-break: break-word;
              }
            }
            a{
              @media only screen and (min-width: 1024px){
                &:hover{
                  background: $primary-color;
                  text-decoration: none;
                  color: $white;
                  @include box-shadow(1px 2px 20px rgba(0, 0, 0, 0.48));
                  @include transition-with-condition-in-mediaquery(false,child-transition);
                }
              }
              /*&:hover .checkbox label .cr{
                @include set-border(1px solid $white);
                color: $white;
              }
              &:hover .checkbox label .fake-label{
                color: $white;
              }*/
              @media only screen and (min-width: 1024px){
                @include transition-with-condition-in-mediaquery(false,parent-transition);
              }
              @extend .shows;
              @include set-padding($padding-5 $padding-10);
              .filter{
                @extend .no-margin;
                @include set-margin($margin-5,bottom);
              }
              .checkbox{
                label{
                  @include set-padding(0,$textDirection);
                  color: $primary-color;
                  .cr {
                    @extend .relative;
                    @extend .inline-b;
                    @include set-border(1px solid $secondry-color);
                    @include border-radius(.25em);
                    @include width(16px);
                    @include height(16px);
                    @extend .pulls-#{$textDirectionOpposite};
                    @include set-margin(.5em,#{$textDirectionOpposite});
                    cursor: pointer;
                    .cr-icon{
                      @extend .absolute;
                      @include font-size(.8em);
                      line-height: 0;
                      @include set-position(50%,top);
                      @include set-position(20%,#{$textDirectionOpposite});
                    }
                  }
                  input[type='checkbox']{
                    position: fixed;
                    @include z-index(-111);
                    @extend .no-opacity;
                    @include set-position(0,top);
                    @include set-position(0,$textDirection);
                    &+.cr{
                      >.cr-icon{
                        -webkit-transform: scale(3) rotateZ(-20deg);
                        -moz-transform:    scale(3) rotateZ(-20deg);
                        -ms-transform:     scale(3) rotateZ(-20deg);
                        -o-transform:      scale(3) rotateZ(-20deg);
                        transform:         scale(3) rotateZ(-20deg);
                        @extend .no-opacity;
                        transition: all .3s ease-in;
                      }
                    }
                  }
                  input[type="checkbox"]:checked{
                    &+.cr{
                      >.cr-icon{
                        -moz-transform: scale(1) rotateZ(0deg);
                        -o-transform: scale(1) rotateZ(0deg);
                        -ms-transform: scale(1) rotateZ(0deg);
                        -webkit-transform: scale(1) rotateZ(0deg);
                        transform: scale(1) rotateZ(0deg);
                        @extend .opacity-1;
                      }
                    }
                  }
                  .fake-label{
                    color: $primary-color;
                    @include font-weight($font-bold);
                    @extend .inline-b;
                    @extend .pulls-#{$textDirection};
                    @include set-margin(-2px 0 0);
                    @include font-size($text-size - 1);
                    width: 85%;
                    vertical-align: $vert-align;
                    .stars{
                      @extend .stars;
                      vertical-align: text-top;
                    }
                  }
                  //label clearfix
                  @include clear-fix();
                }
              }
              &:hover .cross-checkbox{
                color: #fff;
              }
            }
          }
          label{
            @extend .shows;
            @extend .full-width;
          }
          .points{
            @extend .pulls-#{$textDirectionOpposite};
          }
        }
        //panel body clearfix
        @include clearfix();
      }
    }
  }
  .listing-body{
    @extend .relative;
    .content-holder{
      @extend .relative;
      @include width(75%);
      @extend .pulls-#{$textDirectionOpposite};
      .portlet{
        @extend .portlet-parrent;
        .visiting-reasons{
          @extend .relative;
          color: $primary-text;
          @include font-size($text-size - 1);
          line-height: 16px;
          h3{
            color: $primary-color;
            @include set-margin(0 0 $margin-5);
            @include font-size($text-lg-size);
            line-height: 22px
          }
        }
      }
      .listing-holder{
        @extend .relative;
        .sort-bar{
          &.container-floating-parrent > div:first-child{@extend .pulls-#{$textDirection};}
          @media only screen and (max-width: 800px){
            &.sticky-filter-now{
              .container-floating-parrent{
                #{$textDirection}:0!important;
              }
            }
          }
          @include set-border(1px solid $divider,bottom);
          @extend .shows;
          @include height(60px);
          @include set-padding($padding-10+1 0);
          @include set-margin($margin-15,bottom);
          .sorting{
            span.title{
              color: $black;
              @include font-weight($font-bold);
              @include font-size($text-size);
              @include set-margin($margin-10,#{$textDirectionOpposite});
            }
            .sort-btn{
              &.active{
                > a{
                  color: $secondry-color;
                }
                @include set-border(2px solid $secondry-color);
              }
              @include set-border(2px solid $secondry-color);
              border: 2px solid $bg-of-white;
              @include border-radius(3px);
              @extend .no-margin;
              @include set-margin($margin-5,#{$textDirection});
              background-color: $white;
              @extend .inline-b;
              @extend .relative;
              > a{
                color: $primary-text;
                @include font-size($text-size);
                @include set-padding($padding-5+2 $padding-15);
                @extend .shows;
                @extend .no-outline;
              }
            }
          }
          .checkbox{
            &.checkbox-slider-lg{
              @include set-margin(0,bottom);
              @include set-margin(0,top);
              line-height: 35px;

            }
            & label{
              @extend .shows;
              @include set-padding(0,#{$textDirectionOpposite});
              color: $primary-color;
              >i.lbl-toggle{
                background-image: url(/web/images/map-pin.png);
                @if $isRtl == true{
                  background-position: 3px;
                }@else{
                  background-position: 90px;
                }
                background-color: $primary-color;
                background-repeat: no-repeat;
                @include height(auto);
                @include font-weight($font-bolder);
                @include font-size($text-size);
                font-style: normal;
                @include set-position(1px,#{$textDirectionOpposite});
                @include set-padding(0 ($padding-10));
                @extend .absolute;
                @extend .text-#{$textDirection};
                @if $isRtl == true{
                  @include width(145px);
                }@else{
                  @include width(127px);
                }
                @include z-index(0);
                @include border-radius(20px);
                color: $white;
                cursor: pointer;
              }
            }
            //label after clearfix
            @include clearfix();
          }
        }
        .applywrapper{
          .apply-filter{
            @extend .pulls-#{$textDirection};
            @include font-size($text-size - 1);
          }
          .seached_tag{
            @include set-margin(0,#{$textDirectionOpposite});
            >span{
              @extend .primary-color-bg;
              border-radius: 25px;
              @extend .white;
              @extend .inline-b;
              @include set-padding(3px,top);
              @include set-padding(15px,#{$textDirection});
              @include set-padding(3px,bottom);
              @include set-padding(15px,#{$textDirectionOpposite});
              @extend .relative;
              @extend .text-elipse;
              @extend .pulls-#{$textDirection};
              @include set-margin(0,top);
              @include set-margin(0,#{$textDirectionOpposite});
              @include set-margin(0,bottom);
              @include set-margin(10px,#{$textDirection});
              max-width: 125px;
              @extend .full-width;
              >.close{
                @extend .white;
                font-weight: normal;
                @include set-margin(-1px,top);
                @include set-margin(-8px,#{$textDirectionOpposite});
                @include set-margin(0,bottom);
                @include set-margin(0,#{$textDirection});
                opacity: 1;
                @extend .relative;
                text-shadow: none;
                z-index: 2;
              }
            }
          }
        }
        .clearall{
          color: $fb-bg;
          @extend .inline-b;
          @include font-size($text-size - 1);
          @extend .no-margin;
          @include set-margin(($margin-5 - 3) ,top);
          @include set-margin(($margin-5+3) ,#{$textDirection});
          vertical-align: top;
          white-space: nowrap;
          cursor: pointer;
        }
        .listing-warper{
          @extend .shows;
          @include set-padding($padding-10);
          @extend .full-width;
          .hotel-listing-item{
            background: $white none repeat scroll 0 0;
            @include set-border(1px solid $divider);
            /* margin: 0 0 20px 20px; */
            @extend .no-padding;
            @include set-padding($padding-10,$textDirection);
            @include set-padding($padding-10+2,top);
            @include set-padding($padding-10+2,bottom);
            @extend .relative;
            @include border-radius(3px);
            //hotel listing item clear
            @include clearfix();
            .hotel-figure{
              @extend .inline-b;
              @extend .pulls-#{$textDirection};
              @include height(210px);
              @extend .relative;
              @include width(26%);
              @include border-radius(2px);
              @include font-weight($font-bolder);
              //listing favorite heart style
              a{
                @extend .absolute;
                #{$textDirection}: 0;
                @include width(200px);
                cursor: pointer;
              }
              span{
                color: $primary-color;
                @extend .inline-b;
                font-family: FontAwesome;
                @include font-size($text-size-24 + 6);
                @extend .absolute;
                top: 10px;
                #{$textDirection}: 5px;
                &.fav-check:before{
                  content: "\f08a"
                }
                &.fav-checked:before{
                  content: "\f004"
                }
              }
            }
            .hotel-item-detail{
              &.item-detail{
                @extend .pulls-#{$textDirection};
                @include width(45%);
              }
            }
            .item-detail{
              @extend .no-padding;
              @include set-padding(($padding-10 - 2),$textDirection);
              @include set-padding($padding-15,$textDirectionOpposite);
            }
            h2{
              @extend .full-width;
              color: $adham-primary;
              @include font-size($text-md-size);
              @include font-weight($font-bold);
              letter-spacing: 0.5px;
              @extend .no-margin;
              line-height: 28px;
              a{
                color: $adham-primary;
                @include font-size($text-md-size);
                @include font-weight($font-bold);
                letter-spacing: 0.5px;
                @include set-margin(5px,bottom);
              }
            }
            address{
              @extend .list-none;
              color: $primary-text;
              @include font-size($text-size - 2);
              letter-spacing: 0.5px;
              margin: 5px 0;
              >b{
                @include font-size($text-size - 2);
                @include font-weight($font-normal);
              }
            }
            ul,li{
              @extend .list-none;
              li,.aminety-wrap{
                @extend .inline-b;
                @include set-margin(($margin-5 - 2),top);
                @include set-margin(0,$textDirection);
                @include set-margin($margin-5 - 2,bottom);
                @include set-margin($margin-5,$textDirectionOpposite);
                vertical-align: top;
              }
            }
            .aminety-wrap{
              @extend .shows;
              @extend .list-none;
              @include set-border(1px solid $divider,top);
              @include set-border(1px solid $divider,bottom);
              @include set-padding($padding-5 0);
              @include set-margin($margin-10 0);
              @include font-size($text-xlg-size);
            }
            .rooms-nly{
              color: $semi-black;
              @include font-size($text-size - 2);
              @include set-margin($margin-5 -6px);
              @extend .text-#{$textDirection};
              .room-icon{
                @extend .shows;
                @extend .no-padding;
                @include set-padding($padding-30+5,$textDirection);
                &:before{
                  @include _spreadsheet(-398px -1036px);
                  content: "";
                  @include height(14px);
                  @include width(25px);
                  #{$textDirection}: 8px;
                  @extend .absolute;
                  @extend .flip-horizental;
                }
              }
              & span:last-child{
                @include set-padding($padding-5 $padding-5+2 0);
              }
            }
            .hotel-price-wrap{
              &.hotel-item-price{
                @extend .pulls-#{$textDirection};
                @include set-border(solid 1px $divider,$textDirection);
                @include width(26%);
              }
              .price-wrap_{
                @extend .relative;
                bottom: 50px;
                @include set-margin($margin-5,bottom);
              }
              @extend .text-center;
              @extend .no-padding;
              @include set-padding(($padding-30+55),top);
              @include set-padding($padding-15,$textDirection);
              @include set-padding($padding-10,$textDirectionOpposite);
              label{
                text-transform: inherit;
                @include font-weight($font-normal);
                @include font-size($text-size - 2);
                @include set-margin($margin-5,bottom);
              }
              .taxes-include{
                @include font-size($text-size - 2);
                color: $secondry-text;
                @extend .no-decoration;
                @extend .relative;
                bottom: 35px;
              }
              .unq-btn{
                .fa-angle-right{
                  @include set-margin(5px,#{$textDirection});
                }
              }
            }
            .price-strong{
              color: $black;
              @include font-size($text-lg-size);
              @extend .shows;
              @include set-padding($padding-5 0);
            }
            .btn{
              &.unq-btn{
                @include set-border(1px solid $secondry-color);
                .fa-angle-right{
                  @if $isRtl == true{
                    @extend .flip-horizental;
                  }
                }
                @extend .relative;
                bottom: 50px;
              }
            }
            // hotel listing item clearfix
            @include clearfix();
          }
        }
      }
    }
  }
  /*==map view css==*/
  &.map_view{
    .visiting-reasons{
      @extend .hides;
    }
    @extend .relative;
    & aside.filter-warper{
      @extend .child-transition;
      //with filter map css
      & .filter-toggle-btn{
        @extend .absolute;
        @extend .shows;
        background-color: $divider;
        @include box-shadow(-5px 0 5px rgba(0, 0, 0, .1));
        @include set-border(1px solid $divider);
        @include set-padding($padding-10 $padding-10 $padding-10);
        @include set-padding($padding-5,$textDirectionOpposite);
        @include width(92px);
        #{$textDirectionOpposite}: 0;
        top: 0;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        @extend .text-#{$textDirectionOpposite};
        cursor: pointer;
      }
      //without filter map css
      &.hidden-filter{
        @include box-shadow(1px 7px 7px 3px rgba(0, 0, 0, 0.4));
        #{$textDirection}: -24%;
        @extend .absolute;
        top: 0;
        .filter-toggle-btn{
          background-color: $primary-color;
          @include set-border(1px solid $primary-color-mid);
          color: $white;
          @include font-size($text-size - 3);
          @include set-padding($padding-10);
          @extend .absolute;
          #{$textDirectionOpposite}: -92px;
          top: 8px;
          @include width(92px);
          @extend .shows;
          @include z-index(1);
          cursor: pointer;
          @extend .text-#{$textDirection};
          &:after{
            #{$textDirection}: 90%;
            top: 50%;
            @include set-border(5px solid transparent);
            content: " ";
            @include height(0);
            @include width(0);
            @extend .absolute;
            pointer-events: none;
            border-#{$textDirectionOpposite}-color: $black;
            @include set-margin(-5px);
            @extend .flip-horizental;
          }
        }
      }

    }
    & .listing-body{
      //without filter map css
      &.withoutfilter{
        @extend .full-width;
        @extend .child-transition;
        & .sort-bar{
          span.title{
            color: $black;
            @include font-weight($font-bold);
            @include font-size($text-size - 1);
            @extend .no-padding;
            @include set-padding(120px,#{$textDirection});
          }
        }
        .map-view-wrap{
          @include width(63%);

          .map_info_window {
            .hotel-title{
              @extend .hotel-title-temp;
            }
            .hotel_price{
              @extend .primary-color;
              font-size: 16px;
              font-weight: 600;
            }
            .hotel-star{
              background-image: url(/web/images/map-sprite.png);
              display: block;
              height: 17px;
              width: 115px;
            }
            .hotel_rating {
              background-position: -69px -300px;
            }
            .hotel_rating_5 {
              background-position: -69px -280px;
            }
            .hotel_rating_4 {
              background-position: -69px -260px;
            }
            .hotel_rating_3 {
              background-position: -69px -241px;
            }
            .hotel_rating_2 {
              background-position: -69px -220px;
            }
            .hotel_rating_1 {
              background-position: -69px -200px;
            }
          }
        }
        & .listing-warper{
          @include set-margin(1%,#{$textDirection});
          width: 35%;
          @extend .shows;
          @include set-padding($padding-10);
          @extend .full-width;
          @extend .pulls-#{$textDirection};
        }
      }

      //with filter map css
      & .listing-warper{
        @include width(40%);
        @extend .pulls-#{$textDirection};
      }

      /*********************************************************/
      /*------ both with and without map fiter css start ------*/
      .map-view-wrap{
        .map_info_window {
          .hotel-title{
            @extend .hotel-title-temp;
          }
          .hotel_price{
            @extend .primary-color;
            font-size: 16px;
            font-weight: 600;
          }
          .hotel-star{
            background-image: url(/web/images/map-sprite.png);
            display: block;
            height: 17px;
            width: 115px;
          }
          .hotel_rating {
            background-position: -69px -300px;
          }
          .hotel_rating_5 {
            background-position: -69px -280px;
          }
          .hotel_rating_4 {
            background-position: -69px -260px;
          }
          .hotel_rating_3 {
            background-position: -69px -241px;
          }
          .hotel_rating_2 {
            background-position: -69px -220px;
          }
          .hotel_rating_1 {
            background-position: -69px -200px;
          }
        }
      }
      /*------ both with and without map fiter css end ------*/
      /*********************************************************/

      background-color: $white;
      @include set-border(1px solid $divider);
      border-right: none;
      @extend .pulls-#{$textDirection};
      @extend .no-margin;
    }
    & .sort-bar{
      & .sorting{
        @extend .text-#{$textDirection};
      }
      @extend .shows;
      @include height(60px);
      @include set-padding($padding-10+1 0);
      @include set-margin($margin-15, bottom);
      &.container-floating-parrent > div:first-child{@extend .pulls-#{$textDirection}}
    }
    & .filter-warper{
      .filter-toggle-btn{
        &:after{
          #{$textDirectionOpposite}: 90%;
          top: 50%;
          @include set-border(5px solid transparent);
          content: " ";
          @include height(0);
          @include width(0);
          @extend .absolute;
          pointer-events: none;
          border-#{$textDirectionOpposite}-color: $black;
          @include set-margin(-5px);
        }
        @include box-shadow(-5px 0 5px rgba(0, 0, 0, .1));
      }
    }
    & .applywrapper{
      .apply-filter{
        @extend .pulls-#{$textDirection};
        @include font-size($text-size - 1);
        @include set-margin(1%,#{$textDirection});
        @include set-padding(10px,#{$textDirection});
      }
      .seached_tag{
        @include set-margin(0,#{$textDirectionOpposite});
        >span{
          @extend .primary-color-bg;
          border-radius: 25px;
          @extend .white;
          @extend .inline-b;
          @include set-padding(3px,top);
          @include set-padding(15px,#{$textDirection});
          @include set-padding(3px,bottom);
          @include set-padding(15px,#{$textDirectionOpposite});
          @extend .relative;
          @extend .text-elipse;
          @extend .pulls-#{$textDirection};
          @include set-margin(0,top);
          @include set-margin(0,#{$textDirectionOpposite});
          @include set-margin(0,bottom);
          @include set-margin(10px,#{$textDirection});
          max-width: 125px;
          @extend .full-width;
          >.close{
            @extend .white;
            font-weight: normal;
            @include set-margin(-1px,top);
            @include set-margin(-8px,#{$textDirectionOpposite});
            @include set-margin(0,bottom);
            @include set-margin(0,#{$textDirection});
            opacity: 1;
            @extend .relative;
            text-shadow: none;
            z-index: 2;
          }
        }
      }
      .clearall{
        color: $fb-bg;
        @extend .inline-b;
        @include font-size($text-size - 1);
        @extend .no-margin;
        @include set-margin(($margin-5 - 3) ,top);
        @include set-margin(($margin-5+3) ,#{$textDirection});
        vertical-align: top;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    & .sort-btn{
      &.active{
        @include set-border(2px solid $primary-color);
        > a{
          color: $primary-text;
        }
      }
      @extend .relative;
      @include set-border(2px solid $bg-of-white);
      @include border-radius(3px);
      @extend .no-margin;
      @include set-margin($margin-20,$textDirection);
      background-color: $white;
      @extend .inline-b;
      > a{
        color: $primary-text;
        @include font-size($text-size - 1);
        @include set-padding($padding-5+2 $padding-15);
        @extend .shows;
        @extend .no-outline;
      }
    }
    .checkbox{
      &.checkbox-slider-lg{
        @include set-margin(0,bottom);
        @include set-margin(0,top);
        line-height: 35px;
        @extend .pulls-#{$textDirectionOpposite};
      }
      & label{
        @extend .shows;
        @include set-padding(0,#{$textDirectionOpposite});
        color: $primary-color;
        >i.lbl-toggle{
          background-image: url(/web/images/map-pin.png);
          @if $isRtl == true{
            background-position: 3px;
          }@else{
            background-position: 90px;
          }
          background-color: $primary-color;
          background-repeat: no-repeat;
          @include height(auto);
          @include font-weight($font-bolder);
          @include font-size($text-size);
          font-style: normal;
          #{$textDirectionOpposite}: 8px;
          @include set-padding(0 ($padding-15 - 1));
          @extend .absolute;
          @extend .text-#{$textDirection};
          @if $isRtl == true{
            @include width(145px);
          }@else{
            @include width(127px);
          }
          @include z-index(0);
          @include border-radius(20px);
          color: $white;
          cursor: pointer;
        }
      }
      //label after clearfix
      @include clearfix();
    }
    & .listing-warper{
      @extend .shows;
      @include set-padding($padding-10);
      @extend .full-width;
      .hotel-listing-item{
        background: $white none repeat scroll 0 0;

        @include set-border(1px solid $divider);
        @extend .no-padding;
        @include set-padding($padding-10+2,top);
        @include set-padding($padding-10,$textDirection);
        @include set-margin(0 0 $margin-20);
        @extend .relative;
        @include border-radius(3px);

        //hotel listing item clear
        @include clearfix();
        .hotel-figure{
          @include set-margin(0 0 $margin-10);
          @extend .inline-b;
          @extend .pulls-#{$textDirection};
          @include height(210px);
          @extend .relative;
          @include width(26%);
          @include border-radius(2px);
          @include font-weight($font-bolder);
        }
        .hotel-item-detail{
          &.item-detail{
            @extend .pulls-#{$textDirection};
            @include width(70%);
            @include set-border(medium none);
          }
        }
        .item-detail{
          @include set-padding(0);
          @include set-padding(($padding-10 - 2),$textDirection);
          @include set-padding($padding-15,$textDirectionOpposite);
        }
        h2{
          @extend .full-width;
          color: $secondry-color;
          @include font-size($text-md-size);
          @include font-weight($font-bold);
          letter-spacing: 0.5px;
          @include set-margin(0);
          @extend .no-scroll;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 28px;
          a{
            color: $secondry-color;
            @include font-size($text-md-size);
            @include font-weight($font-bold);
            letter-spacing: 0.5px;
            @include set-margin(5px,bottom);
          }
        }
        address{
          @extend .list-none;
          color: $primary-text;
          @include font-size($text-size - 3);
          letter-spacing: 0.5px;
          margin: 5px 0;
          >b{
            @include font-size($text-size - 3);
            @include font-weight($font-normal);
          }
        }
        ul,li{
          @extend .list-none;
          li,.aminety-wrap{
            @extend .inline-b;
            @include set-margin(($margin-5 - 2) 0 $margin-5 - 2 $margin-5);
            vertical-align: top;
          }
        }
        .aminety-wrap{
          @extend .hides;
        }
        .rooms-nly{
          color: $semi-black;
          @include font-size($text-size - 2);
          @include set-margin($margin-5 -6px);
          @extend .text-#{$textDirection};
          .room-icon{
            @extend .shows;
            @include set-padding(0);
            @include set-padding($padding-30+5,$textDirection);
            &:before{
              @include _spreadsheet(-398px -1036px);
              content: "";
              @include height(14px);
              @include width(25px);
              #{$textDirection}: 8px;
              @extend .absolute;
              @if($isRtl){
                @extend .flip-horizental;
              }
            }
          }
          & span:last-child{
            @include set-padding($padding-5 $padding-5+2 0);
          }
        }
        .hotel-item-price{
          &.hotel-price-wrap{
            @extend .pulls-#{$textDirectionOpposite};
            @include set-border(medium none);
            @include set-margin(0);
            @include set-padding(0);
            @include width(102%);
          }
          .price-wrap_{
            background-color: $semi-black;
            color: $white;
            @extend .pulls-#{$textDirection};
            @include height(52px);
            @include set-margin(0);
            @include set-padding(($padding-5 - 2));
            @include set-padding($padding-15,$textDirection);
            @include set-padding(0,$textDirectionOpposite);
            @extend .text-#{$textDirection};
            @include width(70%);
          }
          @extend .text-center;
          @include set-padding(($padding-30+55),top);
          @include set-padding(0,$textDirectionOpposite);
          @include set-padding($padding-25+1,$textDirection);
          label{
            text-transform: inherit;
            @include font-weight($font-normal);
            @include font-size($text-size - 2);
            @include set-margin($margin-5,bottom);
          }
          .taxes-include{
            @include font-size($text-size - 2);
            color: $secondry-text;
            @extend .no-decoration;
            @extend .relative;
            bottom: 35px;
          }
        }
        .price-strong{
          color: $white;
          @include font-size($text-lg-size);
          @extend .shows;
          @include set-padding(0);
        }
        .btn{
          &.unq-btn{
            @include border-radius(0);
            @extend .pulls-#{$textDirectionOpposite};
            @include height(52px);
            @include set-margin(0);
            @include set-padding($padding-15 0);
            @include width(30%);
            .fa-angle-right{
              @if $isRtl == true{
                @extend .flip-horizental;
              }
            }
          }
        }
        // hotel listing item clearfix
        @include clearfix();
      }
      .active-hotel{
        & .hotel-listing-item{
          @extend .active-hotel-temp;
        }
      }
    }
    & .listing-body{
      @extend .pulls-#{$textDirection};
      @include set-margin(0);
      @include width(75%);
    }
    & .map-view-wrap{
      @extend .no-scroll;
      @include width(60%);
      @extend .pulls-#{$textDirection};
      @extend .relative;
    }
    & .filter-list,.listing-warper{
      @extend .scroll;
    }
  }
  //listing page clearfix
  @include clearfix();
}
@media only screen and (max-width: 800px) {
  #listing-page .listing-body .content-holder .listing-holder .applywrapper .seached_tag > span{
    width: 90px;
  }
}

.post-card {
  @extend .full-width;
  margin: 1px auto 0;
  border-radius: 2px;
  padding: 16px;
  @extend .white-bg;
  box-shadow: 0 0 3px #ccc;
  @extend .flip-horizental;
  .loading-box {
    height: 200px;
    @extend .full-width;
    @extend .relative;
    background-image: linear-gradient(to right, #EEE 0%, #FAFAFA 20%, #EEE 100%);
    background-color: #EEE;
    animation: 1s shimmer infinite linear;
    .image{
      @include width(1%);
      @extend .white-bg;
      @extend .absolute;
      @include height(200px);
      @include set-position(210px,$textDirectionOpposite);
    }
    .content{
      @extend .no-padding;
      .demo{
        &-divider{
          @extend .white-bg;
          @include height(10px);
          @extend .full-width;
        }
        &-title{
          height: 25px;
          background: #Fff;
          @extend .full-width;
        }
        &-address{
          height: 25px;
          @extend .white-bg;
          width: calc(100% - 50%);
        }
        &-star{
          height: 15px;
          @extend .white-bg;
          @extend .full-width;
        }
        &-facility{
          @include height(35px);
          @extend .relative;
          $class-slug:demo-facility;
          $specifier:0%;
          @for $i from 1 through 10{
            .#{$class-slug}-#{$i}{
              @include width(1%);
              @include height(35px);
              @extend .absolute;
              @include set-position(calc(100% - #{$i}#{$specifier}),$textDirectionOpposite);
              @extend .white-bg;
            }
          }
        }
        &-room{
          height: 15px;
          @extend .white-bg;
          @extend .full-width;
        }
        &-type{
          height: 15px;
          @extend .white-bg;
          @extend .full-width;
        }
      }
    }
    .price{
      .right-divider{
        @extend .white-bg;
        @include width(1%);
        @include height(200px);
        @extend .absolute;
        #{$textDirectionOpposite}: 0px;
      }
      .demo{
        &-divider{
          @extend .white-bg;
          @include height(35px);
          @extend .full-width;
        }
        &-divider-light{
          @extend .white-bg;
          @include height(10px);
          @extend .full-width;
        }
        &-divider-last{
          @include height(55px);
          @extend .white-bg;
        }
        &-price{
          @include height(25px);
          @extend .white-bg;
          &-1{
            @include height(25px);
          }
        }
        &-stay{
          @include height(15px);
        }
        &-button{
          @include height(25px);
          @extend .white-bg;
          &-1{
            @include height(25px);
          }
        }
        &-tax{
          @include height(15px);
          @extend .white-bg;
          &-1{
            @include height(15px);
          }
        }
      }
    }
    .white-space {
      div {
        @extend .white-bg;
        @extend .absolute;
      }
    }
    .head {
      &_1 {
        #{$textDirectionOpposite}:  40px;
        width: 8px;
        height: 40px;
      }
      &_2 {
        width: 60%;
        height: 16px;
        #{$textDirectionOpposite}: 40%;
        top: 0px;
      }
      &_3 {
        width: calc(100% - 48px);
        height: 8px;
        top: 16px;
        #{$textDirectionOpposite}: 48px;
      }
      &_4 {
        width: 50%;
        height: 16px;
        #{$textDirectionOpposite}: 50%;
        top: 24px;
      }
    }
    .body {
      &_1 {
        width: 100%;
        height: 24px;
        top: 40px;
      }
      &_2 {
        width: 30%;
        height: 20px;
        top: 88px;
        #{$textDirection}: 0;
      }
      &_3 {
        @extend .full-width;
        height: 8px;
        top: 108px;
      }
      &_4 {
        @extend .full-width;
        height: 24px;
        top: 150px;
      }
      &_5 {
        width: 8px;
        height: 26px;
        top: 174px;
        #{$textDirectionOpposite}: 40px;
      }
      &_6 {
        width: calc(100% - 180px);
        height: 26px;
        top: 174px;
        #{$textDirectionOpposite}: 90px;
      }
      &_7 {
        width: 8px;
        height: 26px;
        top: 174px;
        #{$textDirectionOpposite}: 40px;
      }
    }
  }
}
/*#Neighborhoods .fake-label{
  word-break: break-word;
}*/

/*============================= search index style end =============================*/
/*============================= hotel index style start =============================*/

.hotel-page{
  .hotel-title{
    @extend .hotel-title-temp;
    &>.stars{
      @extend .stars;
    }
  }
  .address-loc{
    @extend .primary-color;
    font-size: unset;
    i{
      @extend .second-color;
      font-size: 18px;
    }
  }
  .hotel-action {
    @include set-padding(10px);
    padding-#{$textDirection}: 0;
    .arab-book-btn {
      background: $secondry-color none repeat scroll 0 0;
      border-radius: 5px;
      @extend .white;
      cursor: pointer;
      height: 34px;
      line-height: 1.4;
      min-width: 120px;
      @extend .text-center;
      @extend .parent-transition;
      white-space: nowrap;
      @extend .full-width;
      .arab-check {
        border-radius: 50%;
        color: #fff;
        @extend .pulls-#{$textDirection};
        font-size: 24px !important;
        line-height: 1;
        margin-top: -5px;
        padding: 3px;
      }
      &:hover {
        background: $primary-color none repeat scroll 0 0;
        color: #fff;
      }
      &:focus{
        @extend .white;
      }
    }
    .book-or-call {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border-color: #999 #999 #999 #000;
      border-image: none;
      border-style: solid;
      @include set-border-width(1px,top);
      @include set-border-width(1px,#{$textDirectionOpposite});
      @include set-border-width(1px,bottom);
      @include set-border-width(0px,#{$textDirection});
      height: 42px;
      @extend .absolute;
      @if $isRtl == true{
        #{$textDirectionOpposite}: 0px;
      }@else{
        #{$textDirectionOpposite}: 10px;
      }
      top: 26px;
      width: 10px;
      span {
        @extend .white-bg;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
        padding: 0 0 4px;
        @extend .absolute;
        #{$textDirectionOpposite}: -5px;
        top: 7px;
      }
    }
    .arab-call-btn {
      @extend .white-bg;
      border: 1px solid $primary-color;
      border-radius: 5px;
      color: $primary-color;
      cursor: pointer;
      height: 34px;
      line-height: 1.4;
      margin-top: 5px;
      min-width: 120px;
      @extend .text-center;
      transition: all 0.15s ease-in 0s;
      white-space: nowrap;
      @extend .full-width;
      a{
        @extend .primary-color;
      }
      .call-btn {
        border-radius: 5px;
        @extend .primary-color;
        @extend .pulls-#{$textDirection};
        font-size: 24px ;
        line-height: 1.2;
        margin-#{$textDirectionOpposite}: -2px;
        margin-top: -7px;
        padding: 3px;
        width: 33px;
      }
      &:hover{
        @extend .primary-color-bg;
        .call-btn{
          @extend .white;
          filter: hue-rotate(-50deg);
          transform: scaleX(-1);
        }
        a{
          @extend .white;
        }
      }
    }
  }
  .hotel-bar{
    @extend .pulls-#{$textDirection};
  }
  @if $isRtl == true{
    .support-btn{
      @extend .no-padding-right;
      button{
        @extend .no-padding-right;
      }
    }
  }
  .property-app {
    .hotel-mosaic{
      @extend .full-width;
      @extend .relative;
      @include set-margin(20px,bottom);
      /*============================ first image holder =====================================*/
      .first-image-sixtile{
        @extend .hotel-detail-masonary;
        @extend .pulls-#{$textDirection};
        @include width(60%);
        @include height(335px);
        img{
          @extend .masonary-img;
        }
        &:hover{
          .dark-overlay{
            @extend .dark-overlay-temp;
          }
          .dark-overlay-information{
            @extend .dark-overlay-information-temp;
          }
        }
      }
      /*============================ second image holder =====================================*/
      .second-image-sixtile{
        @extend .hotel-detail-masonary;
        @include height(250px);
        @include width(39.5%);
        @include set-margin(.5%,#{$textDirection});
        @extend .pulls-#{$textDirectionOpposite};
        img{
          @extend .masonary-img;
        }
        &:hover{
          .dark-overlay{
            @extend .dark-overlay-temp;
          }
          .dark-overlay-information{
            @extend .dark-overlay-information-temp
          }
        }
      }
      /*============================ third image holder =====================================*/
      .third-image-sixtile{
        @extend .hotel-detail-masonary;
        @extend .pulls-#{$textDirection};
        @include width(19%);
        @include set-margin(.5% 0 0);
        @include height(165px);
        clear: both;
        img{
          @extend .masonary-img;
        }
        &:hover{
          .dark-overlay{
            @extend .dark-overlay-temp;
          }
          .dark-overlay-information{
            @extend .dark-overlay-information-temp
          }
        }
      }
      /*============================ fourth image holder =====================================*/
      .fourth-image-sixtile{
        @extend .hotel-detail-masonary;
        @extend .pulls-#{$textDirection};
        @include width(40.5%);
        @extend .no-margin;
        @include set-margin(.5%,top);
        @include set-margin(.5%,#{$textDirection});
        @include height(165px);
        img{
          @extend .masonary-img;
        }
        &:hover{
          .dark-overlay{
            @extend .dark-overlay-temp;
          }
          .dark-overlay-information{
            @extend .dark-overlay-information-temp
          }
        }
      }
      /*============================ fifth image holder =====================================*/
      .fifth-image-sixtile{
        @extend .hotel-detail-masonary;
        @extend .pulls-#{$textDirection};
        @include width(19.5%);
        @include height(251px);
        @include set-margin(-80px,top);
        @include set-margin(.5%,#{$textDirection});
        img{
          @extend .masonary-img;
        }
        &:hover{
          .dark-overlay{
            @extend .dark-overlay-temp;
          }
          .dark-overlay-information{
            @extend .dark-overlay-information-temp
          }
        }
      }
      /*============================ map image holder =====================================*/
      .map-image-sixtile{
        &.generic-map{
          background: 0 0;
          @include set-border(1px solid $divider);
        }
        @extend .hotel-detail-masonary;
        @extend .pulls-#{$textDirection};
        @include width(19.5%);
        @include height(165px);
        @include set-margin(-80px,top);
        @include set-margin(.5%,#{$textDirection});
        img{
          @extend .masonary-img;
        }
        &:hover{
          .dark-overlay{
            @extend .dark-overlay-temp;
          }
          .dark-overlay-information{
            @extend .dark-overlay-information-temp
          }
        }
      }
      /*============================ sixth image holder =====================================*/
      .sixth-image-sixtile{
        @extend .hotel-detail-masonary;
        @extend .pulls-#{$textDirection};
        @include width(19.5%);
        @include height(80px);
        @extend .no-margin;
        @include set-margin(.5% ,top);
        @include set-margin(.5%,#{$textDirection});
        img{
          @extend .masonary-img;
        }
      }
      .dark-overlay{
        @extend .absolute;
        @extend .full-width;
        @include height(100%);
        @include set-position(0,top);
        @include set-position(0,#{$textDirectionOpposite});
        background-color: transparent;
        @include transition(background-color 250ms ease-in-out);
        .dark-overlay-information{
          @extend .arab-call-btn;
          @include set-position(5px,bottom);
          @include set-position(0,#{$textDirectionOpposite});
          @extend .full-width;
          @extend .no-opacity;
          @include transition(all 250ms ease-in-out);
          .dark-overlay-information-title{
            @extend .absolute;
            @include set-position(50px,bottom);
            @include set-position(20px,#{$textDirectionOpposite});
            color: #fff;
            font-size: 24px;
            line-height: 28px;
            width: calc(100% - 40px);
            @extend .text-#{$textDirection};
          }
        }
      }
      .see-all-overlay{
        @include set-padding(2px);
        @extend .absolute;
        background-color: $see-all-overlay;
        color: $white;
        @extend .full-width;
        @include height(100%);
        @extend .text-center;
        display: table;
        @include transition(all 250ms ease-in-out);
        text-decoration: none;
        @extend .text-uppercase;
        .see-all-overlay-text{
          @include font-size($text-size);
          display: table-cell;
          vertical-align: $vert-align;
          letter-spacing: normal;
          @include font-weight($font-bolder);
          a{
            &:hover{
              color: $white;
            }
          }
        }
      }
      .badge-price{
        &.MIN-9824{
          cursor: pointer;
        }
        @extend .absolute;
        @include set-position(20px,top);
        @include set-position(-5px,#{$textDirection});
        @include border-radius(2px);
        .price-ribbon{
          &.bg-red{
            background: $secondry-color;
          }
          &-from{
            @extend .show;
            @include font-size($text-size);
            line-height: 10px;
            @extend .text-#{$textDirection};
          }
          &-currency{
            @include font-size($text-size - 3);
            @include set-padding($padding-5);
          }
          &-price{
            @include font-weight($font-bold);
            @include font-size($text-lg-size);
            line-height: 20px;
          }
          color: $white;
          @extend .relative;
          @extend .pulls-#{$textDirection};
          @include height(40px);
          @include set-padding($padding-5 $padding-15);
          @extend .text-center;
          @include z-index(auto);
        }
      }
      .badge-rating{
        @extend .absolute;
        @include set-position(-5px,#{$textDirectionOpposite});
        @include border-radius(2px);
        background-color: #468cff;
        color: $white;
        @extend .text-center;
        @include width(84px);
        @include height(39px);
        @include set-position(200px,bottom);
        &-score{
          @extend .shows;
          @include font-size($text-lg-size);
          @include font-weight($font-normal);
          @include height(22px);
        }
        &-text{
          @extend .shows;
          font-size: 10px;
          font-weight: 300;
        }
      }
      .FavoriteHeart{
        width: 42px;
        height: 42px;
        @extend .absolute;
        top: 15px;
        left: 54%;
        left: calc(60% - 58px);
        font-size: 40px;
        cursor: pointer;
        .fa {
          @extend .inline-b;
          line-height: 1;
          font-weight: 400;
          font-style: normal;
          speak: none;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-bottom: 3px;
        }
        .ficon-favorite-filled::before {
          content: "\f004";
        }
        .ficon-favorite::before {
          content: "\f08a";
        }
        &-layout{
          @extend .absolute;
          color: rgba(0,0,0,.4);
        }
        &-icon {
          &.is-disabled{
            @extend .white;
          }
          @extend .absolute;
        }
        .is-checked{
          color: $primary-color;
        }
      }
    }
  }
  .pagecontainer2{
    background: $white;
    @include set-border(1px solid $divider);
    @include box-shadow(0 0 5px rgba(0, 0, 0, 0.13));
    @extend .relative;
    .cstyle10{
      @extend .second-color-bg;
      @include height(50px);
      @extend .absolute;
      @include set-position(0px,top);
      @extend .full-width;
    }
    .nav-tabs{
      >li{
        @extend .pulls-#{$textDirection};
        &.active{
          a{
            @extend .second-color;
            &:hover{
              @extend .white;
            }
          }
          @extend .black;
          cursor: default;
          @extend .primary-color-bg;
          /*@include set-border(0px solid $divider);*/
          border-bottom-color: $transparent;
          /*& span:nth-of-type(2){
            color: $black;
          }*/
        }
        @include set-border(1px solid $white,#{$textDirectionOpposite});
        >a{
          @include set-border(0px solid $transparent);
          border-radius: 0px 0px 0 0;
          color: #ffffff;
          margin: 0;
          padding: 15px 15px;
          @include transition(.3s);
          span.summary{
            @extend .rooms-container-icon;
            @extend .pulls-#{$textDirection};
            @if $isRtl == true{
              background-position: -160px 0px;
            }@else{
              background-position: -163px 0px;
            }
          }
          span.rates{
            @extend .rooms-container-icon;
            @extend .pulls-#{$textDirection};
            @if $isRtl == true{
              background-position: -178px 0px;
            }@else{
              background-position: -180px 0px;
            }
          }
          span.preferences{
            @extend .rooms-container-icon;
            @extend .pulls-#{$textDirection};
            @if $isRtl == true{
              background-position: -194px 0px;
            }@else{
              background-position: -199px 0px;
            }
          }
          span.maps{
            @extend .rooms-container-icon;
            @extend .pulls-#{$textDirection};
            @if $isRtl == true{
              background-position: -194px 0px;
            }@else{
              background-position: -217px 0px;
            }
          }
          span.reviews{
            @extend .rooms-container-icon;
            @extend .pulls-#{$textDirection};
            background-position: -236px 0px;
          }
          &:hover{
            @include set-border(0px solid $transparent);
            background: $primary-color;
            @include box-shadow(inset 0px 0px 5px 0px rgba(0,0,0,0.2));
            color: $white;
            span.summary{
              background-position: -160px -18px;
            }
            span.rates{
              @extend .rooms-container-icon;
              @extend .pulls-#{$textDirection};
              background-position: -180px -18px;
            }
            span.preferences{
              @extend .rooms-container-icon;
              @extend .pulls-#{$textDirection};
              background-position: -196px -18px;
            }
            span.maps{
              @extend .rooms-container-icon;
              @extend .pulls-#{$textDirection};
              background-position: -214px -18px;
            }
            span.reviews{
              @extend .rooms-container-icon;
              @extend .pulls-#{$textDirection};
              background-position: -233px -18px;
            }
          }
        }
      }
      @extend .pulls-#{$textDirection};
    }
    .text-show,.text-show ~ span{
      @extend .white;
      font-weight: bold;
    }
    .sticky-book-now{
      &.has-value{
        box-shadow: 1px 2px 20px rgba(0, 52, 154, 0.78);
        .booknow-new{
          a{
            >span.text-show:not(.counter){
              color: red;
            }
          }
        }
      }
      box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.38);
      @extend .fixed;
      @include z-index(15);
      @include set-position(0,top);
      @include font-size($text-size - 1);
      @extend .white-bg;
      cursor: pointer;
      @extend .parent-transition;
      >li{
        @include set-border(1px solid #000,#{$textDirectionOpposite});
        &.active{
          >a{
            color: $white;
            @extend .second-color-bg;
          }
        }
        >a{
          color: $primary-color;
          &:hover{
            @extend .white;
            @extend .second-color;
          }
        }
      }
      .booknow-new{
        a{
          @extend .white-bg;
          >span.text-show:not(.counter){
            @extend .second-color;
          }
          span.booked-value:nth-of-type(2){
            @extend .second-color;
          }
        }
      }
    }
    .has-value{
      .booknow-new{
        &.progress-striped-brown{
          background-color: $primary-color;
        }
        &.progress-striped{
          height: 50px;
        }
        width: 21%;
        @extend .text-center;
        a{
          background: transparent;
          >span:not(.counter){
            color: red;
          }
          span.booked-value:nth-of-type(2){
            @extend .second-color;
          }
          .badge-container{
            background-color: $white;
            @include set-position(10px,#{$textDirection});
            .counter{
              @include set-margin(-1px,#{$textDirection});
              color: $secondry-color;
            }
          }
        }
      }
      .total-value{
        @include set-margin(0px,#{$textDirectionOpposite});
      }
    }
    .tab-content{
      @include clearfix();
      .collapsebtn2{
        &.collapsed{
          span.collapsearrow{
            @include width(9px);
            @include height(8px);
            @extend .pulls-#{$textDirectionOpposite};
            @include set-margin($margin-10,top);
            @extend .collapsearrow-bg;
            background-position: 0 0px;
            background-repeat: no-repeat;
            @extend .child-transition;
          }
        }
        @extend .full-width;
        @include height(32px);
        @include set-border(0px solid $black);
        @extend .text-#{$textDirection};
        @include set-padding($padding-20,#{$textDirection});
        @include set-padding($padding-20,#{$textDirectionOpposite});
        color: $semi-black;
        @include font-size($text-size);
        @include font-weight($font-bold);
        background: $white;
        @include set-margin($margin-10,bottom);
        @include set-margin($margin-25,top);
        span.collapsearrow{
          @include width(9px);
          @include height(8px);
          @extend .pulls-#{$textDirectionOpposite};
          @include set-margin($margin-10,top);
          @extend .collapsearrow-bg;
          background-position: 0 -8px;
          background-repeat: no-repeat;
          @extend .parent-transition;
        }
      }
      .hotel-title{
        @extend .hotel-title-temp;
        font-family: "open sans";
      }
      .checklist{
        @include set-padding(1px);
        li{
          @extend .list-image;
        }
      }
      .rating-margin{
        @include set-margin($margin-20 auto);
        .h-ratings{
          @extend .relative;
          @include z-index(10);
          .hotel-ratings-bars{
            @extend .pulls-#{$textDirection};
            @include font-size($text-size - 4);
            @include set-padding($padding-25,#{$textDirectionOpposite});
            .rating-category{
              @extend .pulls-#{$textDirection};
              color: $fb-bg;
              @include font-size($text-size);
              @include set-padding($padding-10,#{$textDirection});
            }
          }
          .progress{
            background-color: $bg-of-white;
            @include border-radius(0);
            @include box-shadow(none);
            @include height(5px);
            @include set-margin(0,bottom);
            @include set-margin($margin-15,#{$textDirectionOpposite});
            @include set-margin(($margin-10 - 2),top);
            @extend .pulls-#{$textDirection};
            .progress-bar{
              @include box-shadow(none);
              @extend .pulls-#{$textDirection};
              @extend .parent-transition;
              &.high{
                background-color: $secondry-color;
              }
            }
          }
          .h-rating-info{
            @extend .text-center;
            .rating-score{
              &:before{
                color: $primary-text;
                content: "10/";
                @include font-size($text-size-24+5);
                @extend .relative;
                @include set-position(18px,top);
              }
              @extend .text-left;
              color: $primary-color;
              @include font-size($text-size-24 + 27);
              @include font-weight($font-bold);
              line-height: 0.8;
              @include set-margin($margin-30 - 135,#{$textDirectionOpposite});
              @extend .flex;
            }
            .rating-score-word{
              @extend .text-#{$textDirectionOpposite};
              color: $secondry-color;
              @include font-size($text-size-24 + 3);
              @include font-weight($font-bold);
            }
            .rating-based-on{
              @extend .text-#{$textDirectionOpposite};
              color: $primary-color;
              @include font-size($text-size);
              @include set-margin($margin-5,top);
            }
          }
        }
      }
      .sr-only{
        @include set-border(0 none);
        clip: rect(0px, 0px, 0px, 0px);
        @include height(1px);
        @include set-margin(-1px);
        @extend .no-scroll;
        @include set-padding(0);
        @extend .absolute;
        @include width(1px);
      }
      .scircle{
        @include width(62px);
        @include height(65px);
        @extend .review-circle;
        @extend .font-#{$textDirectionCode};
        @include font-size($text-size - 1);
        @include font-weight($font-bold);
        color: $primary-text;
        @extend .no-padding;
        @include set-padding($padding-15,top);
        @include set-padding($padding-30,#{$textDirection});
      }
      .sctext{
        @extend .font-#{$textDirectionCode};
        @include font-size($text-size - 1);
        @include font-weight($font-bold);
        color: $primary-text;
      }
      ul.circle-list{
        @extend .list-inline;
        &>li{
          @extend .hotel-listing-item;
          background: $white;
          @include width(27px);
          @include height(27px);
          color: $secondry-text;
          @include font-size($text-size - 3);
          @include set-border(2px solid $bg-of-white);;
          @include border-radius(23px);
          @include set-padding(($padding-5 - 2),top);
          @include set-padding(($padding-5 - 2),#{$textDirection});
        }
      }
      .circlewrap{
        @include width(52px);
        @include height(52px);
        @include set-margin(0 auto);
        @extend .relative;
        span{
          @include width(25px);
          @include height(25px);
          @include border-radius(23px);
          @extend .absolute;
          @include set-position(0,bottom);
          @include set-position(0,#{$textDirectionOpposite});
          background: $home-icon-1;
          color: $white;
          @include font-size($text-size - 3);
          @include set-padding($padding-5 - 1, top);
        }
      }
      .new-room-table{
        background-color: $bg-body-color;
        @include set-border(1px solid $divider,#{$textDirectionOpposite});
        @include set-border(1px solid $divider,#{$textDirection});
        @include set-border(1px solid $divider,bottom);
        @include transform(translateY(-1.5px));
        .room-title{
          @include set-padding($padding-15 $padding-15 ($padding-10 - 2));
          @include font-size($text-lg-size);
          background-color: $white;
          @include box-shadow(0 0 6px #eee);
          h3{
            @include font-size($text-lg-size);
            @include set-margin(0,top);
            @include set-margin(0,bottom);
            @extend .second-color;
          }
        }
        .rooms-table-header{
          @include box-shadow(0 0 6px #ddd);
          @include set-margin($margin-10,bottom);
          &>div{
            @extend .no-padding;
            @include set-padding($padding-15,top);
            @include set-padding($padding-15,#{$textDirection});
            @include set-padding($padding-10,bottom);
            @include set-border(1px solid $light-divider,#{$textDirectionOpposite});
            &:nth-of-type(1){
              @extend .pulls-#{$textDirection};
            }
            &:nth-of-type(2){
              @extend .pulls-#{$textDirection};
            }
            &:nth-of-type(3){
              @extend .pulls-#{$textDirection};
            }
            &:nth-of-type(4){
              @extend .pulls-#{$textDirection};
            }
          }
        }
        .arabic-row{
          &>div{

            &:nth-of-type(1){
              @extend .pulls-#{$textDirection};
              .room-image-holder{
                @extend .relative;
                @extend .no-scroll;
                cursor: pointer;
                img{
                  max-width: 100%;
                }
              }
            }
            &:nth-of-type(2){
              .room{
                &:hover{
                  @include set-margin($margin-15,bottom);
                  @extend .child-transition;
                }
                min-height: 150px;

                @extend .parent-transition;
                @include box-shadow(0 0 6px $divider);
                @include set-margin(0px -8px);
                background-color: $white;
                &.row{
                  .room-grouping-whats-included{
                    min-height: 120px;
                    @include set-padding($padding-15 0);
                    ul{
                      @extend .green;
                      @extend .list-none;
                      margin-#{$textDirection}: 2px;
                      width: 75%;
                      li{
                        margin-bottom: 10px;
                      }
                    }
                  }
                  .capacity-holder{
                    @extend .no-padding;
                    @include set-padding($padding-5,top);
                    @include set-padding($padding-15,#{$textDirectionOpposite});
                    .capacity{
                      color: $primary-text;
                      &>span:nth-of-type(3){
                        @include font-size($text-size-24);
                      }
                    }
                  }
                  .booking-types{
                    @extend .no-padding;
                    @include set-padding($padding-5,top);
                    @include set-padding($padding-15,#{$textDirection});
                    ul{
                      @extend .list-none;
                      &>li{
                        @include set-border(1px solid);
                        @include set-padding($padding-5 + 2);
                        @include border-radius(20px);
                        @include font-size($text-size - 5);
                        vertical-align: bottom;
                        @include set-margin($margin-10,bottom);
                        @include width(75%);
                        &>span{
                          @include font-size($text-size);
                          vertical-align: text-bottom;
                        }
                        i{
                          @include set-margin(10px,#{$textDirectionOpposite});
                          @include set-margin(10px,#{$textDirection});
                        }
                      }
                    }
                  }
                  .hotel-item-price{
                    .price-strong{
                      color: $black;
                      @include font-size($text-lg-size);
                      @extend .shows;
                      @include set-padding($padding-5 0);
                    }
                    label{
                      text-transform: inherit;
                      @include font-weight($font-normal);
                      @include font-size($text-size - 2);
                      @include set-margin($margin-5,bottom);
                    }
                    .btn-acent{
                      background-color: $secondry-color;
                      color: $white;
                      @include set-padding($padding-5);
                      @extend .parent-transition;
                      .fa-angle-right{
                        @if $isRtl == true{
                          @extend .flip-horizental;
                        }
                      }
                      &:hover{
                        @extend .primary-color-mid-bg;
                        color: $white;
                      }
                    }
                    .taxes-include{
                      @include font-size($text-size - 2);
                      color: $dark-divider;
                      text-decoration: none;
                    }
                  }
                  &>div{
                    &:nth-of-type(1){
                      @extend .pulls-#{$textDirection};
                      line-height: 50px;
                    }
                    &:nth-of-type(2){
                      @extend .pulls-#{$textDirection};
                      @include set-margin($margin-20,top);
                    }
                    &:nth-of-type(3){
                      @extend .pulls-#{$textDirection};
                      @include set-margin($margin-25 - 1,top);
                    }
                    &:nth-of-type(4){
                      @include set-margin($margin-15,top);
                    }
                  }
                }
              }
              .more-desc-toggle{
                @extend .parent-transition;
                &:hover{
                  @include box-shadow(1px 2px 20px rgba(0, 0, 0, 0.48));
                  @include set-margin($margin-25,bottom);
                  @extend .child-transition;
                }
                cursor: pointer;
                @include set-padding($padding-10);
                @extend .text-center;
                @include set-border(1px solid $light-divider);
                @include set-margin(0,#{$textDirectionOpposite});
                @include set-margin(0,top);
                max-width: 100%;
                @include set-margin($margin-20,bottom);
                @extend .relative;
                @include set-position(20px,top);
                &[aria-expanded="true"]{
                  .title{
                    &:before{
                      content: "Less ?";
                    }
                  }
                }
                .title{
                  &:before{
                    @extend .primary-color-mid;
                    content: "More ?";
                    @include font-size($text-size - 2);
                    @include font-weight($font-bold);
                  }
                  color: #0096a5;
                  cursor: pointer;
                  @extend .relative;
                  @extend .child-transition;
                }
              }
            }
          }
        }
      }
    }
  }
  .rooms-container{
    @extend .margin-btm;
  }
  .b-lazy{
    @extend .b-lazy;
  }
  .b-loaded{
    @extend .b-loaded;
  }

  .booknow-new{
    @extend .inline-b;
    @include set-padding(($padding-15 - 1) $padding-15);
    @extend .relative;
    @extend .pulls-#{$textDirectionOpposite};
    cursor: pointer;
    a{
      @include set-padding($padding-5+3 $padding-5);
      .badge-container{
        @extend .absolute;
        @include set-position(-25px,#{$textDirection});
        @include width(20px);
        @extend .second-color-bg;
        @include border-radius(50%);
        @extend .parent-transition;
        .counter{
          @include set-margin($margin-5+2,#{$textDirection});
          @extend .white;
          @include font-size($text-size - 2);
          @include font-weight($font-bold);
          @extend .parent-transition;
        }
      }
    }
  }
  .total-value{
    @extend .inline-b;
    @include set-padding($padding-15);
    @extend .relative;
    @extend .pulls-#{$textDirectionOpposite};
    cursor: pointer;
    background: #fff;
    @extend .second-color;
    @include set-margin(3%,#{$textDirectionOpposite});
  }
  //check box style start
  .checkbox {
    label{
      @extend .no-padding-#{$textDirection};
      @extend .primary-color;
      input[type="checkbox"]{
        /*@extend .hides;*/
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        &:checked{
          &+.cr{
            &>.cr-icon{
              -moz-transform: scale(1) rotateZ(0deg);
              -o-transform: scale(1) rotateZ(0deg);
              -ms-transform: scale(1) rotateZ(0deg);
              -webkit-transform: scale(1) rotateZ(0deg);
              transform: scale(1) rotateZ(0deg);
              @extend .opacity-1;
            }
          }
        }
        &+.cr{
          @include set-border(1px solid $secondry-color);
          &>.cr-icon{
            -webkit-transform: scale(3) rotateZ(-20deg);
            -moz-transform:    scale(3) rotateZ(-20deg);
            -ms-transform:     scale(3) rotateZ(-20deg);
            -o-transform:      scale(3) rotateZ(-20deg);
            transform:         scale(3) rotateZ(-20deg);
            @extend .no-opacity;
            @extend .child-transition;
          }
        }
      }
    }
    .cr{
      @extend .relative;
      @extend .inline-b;
      @include set-border(1px solid $primary-color);
      @include border-radius(.25em);
      @include width(16px);
      @include height(16px);
      @extend .pulls-#{$textDirection};
      @extend .no-margin;
      @include set-margin(.5em,#{$textDirectionOpposite});
      .cr-icon{
        @extend .absolute;
        @include font-size(.8em);
        @include line-height(0);
        @include set-position(50%,top);
        @if $isRtl == true{
          @include set-position(6%,#{$textDirection});
        }@else{
          @include set-position(20%,#{$textDirection});
        }
      }
    }
    .fake-label{
      color: $secondry-color;
      line-height: 1;
      @extend .pulls-#{$textDirection};
    }
  }
  .cross-checkbox {
    @extend %controller;
    input + span {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
      &::after {
        /*background: url(images/tick.svg) no-repeat center;
        background-size: contain;*/
        width: 1.5em;
        height: 1.5em;
      }
    }
    .name{

      word-break: break-word;
    }
  }
  //check box style end
}
.is-#{$textDirectionCode}.owl-carousel{
  @extend .text-#{$textDirection};
  .area-name{
    direction: #{$textDirectionCode};
  }
}
/*============================= hotel index style end =============================*/
/*========================steps in booking css start =============================*/


.tabs-steps {
  /*tabs style*/
  @extend .relative;
  @extend .no-scroll;
  @extend .margin-0-auto;
  @extend .full-width;
  @include font-weight($font-normal);
  @include font-size(1.25em);
  &.tabs-style-iconbox{
    .bk{
      &::before{

        @include z-index(10);
        @extend .v-align-middle;
        text-transform: none;
        @include font-weight($font-normal);
        font-variant: normal;
        font-size: 1.3em;
        @include line-height(1px);
        speak: none;
        -webkit-backface-visibility: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @extend .shows;
        @extend .no-margin;
        @include set-margin(0.65em,bottom);
        @include set-margin(0.1em,#{$textDirectionOpposite});
      }
      @include font-size(150%);
    }
  }
  /* Nav */
  nav{
    @extend .text-center;
    background: rgba(255,255,255,0.4);
    @extend .shows;
    ul{
      @extend .relative;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: flex;
      @extend .list-none;
      @extend .no-padding;
      @extend .margin-0-auto;
      max-width: 1200px;
      -ms-box-orient: horizontal;
      -ms-box-pack: center;
      -webkit-flex-flow: row wrap;
      -moz-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      li{
        &:first-child{
          &::before{
            @extend .absolute;
            @include set-position(20%,top);
            @include set-position(0,#{$textDirection});
            @include z-index(-1);
            @include width(1px);
            @include height(60%);
            background: rgba(0,0,0,0.07);
            content: '';
          }
          &::before{
            @include set-position(auto,#{$textDirectionOpposite});
            @include set-position(0,#{$textDirection});
          }
        }
        &.tab-current{
          z-index: 1;
          a{
            background: $white;
            @include box-shadow(-1px 0 0 $white);
            color: $secondry-color;
            &::after{
              @extend .absolute;
              @include set-position(100%,top);
              @include set-position(50%,#{$textDirection});
              @include set-margin(-10px,#{$textDirection});
              width: 0;
              height: 0;
              @include set-border(solid transparent);
              border-width: 10px;
              border-top-color: $secondry-color;
              content: '';
              pointer-events: none;
            }
            span{
              @include font-weight($font-bold);
              @include font-size(0.7em);
            }
          }
        }

        @extend .relative;
        @include z-index(1);
        @extend .shows;
        @extend .no-margin;
        @extend .text-center;
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        flex: 1;
        a{
          overflow: visible;
          @include set-padding(2em 0);
          line-height: 1;
          color: $secondry-text;
          @extend .child-transition;
        }
        &::after{
          @extend .absolute;
          @include set-position(20%,top);
          @include set-position(0,#{$textDirectionOpposite});
          @include z-index(-1);
          @include width(1px);
          @include height(60%);
          background: rgba(0,0,0,0.07);
          content: '';
        }
      }
    }
    a{
      &:focus{
        @extend .no-outline;
        text-decoration: none;
      }
      @extend .relative;
      @extend .shows;
      @extend .no-scroll;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 2.5;
      span{
        vertical-align: $vert-align;
        @include font-size(0.75em);
        @include font-weight($font-bold);
      }
    }
  }
  form{
    @extend .no-padding;
    @extend .no-border-style;
    @extend .no-margin;
    .content-wrap{
      @extend .relative;
      section{
        display: none;
        &.content-current{
          @extend .shows;
        }
        @extend .margin-0-auto;
        @include set-padding(1em);
        max-width: 1200px;
        @extend .text-center;
        .booking-wraper{
          font: 75%/150% "Droid Arabic Kufi", Tahoma, Arial;
          @include font-weight($font-normal);
          @include font-size(75%);
          color: $secondry-color;
          @extend .text-#{$textDirection};
          .booking-section{
            h2{
              @include font-size(1.6667em);
              @include line-height(1.25em);
              @include set-margin(0 0 $margin-15);
              @include font-weight($font-normal);
              color: $secondry-color;
            }
            .other-user{
              @extend .text-center;
              .filter{
                @include set-margin(0);
                @include set-margin($margin-5,bottom);
              }
              .checkbox{
                &.filter{
                  label{
                    display: table;
                  }
                }
                label{
                  color: $primary-color;
                  input[type="checkbox"]{
                    @extend .hidden;
                    &+.cr{
                      .cr-icon{
                        -webkit-transform: scale(3) rotateZ(-20deg);
                        -moz-transform:    scale(3) rotateZ(-20deg);
                        -ms-transform:     scale(3) rotateZ(-20deg);
                        -o-transform:      scale(3) rotateZ(-20deg);
                        transform:         scale(3) rotateZ(-20deg);
                        @extend .no-opacity;
                        @extend .child-transition;
                      }
                    }
                    &:checked{
                      &+.cr{
                        .cr-icon{

                          @extend .opacity-1;
                          @include transform(scale(1) rotateZ(0deg));
                        }
                      }
                    }
                  }
                  @include clearfix();
                }
                .cr{
                  @extend .relative;
                  @extend .inline-b;
                  @include set-border(1px solid $primary-color);
                  @include border-radius(.25em);
                  @include width(16px);
                  @include height(16px);
                  @extend .pull-#{$textDirection};
                  @include set-margin(.5em,#{$textDirectionOpposite});
                  @include set-margin(1px,top);
                  .cr-icon{
                    @extend .absolute;
                    @include font-size(.8em);
                    @include line-height(0);
                    @include set-position(50%,top);
                    @include set-position(10%,#{$textDirection});
                  }
                }
                .fake-label{
                  @extend .inline-b;
                  @extend .no-margin;
                  color: $secondry-color;
                  vertical-align: unset;
                }
              }
            }
            label{
              &.cross-checkbox{
                margin: 0 auto 5px;
                width: 250px;
                color: $primary-color;
                input + span{
                  float: #{$textDirection};
                }
              }
              @extend .text-uppercase;
              @extend .not-imp-show;
              @include set-margin($margin-5,bottom);
              @include font-weight($font-normal);
              @include font-size(0.9167em);
            }
            .form-group{
              &.row{
                @extend .parent-transition;
                &:hover{
                  @extend .unq-hover;
                  @include set-margin($margin-30,bottom);
                  .discount-ribben{
                    @include set-padding($padding-5+1 $padding-30 $padding-5 +2);
                    @extend .parent-transition;
                  }
                  .booking-table-data{
                    @include set-position(20px,top);
                    @extend .child-transition;
                  }
                }
              }
              &>*{@include set-margin($margin-20,bottom);}
              .booking-table-header{
                @extend .primary-color-bg;
                color: $white;
              }
              .booking-table-data{
                @include set-position(20px,top);
              }
              .c_field{
                @include font-size($text-size - 3);
                &>div:not(.selector),&>span input{
                  @include set-border(1px solid $secondry-color);
                  @extend .full-width;
                }
                &>span input{

                }
                .cs-select > span{
                  @include set-padding($padding-5);
                }
                .input{
                  @extend .relative;
                  @include z-index(1);
                  @extend .inline-b;
                  max-width: 100%;
                  @extend .full-width;
                  vertical-align: top;
                }
                .select_bootstrap{
                  .btn-default{
                    @include box-shadow(0 0 0 2px transparent);
                    color: $secondry-color;
                    transition: box-shadow 0.3s ease 0s;
                    @include line-height(18px);
                    &:hover{
                      background: $white;
                      @include set-border(1px solid transparent);
                    }
                  }
                }
              }
            }
            .own_notice{
              background: $primary-color;
              color: $white;
            }
            .selector{
              @extend .gcc-selector;
              select{
                @extend .gcc-select;
                @include set-padding($padding-5 + 3 ,top);
                @include set-padding($padding-5 + 3 ,#{$textDirection});
                @include set-padding($padding-5 + 3 ,bottom);
                @include set-padding(0,#{$textDirectionOpposite});
                option{
                  @extend .gcc-option;
                }
              }
              .custom-select{
                @extend .gcc-basic-select;
                @include set-padding(10px,#{$textDirection});
                &::before{
                  @include set-position(0,#{$textDirectionOpposite});
                }
                &::after{
                  @include set-position(9px,#{$textDirectionOpposite});
                  @include set-border(3px solid transparent,#{$textDirectionOpposite});
                  @include set-border(3px solid transparent,#{$textDirection});
                }
              }
            }
            textarea{
              background: #f5f5f5;
              @extend .no-border;
              line-height: normal;
              overflow-y: auto;
              resize: vertical;
              vertical-align: top;
              &.s_request{
                height: 40px;
              }
            }
            .checks-list{
              @extend .list-none;
              transition: all 0.55s ease 0s;
              @extend .child-transition;
              li{
                @extend .list-none;
                a{
                  &:focus{outline: none;text-decoration: none;}
                  &:hover{
                    background: $primary-color;
                    text-decoration: $none;
                    color: $white;
                    @include box-shadow(1px 2px 20px rgba(0, 0, 0, 0.48));
                    @extend .child-transition;
                  }
                  &:hover .checkbox label .cr{
                    @include set-border(1px solid $white);
                    color: $white;
                  }
                  &:hover .checkbox label .fake-label{
                    color: $white;
                  }
                  @extend .parent-transition;
                  @extend .shows;
                  @include set-padding($padding-5 $padding-15);
                  .filter{
                    @include set-margin(0);
                    @include set-margin($margin-5,bottom);
                  }
                  .checkbox{
                    label{
                      @include set-padding(0,#{$textDirectionOpposite});
                      color: $primary-color;
                      .cr{
                        @extend .relative;
                        @extend .inline-b;
                        @include set-border(1px solid $secondry-color);
                        @include border-radius(.25em);
                        @include width(16px);
                        @include height(16px);
                        @extend .pulls-#{$textDirection};
                        @include set-margin(.5em,#{$textDirectionOpposite});
                        cursor: pointer;
                        .cr-icon{
                          @extend .absolute;
                          @include font-size(.8em);
                          line-height: 0;
                          @include set-position(50%,top);
                          @include set-position(20%,#{$textDirection});
                        }
                      }
                      input[type='checkbox']{
                        @extend .fixed;
                        @include z-index(-111);
                        @extend .no-opacity;
                        @include set-position(0,top);
                        @include set-position(0,#{$textDirectionOpposite});
                        &+.cr{
                          >.cr-icon{
                            -moz-transform: scale(3) rotateZ(-20deg);
                            -o-transform: scale(3) rotateZ(-20deg);
                            -ms-transform: scale(3) rotateZ(-20deg);
                            -webkit-transform: scale(3) rotateZ(-20deg);
                            transform: scale(3) rotateZ(-20deg);
                            @extend .no-opacity;
                            transition: all .3s ease-in;
                          }
                        }
                      }
                      input[type="checkbox"]:checked{
                        &+.cr{
                          >.cr-icon{
                            transform: scale(1) rotateZ(0deg);
                            @extend .opacity-1;
                          }
                        }
                      }
                      .fake-label{
                        color: $primary-color;
                        @include font-weight($font-normal);
                        @extend .show;
                        @include width(90%);
                        @extend .pulls-#{$textDirectionOpposite};
                        @include set-margin(-2px 0 0);
                        @include font-size($text-size - 1);
                        vertical-align: $vert-align;
                        .stars{
                          @extend .stars;
                          vertical-align: text-top;
                        }
                      }
                      //label clearfix
                      @include clearfix();
                    }
                  }

                }
              }
              input[type='checkbox']{
                @extend .fixed;
                @extend .no-opacity;
                @include z-index(-111);
                @include set-position(0,top);
                @include set-position(0,#{$textDirection});
              }
              label{
                >.fake-label{
                  @extend .primary-color;
                  @extend .shows;
                  max-width: 88%;
                  @extend .pulls-#{$textDirection};
                  @extend .text-#{$textDirection};
                  @include set-margin(-2px,top);
                }
              }
            }
          }
          .s_request{
            .input--minoru{
              width: 350px;
            }
          }

          input.input-text{
            @include set-padding($padding-15,#{$textDirectionOpposite});
            @include set-padding($padding-15,#{$textDirection});
            @include height(34px);
          }
          .discount-ribben-wrap{
            @extend .absolute;
            @include set-position(-10px,#{$textDirection});
            @include set-position(-12px,top);
            @include z-index(10);
            .discount-ribben{
              background-color: $secondry-color;
              color: $white;
              padding: 6px 10px 7px;
              @extend .relative;
              @include box-shadow(0 1px 6px rgba(225, 225, 225, 0.5));
              letter-spacing: 0.5px;
              @extend .child-transition;
              .discount-text{
                @include font-size($text-size - 4);
                white-space: nowrap;
                &:before{
                  @include set-border(14px solid transparent,bottom);
                  @include set-border(9px solid $secondry-color,#{$textDirection});
                  @include set-border(11px solid transparent,top);
                  content: "";
                  height: 0;
                  @extend .absolute;
                  @include set-position(-8px,#{$textDirectionOpposite});
                  @include set-position(0,top);
                  width: 0;
                }
              }
              &:after{
                content: ' ';
                @extend .absolute;
                width: 0;
                height: 0;
                @include set-position(0,#{$textDirection});
                @include set-position(100%,top);
                @include set-border-width(5px 5px);
                border-style: solid;
                @include set-border-color($text-color,top);
                @include set-border-color($secondry-color,#{$textDirectionOpposite});
                @include set-border-color(transparent,bottom);
                @include set-border-color(transparent,#{$textDirection});
              }
            }
          }
          .travelo-box{
            background: $white;
            @include set-padding($padding-20);
            @include set-margin($margin-30,bottom);
          }
          .sidebar{
            .booking-details{
              @extend .white-bg;
              @include set-padding($padding-20);
              @include set-margin($margin-30,bottom);
              h4{
                @include font-size(1.3333em);
                @include line-height(1.25em);
                @extend .second-color;
              }
              article{
                @include set-margin($margin-20,bottom);
              }
              figure{
                &>a{
                  &:first-child{
                    @include width(75px);
                    @include height(75px);
                    @extend .pull-#{$textDirection};
                    @extend .shows;
                    @extend .relative;
                    @extend .no-scroll;
                    background: none;
                  }
                  &:hover{
                    color: #0ab596;
                    text-decoration: none;
                    img{
                      @include transform(scale(1.2));
                    }
                    &:after{
                      visibility: visible;
                      @include transform(rotateY(0deg) scale(1, 1));
                      filter: alpha(opacity=100);
                      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                      -moz-opacity: 1;
                      -khtml-opacity: 1;
                      opacity: 1;
                    }
                  }
                  &:after{
                    content: '';
                    @extend .absolute;
                    #{$textDirectionOpposite}: 0;
                    top: 0;
                    @extend .full-width;
                    height: 100%;
                    visibility: hidden;
                    @extend .parent-transition;
                    @include transform(rotateY(180deg) scale(0.5, 0.5));
                    background: url('/web/images/hover-effect.png') no-repeat center;
                    background-color: rgba(0, 0, 0, 0);
                    filter: alpha(opacity=0);
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                    -moz-opacity: 0;
                    -khtml-opacity: 0;
                    opacity: 0;
                    background-color: rgba(10,181,150,0.6);
                  }
                }
                .travel-title{
                  @include set-margin($margin-30+60,#{$textDirection});
                  h5{
                    @include set-margin(0 0 $margin-15);
                    small{
                      @include font-size(12px);
                      @extend .text-uppercase;
                      @extend .shows;
                      @include set-margin($margin-5 - 1,top);
                    }
                  }
                }
                img{
                  @extend .img;
                  @extend .shows;
                  @extend .parent-transition;
                }
              }
              .details{
                @include set-padding(0,#{$textDirectionOpposite});
                @include set-padding(0,#{$textDirection});
                @include set-padding($padding-5 +2,top);
                .feedback{
                  @include set-margin($margin-5 0);
                  @include set-border(1px solid #f5f5f5,top);
                  @include set-padding($padding-5,top);
                  @include set-border(1px solid #f5f5f5,bottom);
                  .five-stars-container{
                    &:before{
                      @extend .shows;
                      @extend .absolute;
                      @include set-position(0,top);
                      @include set-position(1px,right);
                      content: "\e006\e006\e006\e006\e006";
                      @include z-index(0);
                    }
                    @extend .inline-b;
                    @extend .relative;
                    font-family: 'Glyphicons Halflings';
                    @include font-size($text-size - 1);
                    @extend .text-#{$textDirection};
                    cursor: default;
                    white-space: nowrap;
                    @include line-height(1.2em);
                    color: $light-border;
                    .five-stars{
                      @extend .shows;
                      @extend .no-scroll;
                      @extend .relative;
                      @extend .white-bg;
                      @include set-padding(1px,left);
                      &:before{
                        content: "\e006\e006\e006\e006\e006";
                        color: #fdb714;
                      }
                    }
                  }
                  .review{
                    @extend .shows;
                    @extend .pulls-#{$textDirectionOpposite};
                    @extend .text-uppercase;
                    @include font-size(0.8333em);
                    color: $dark-divider;
                  }
                }
              }
              .timing{
                @include font-size(0.8333em);
                @extend .text-uppercase;
                @extend .text-#{$textDirectionOpposite};
                @include line-height(1.3333em);
                @include set-border(1px solid #f5f5f5,bottom);
                @extend .no-margin;
                @include set-padding($padding-10 0 $padding-5);
                label{
                  @extend .primary-color-mid;
                  @include font-size($text-size - 3);
                  @include set-margin(0,bottom);
                  @include font-weight($font-bold);
                  @extend .shows;
                  @include line-height(1.42);
                  letter-spacing: 0.04em;
                  &~span{
                    @extend .shows;
                    @include line-height(1.42);
                    letter-spacing: 0.04em;
                    @include font-size($text-size - 5);
                    color: $primary-text-dark;
                    @include font-weight(600);
                  }
                }
                .duration{
                  @include font-weight($font-bold);
                  color: #fdb714;
                  i{
                    @include font-size(2em);
                    &~span{
                      @extend .shows;
                      @include line-height(1.42);
                      letter-spacing: 0.04em;
                      @include font-size($text-size - 4);
                    }
                  }

                }
              }
              .constant-column-3{
                &>:nth-child(3n+1){
                  clear: both;
                }
                &>:nth-child(3n){
                  @include set-margin(0,#{$textDirectionOpposite});
                }
                &>*{
                  @include width(31.3333%);
                  @include set-margin(3%,#{$textDirectionOpposite});
                  @include set-margin(3%,bottom);
                  @extend .pulls-#{$textDirection};
                }
              }
              .guest{
                @include set-border(1px solid $bg-of-white);
                @include set-padding($padding-10 0);
                @extend .second-color;
                .skin-color{
                  @extend .primary-color;
                  @extend .shows;
                }
              }
              .other-details{
                @include font-size(0.8333em);
                @include set-margin(0,bottom);
                @extend .text-#{$textDirectionOpposite};
                @extend .text-uppercase;
                dt{
                  @extend .pulls-#{$textDirection};
                  @extend .text-#{$textDirection};
                  @extend .no-margin;
                  @extend .primary-color-mid;
                  @include set-padding(1.3em 0);
                  clear: both;
                }
                dd{
                  color: $primary-text-dark;
                  @include font-size(11px);
                  @include font-weight(800);
                  @include set-padding(1.3em 0);
                  @include set-border(1px solid #f5f5f5,top);
                }
                .total-price{
                  @include font-size(1.5em);
                  color: $secondry-color;
                  text-transform: none;
                  @include set-padding($padding-15 0);
                }
                .total-price-value{
                  color: $primary-color-mid;
                  @include font-size($text-size + 1);
                  @include font-weight(600);
                }
              }
            }
            .contact-box{
              @include font-size(1.3333em);
              @include line-height(1.25em);
              p{
                @include font-size($text-size - 1);
                @include line-height(1.6666);
                @include set-padding(0.75em 0);
                @include set-margin($margin-15,bottom);
                color: $primary-text-dark;
                @include font-weight($font-normal);
              }
              .contact-details{
                @include set-margin(0);
                .contact-phone{
                  @extend .primary-color;
                  &>i{
                    color: #fdb714;
                    @include font-size($text-size - 3);
                    @extend .flip-horizental;
                  }
                }
                .contact-email{
                  color: $primary-text-dark;
                  @include font-size($text-size - 3);
                  @include set-padding(0 $padding-25 - 1);
                  @include line-height(2em);
                }
              }
            }
          }

        }
        .payment-wraper{
          @extend .booking-wraper;
          .credit-card{
            .payment-method-name{
              @extend .relative;
              @include set-position(7px,top);
              &>input{
                vertical-align: baseline;
                vertical-align: -webkit-baseline-middle;
              }
              &>span{
                vertical-align: baseline;
                vertical-align: -webkit-baseline-middle;
              }
            }
            .option-input{
              &.radio{
                @include border-radius(50%);
                &:after{
                  @extend .radio;
                }
              }
              @include _appearance($none);
              @extend .relative;
              @include set-position(-3px,top);
              @include set-position(0,#{$textDirection});
              @include set-position(0,bottom);
              @include set-position(0,#{$textDirectionOpposite});
              @include height(15px);
              @include width(15px);
              @extend .child-transition;
              background: $divider;
              @extend .no-border;
              @extend .white;
              cursor: pointer;
              @extend .inline-b;
              @include set-margin(0.5rem,#{$textDirectionOpposite});
              @extend .no-outline;
              @extend .relative;
              @include z-index(1000);
              &:checked{
                background: $primary-color;
                &::before{
                  @include height(15px);
                  @include width(15px);
                  @extend .absolute;
                  content: '✔';
                  @extend .inline-b;
                  @include font-size($text-size - 2.33333px);
                  @extend .text-center;
                  @include line-height(18px);
                }
                &::after{
                  @include _progress_active(click-wave-right 0.45s);
                  background: $primary-color;
                  content: '';
                  @extend .shows;
                  @extend .relative;
                  @include z-index(100);
                }
              }
              &:not(:checked):hover{
                background: #9faab7;
              }
            }
            .payment-method-img{
              @extend .img;
              @include width(125px);
              @extend .relative;
              img{
                @extend .img;
              }
            }
          }
          .card-information{
            input.input-text{
              background: $bg-of-white;
            }
          }
        }
      }
    }
  }

}

.tabs-style-iconbox nav ul li.tab-complete{
  background-color: $primary-color;
}
.tabs-style-iconbox nav ul li.tab-complete .fa{
  color: $white;
}

/*====================================credit card style start==========================================*/
.payment-wraper .credit-card .payment-method-img{
  width: 125px;
  height: auto;
  position: relative;
}
.payment-wraper .credit-card .payment-method-img img{
  max-width: 100%;
  height: auto;
}
.currrency-help {
  h4{
    font-size: 18px;
    line-height: 1.25em;
  }
  p{
    font-size: 14px;
    line-height: 1.6666;
    padding: 0.75em 0;
    margin-bottom: 15px;
    color: #838383;
    font-weight: normal;
    word-wrap: normal;
    text-align: justify;
    text-justify: inter-word;
  }

}
/*====================================Voucher style start==========================================*/
.voucher{
  font: 14px/21px 'Roboto', Arial, Helvetica, sans-serif;
  @include font-weight(500);
  .main-heading{
    @extend .relative;
    &:nth-of-type(1){
      input.v-btn{
        @extend .absolute;
        #{$textDirectionOpposite}: 0;
      }
    }
    h1{
      @extend .second-color;
    }
    h4{
      @extend .text-uppercase;
      @extend .primary-color-mid;
      @include set-margin($margin-20,bottom);
    }
  }
  .hotel-title{
    @extend .no-margin;
    @include font-size($text-md-size);
    @extend .second-color;
    .stars{
      @extend .v-align-middle;
    }
  }
  .address-loc{
    @include font-size($text-size-13);
    @extend .text-capitalize;
    @include set-margin($margin-5,bottom);
    @extend .primary-color-mid;
    font-weight: 500;
    i{
      @include font-size($text-size+1);
    }
  }
  .booking-detail{
    & > .row:first-child{
      span:nth-of-type(1){
        @extend .second-color;
      }
      span:nth-of-type(2){
        @extend .primary-color-mid;
        font-weight: bold;
      }
    }
    .border{
      .row{
        &:nth-of-type(1){
          @extend .margin-btm;
        }
        &:nth-of-type(2){
          @extend .margin-btm;
        }
        &:nth-of-type(3){
          @extend .margin-btm;
        }
      }
    }
  }
  .border{
    @include set-border(2px solid $primary-color);
    @include border-radius(4px);
  }
  .contact-detail{
    &>.row:last-child{
      @extend .margin-btm;
    }
  }
  #roomsTable{
    .rooms-table-header{
      @extend .primary-color-mid-bg;
      @extend .white;
      @extend .no-padding;
      @include border-radius(2px);
      .voucher-table-border{
        @include set-border(1px solid #f2f2f2,right);
        @include set-padding($padding-5);
      }
    }
    .room{
      background: rgba(0, 51, 154, 0.1);
      @include border-radius(8px);
      &:nth-of-type(2){
        .v_info-h:nth-of-type(1){
          @include set-border(1px solid #f2f2f2,bottom);
          @include set-border(1px solid #f2f2f2,top);
        }
      }
      .v_info-h{
        @extend .primary-color-mid-bg;
        @extend .white;
        @include set-border(1px solid #f2f2f2,right);
        @include set-padding($padding-5);
      }
    }
  }
  .v_field{
    .v_info{
      @extend .second-color;
      &.text-center{
        @include set-padding($padding-5);
      }
    }
  }
  .box{
    @include box-shadow(0 0px 1px $primary-color-mid inset);
  }
  .s-request{
    @include set-padding($padding-10);
  }
  .polices-heading{
    span{
      @extend .second-color;
      @include font-size($text-md-size);
    }
  }
  .polices-disc{
    @include font-size(12px);
    @extend .text-justify;
  }
  .v-btn{
    @include set-padding($padding-10 $padding-20);
    @include set-margin(10px,top);
  }
}
/*====================================Voucher style end==========================================*/
/*========================= deal page start =========================*/
.deals-page{
  /*width: 1100px;*/
  margin: 0 auto;
  @extend .relative;
  @include font-size($text-lg-size);
  line-height: 24px;
  @extend .text-center;
  .disabled{
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: not-allowed;
    input{
      cursor: not-allowed;
    }
  }
  .deal-Gird-1 {
    margin: 0 0 30px;
    background-color: rgba(255, 255, 255, 0.83);
    @extend .no-padding;
    @extend .relative;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    .img-holder{
      z-index: 1;
      @extend .relative;
    }
  }
  h2{

    font-size: 50px;
    line-height: 38px;
    font-weight: bold;
    @extend .text-center;
    color: $secondry-color;
    text-transform: uppercase;
  }
  .content-hlder{
    padding: 10px 0 0;
    @extend .relative;
    min-height: 150px;
    .deal-card{
      color: #666;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 175px;
      width: 100%;
      padding: 5px 0;
    }
    .description{
      text-overflow: ellipsis;
      @extend .no-scroll;
      @extend .full-width;
      max-height: 145px;
      li{
        line-height: 24px;
        list-style: outside disc;
        margin: 0 0 0 10px;
        font-size: 14px;
        @extend .text-left;
      }
    }
    .learn-more{
      @extend .shows;
      @extend .text-left;
      margin-left: 10px;
      a{
        @extend .no-border;
        text-decoration: underline;
        padding: 1px 5px;
        @extend .parent-transition;
        &:hover{
          text-decoration: none;
          @extend .child-transition;
        }
      }
    }
    .right{
      @extend .pulls-#{$textDirectionOpposite};
      @extend .text-#{$textDirectionOpposite};
      .line-through{
        text-decoration: line-through;
        font-size: 13px;
        line-height: 15px;
        color: #ccc;
        font-weight: normal;
      }
      strong{
        color: $primary-color;
      }
      .rating-list {
        @extend .no-padding;
        @extend .no-margin;
        @extend .list-none;
        @extend .no-scroll;
        @extend .text-#{$textDirection};
        font-size: 15px;
        li {
          @extend .inline-b;
          @extend .v-align-middle;
        }
      }
    }
    .left{
      @extend .pulls-#{$textDirection};
      strong{
        @extend .second-color;
      }
    }
    .txt-room-no {
      color: #ccc;
      font-size: 12px;
      line-height: 14px;
      @extend .shows;
      @extend .text-#{$textDirection};;
    }
    ul{
      &.rating-list li a{
        @extend .primary-color;
      }
      &.aminety-wrap li a i{
        @extend .second-color;
      }
    }
    .mlt_option{
      &:not(.progress-striped-brown){
        background: $primary-color;
      }
      border: 2px solid $white;
      padding: $padding-10 2em $padding-10 $padding-10;
      /*.radio-option{
        @extend .inline-b;
        border-radius: 25px;
        @extend .no-border;
        background: $white;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-bottom: 24px;
        width: 25px;
        height: 25px;
        @extend .left;
        .inner{
          @extend .relative;
          @extend .inline-b;
          @include transition(all 0.2s ease);
          border-radius: 50%;
          background: rgba(0, 0, 0, 0);
          @extend .no-border;
          width: 19px;
          height: 19px;
          left: 0px;
          top: 3px;
          @include transform(scale(0));
        }
        input{
          width: 0;
          height: 0;
          @extend .no-opacity;
          @extend .no-scroll;
        }
        &.checked{
          .inner{
            background: $primary-color;
            @include transform(scale(1));
          }
        }
      }*/
      .radio-option{
        @extend .relative;
        .choice{
          @extend .absolute;
          top: 3px;
          right: 0;
          width: 18px;
          height: 18px;
          @extend .inline-b;
          cursor: pointer;
          @extend .v-align-middle;
          border-radius: 100%;
          span.c-radio{
            border: 2px solid $white;
            display: -moz-inline-box;
            @extend .inline-b;
            border-radius: 100%;
            @extend .text-center;
            @extend .relative;
            width: 18px;
            height: 18px;
            &:after{
              content: "";
              @extend .absolute;
              top: 3px;
              left: 3px;
              border: 4px solid;
              border-color: inherit;
              width: 0;
              height: 0;
              border-radius: 100%;
              opacity: 0;
              filter: alpha(opacity=0);
              @include transform(scale(0));
            }
          }
          input[type=radio]{
            border: none;
            background: none;
            display: -moz-inline-box;
            @extend .inline-b;
            @extend .no-margin;
            vertical-align: top;
            cursor: pointer;
            @extend .absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            @extend .no-opacity;
            filter: alpha(opacity=0);
          }
        }
        /*&.checked{
          .inner{
            background: $primary-color;
            @include transform(scale(1));
          }
        }*/
      }
      &.active .radio-option .choice span.c-radio{
        &:after{
          opacity: 1;
          filter: alpha(opacity=100);
          @include transform(scale(1));
        }
      }
    }
    .mlt-price-holder{
      @extend .pulls-left;
      margin-right: $margin-20;
      .nights{
        font-size: $text-size;
        @extend .white;
      }
      .mlt-price{
        color: $white;
        font-weight: bold;
        padding: 0;
        margin-bottom: 0px;
        font-size: 34px;
        cursor: pointer;
        .mlt-coma2{
          left: 0px;
          @extend .relative;
        }
        &:after{
          white-space: pre;
          font-size: 12px;
        }
      }
    }
    @media only screen and (max-width: 800px){
      .mlt_option{
        height: 50px;
      }
      .mlt-price-holder{
        .mlt-price{
          font-size: 24px;
        }
      }
    }
  }
  .amentity{
    .aminety-wrap{
      @extend .list-none;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      padding: 5px 0;
      margin: 10px 0;
      font-size: 22px;
      li{
        @extend .inline-b;
      }
    }
  }
  .off-percent{
    &:before{
      @extend .absolute;
      content: '';
      background: $secondry-color;
      left: 6px;
      top: 25px;
      width: 40px;
      height: 40px;
      @include transform(rotate(-20deg));
      z-index: -1;
    }
    @extend .absolute;
    top: 10px;
    left: -10px;
    background: $secondry-color;
    @extend .white;
    padding: 5px 12px;
    @extend .inline-b;
    vertical-align: top;
    min-width: 110px;
    font-weight: normal;
  }
  .book-btn{
    cursor: pointer;
    font-size: 16px;
    padding-top: 14px;
    font-weight: bold;
  }
  .more-deal-btn{
    a{
      margin: 0 auto 20px;
    }
  }
  .has-value{
    box-shadow: 1px 2px 20px rgba(0, 52, 154, 0.78);
  }
  .progress{
    height: 50px;
  }
  .active-text{
    h4{
      color: $tripadvisor-color;
      font-size: $text-lg-size;
    }
  }
  .deal-count-holder{
    margin: 25px 0 35px;
    .counter-text{
      background: linear-gradient(to top left, #79c4e4 , #3a9fca);
      padding: 10px;
      padding-right: 0;
      margin-bottom: 10px;
      color: #fff;
      .counter{

        padding: 10px;
        padding-left: 0;
      }
    }
  }
  /*==================== coupon start=========================*/
  .banner-content {
    &:before{
      left: 8px;
      top: -26px;
      @include transform(rotate(180deg));
    }
    &:before,&:after{
      @extend .absolute;
      content: "";
      background: url(/web/images/scisor.png);
      background-repeat: no-repeat;
      height: 39px;
      width: 43px;
    }
    @extend .relative;
    border: 2px dashed $primary-color;
    @extend .inline-b;
    padding: 30px 30px;
    @extend .text-center;
    &:after{
      right: 5px;
      bottom: -25px;
    }
  }
  .coupon{
    .img-holder{
      @extend .relative;
      img{
        @extend .img;
      }
    }
    .hotel-name{
      border-bottom: 1px solid $primary-color;
      margin-bottom: 15px;
      .stay{
        @extend .shows;
        font-size: 13px;
        color: #777;
      }
      .txt-or{
        @extend .shows;
        margin-bottom: 0;
      }
      .rating-list{
        @extend .list-none;
        li{
          @extend .v-align-middle;
          @extend .inline-b;
        }
      }
      .txt-room-no{
        @extend .shows;
        font-size: 13px;
        color: #777;
        margin-bottom: 15px;
      }
    }
    .deal-content{
      border: 1px solid #d9d9d9;
      border-top: none;
      padding: 15px 15px 20px;
      padding-bottom: 5px;
      @extend .text-center;
      p {
        color: #777;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Hind', sans-serif;
        line-height: 25px;
        letter-spacing: 0.64px;
        word-wrap: break-word;
      }
      .getdeal-btn {
        @extend .inline-b;
        margin-bottom: 12px;
        @extend .relative;
        word-wrap: break-word;
        @extend .no-scroll;
        &:hover{
          .get-coupencode{
            border-color: $secondry-color;
          }
          .getdeal{
            left: -125px;
          }
          .get-coupencode{
            color: $secondry-color;
          }
        }
      }
      .get-coupencode{
        border: 2px dashed #d9d9d9;
        @extend .black;
        @extend .inline-b;
        font-family: 'Hind', sans-serif;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 1.05px;
        line-height: 28px;
        margin-left: 36px;
        padding: 3.5px 8px;
        @extend .relative;
        @extend .text-right;
        text-transform: uppercase;
        @include transition(all 1s ease 0s);
        min-width: 151px;
        z-index: -1;
        word-wrap: break-word;
      }
      .getdeal {
        color: #fff;
        @extend .inline-b;
        font-family: 'Hind', sans-serif;
        font-size: 13px;
        letter-spacing: 0.91px;
        font-weight: 600;
        left: 0;
        min-width: 190px;
        padding: 10px 15px 9px;
        @extend .absolute;
        @extend .text-left;
        text-transform: uppercase;
        top: 0;
        @include transition(all 1s ease 0s);
        z-index: 2;
        word-wrap: break-word;
        &:before{
          @extend .absolute;
          content: "";
          background: url(/web/images/copunshape.png);
          background-repeat: no-repeat;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
        }
      }
      .desc{
        border-bottom: 1px solid $primary-color;
        margin-bottom: 15px;
      }
      .contact-info{
        @extend .text-left;
        font-size: 15px;
        span{
          @extend .inline-b;
        }
        i{
          color: #23527c;
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }
  }
  //deal page end
}
.ribbon {
  font-size: 16px !important;
  /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */

  @extend .full-width;

  @extend .relative;
  background: $primary-color-mid;
  @extend .white;
  @extend .text-center;
  padding: 1em 2em; /* Adjust to suit */
  margin: 0;
}
.ribbon:before, .ribbon:after {
  content: "";
  @extend .absolute;
  @extend .shows;
  bottom: -1em;
  border: 1.5em solid $primary-color-mid;
  z-index: -1;
}
.ribbon:before {
  left: -2em;
  border-right-width: 1.5em;
  border-left-color: transparent;
}
.ribbon:after {
  right: -2em;
  border-left-width: 1.5em;
  border-right-color: transparent;
}
.ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
  content: "";
  @extend .absolute;
  @extend .shows;
  border-style: solid;
  border-color: $secondry-color transparent transparent transparent;
  bottom: -1em;
}
.ribbon .ribbon-content:before {
  left: 0;
  border-width: 1em 0 0 1em;
}
.ribbon .ribbon-content:after {
  right: 0;
  border-width: 1em 1em 0 0;
}
.deal-modal{
  .hotel-bar{
    background: $primary-color;
    margin: 10px 0;
    padding: 1px 13px;
  }
  .hotel-title {
    font-size: 24px;
    color: rgb(0, 52, 154);
    margin: 10px 0px;
  }
  .address-loc {
    color: #79c4e4;
    font-size: unset;
    margin: 10px 0;
    line-height: 2;
    @extend .text-right;
  }
  .hotel-desc{
    font-weight: bolder;
  }
  .adit-info{
    border-bottom: 1px solid $primary-color-mid;
    color: $primary-color-mid;
    margin-top: 30px;
    padding-bottom: 5px;
  }
  .owl-thumbs{
    position: relative;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding: 2% 0;
  }
  .owl-thumb-item {
    display: inline-block;
    border: none;
    background: none;
    padding: 0;
    opacity: .4;
    img{
      width: 150px;
      height: auto;
    }
  }
  .owl-thumb-item.active {
    opacity: 1;
  }
}

#hotel-deal .modal-dialog{
  width: 90%;
}
.owl-stage-outer{
  height: 21.87%;
}
.owl-carousel .owl-item .item> img,.owl-carousel .owl-item img{
  @extend .full-width;
}
/*========================= deal booking page start =========================*/
.voucher-selector{
  span:first-child {
    font-size: 15px;
    color: #00349a;
    line-height: 2;
  }
  h2{
    @extend .inline-b;
  }
  .deal-selector{
    @extend .inline-b;
    @extend .pulls-#{$textDirectionOpposite};
    span{
      width: 15%;
    }
  }
}
/*========================= deal booking page end =========================*/
/*========================= deal page end =========================*/
/*================================ input css loading=====================================*/

#main{
  @extend .relative;
  border-top: 1px solid #d4d4d4;
  @include give_padding($padding-30 0);
  &:after{
    @extend .show;
    content: '';
    clear: both;
  }
}
/*===================================subscribe section start====================================*/
.subscribe-section {
  @extend .relative;
  background: $primary-color;
  @extend .full-width;
  padding: 50px 0 0;
  .subscribe-holder{
    max-width:1100px;
    margin:0 auto 50px;
    @extend .relative;
    @extend .no-scroll;
  }
  h2{
    @extend .white;
    margin: 0 0 15px;
    font-size: 28px;
    line-height: 32px;
    @extend .text-center;
  }
  .subscribe {
    @extend .relative;
    max-width: 600px;
    @extend .margin-0-auto;
  }
  .custom-field{
    @extend .white-bg;
    @include set-border(1px solid #fff);
    @extend .black;
    @extend .shows;
    margin: 0 0 10px;
    padding: 10px 15px;
    @extend .full-width;
    border-radius: 3px;
  }
  .fake-input {
    @extend .inline-b;
    @extend .v-align-middle;
    height: 16px;
    width: 16px;
    @extend .relative;
    margin: 0 5px 0 0;
    @extend .white-bg;
    border-radius: 3px;
    @extend .no-border;
    &:before{
      content: '';
      @extend .absolute;
      height: 8px;
      width: 2px;
      @extend .primary-color-bg;
      bottom: 0;
      @extend .no-opacity;
      #{$textDirection}: 3px;
      transform: rotate(-42deg);
    }
    &:after{
      content: '';
      @extend .absolute;
      height: 17px;
      width: 2px;
      @extend .primary-color-bg;
      bottom: 0;
      #{$textDirection}: 11px;
      @extend .no-opacity;
      transform: rotate(-140deg);
    }
  }
  input[type='checkbox'] {
    position: fixed;
    z-index: -111;
    @extend .no-opacity;
    #{$textDirection}: 0;
    top: 0;
    &:checked ~ .fake-input:before,
    &:checked ~ .fake-input:after{
      opacity: 1;
    }
  }
  .btn-submit{
    @extend .pulls-#{$textDirectionOpposite};
    background: $secondry-color;
    color: $white;
    padding: 8px 16px;
    border-radius: 3px;
    @extend .no-outline;
    @extend .no-border;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .subscribe-nav {
    @extend .relative;
    @extend .no-scroll;
    @extend .full-width;
    background: rgba(0, 0, 0, .25);
    ul{
      padding: 10px 0 0;
      @extend .margin-0-auto;
      @extend .list-none;
      @extend .no-scroll;
      max-width: 1100px;
    }
    li{
      @extend .inline-b;
      @extend .v-align-middle;
      @extend .no-margin;
      @include set-margin(6%,#{$textDirectionOpposite});
      &:last-child{
        @extend .no-margin;
      }
      &.active{
        background: rgba(0, 0, 0, .25);
      }
    }
    a{
      @extend .shows;
      padding:7px 13px;
      color: $white;
      &:hover,
      &:focus{
        background:rgba(0, 0, 0, .25);
        color:#fff;
        text-decoration:none;
      }
    }

  }
}
/*===================================subscribe section end====================================*/

#footer{
  @extend .relative;
  @extend .no-scroll;
  background:#f2f4f8;
  padding:20px 0 0;
  .footer-holder {
    max-width: 80%;
    @extend .relative;
    @extend .no-scroll;
    @extend .margin-0-auto;
    font-size: 12px;
    line-height: 15px;
    @extend .text-#{$textDirection};
    .app-holder {
      @extend .relative;
      padding: 20px 0;
      .form-holder{
        @extend .pulls-#{$textDirection};
        width:50%;
        @extend .black;
        font-size:14px;
        h2{
          @extend .no-margin;
          color:$secondry-color;
          font-size:25px;
          line-height:34px;
        }
        .two-forms{
          @extend .relative;
          margin:0 0 10px;
          .email-form{
            @extend .relative;
            @extend .pulls-#{$textDirection};
            width:49%;
          }
          .sms-form {
            @extend .relative;
            @extend .pulls-#{$textDirectionOpposite};
            width: 49%;
            .sms-holder{
              &.custom-field{
                @extend .no-padding;
                .intl-tel-input{
                  @extend .full-width;
                }
              }
              @extend .relative;
              input{
                @extend .full-width;
              }
              @if $isRtl == true{
                .intl-tel-input{
                  @extend .inline-b;
                  @extend .relative;
                  .flag-container{
                    @extend .absolute;
                    top:0;
                    bottom: 0;
                    #{$textDirectionOpposite}: 0;
                  }
                }
                .intl-tel-input{
                  &.allow-dropdown,&.separate-dial-code{
                    .flag-container{
                      #{$textDirection}: 0;
                      #{$textDirectionOpposite}: auto;
                    }
                  }
                  .selected-flag{
                    @include set-padding(0 ($padding-10 - 2) 0 0);
                    .iti-arrow{
                      @include set-position(initial,#{$textDirection});
                      @include set-position(6px,#{$textDirectionOpposite});
                    }
                  }
                  .selected-dial-code{
                    @include set-padding($padding-30 - 2,#{$textDirection});
                  }
                  input{
                    @extend .no-outline;
                    @extend .no-padding-left;
                    @include set-padding(84px,#{$textDirection});
                  }
                }
              }
            }
          }
          &:after{
            @extend .shows;
            clear:both;
            content:'';
          }
          a{
            @extend .white;
          }
        }
        .txt-available{
          @extend .shows;
          font-size:15px;
          line-height:19px;
        }
        .custom-field{
          @extend .white-bg;
          border: 1px solid #ced0d3;
          @extend .black;
          @extend .shows;
          margin: 0 0 10px;
          padding: 10px 10px;
          @extend .full-width;
          border-radius:3px;
        }
        .btn-submit{
          @extend .full-width;
          @extend .shows;
          background: $secondry-color;
          @extend .white;
          font-size:18px;
          line-height:24px;
          font-weight:bold;
          @extend .text-center;
          @extend .no-outline;
          @extend .no-border;
          padding:8px 10px;
          border-radius:3px;
        }
      }
      .code-listing-holder{
        @extend .pulls-#{$textDirectionOpposite};
        width:49%;
        padding:50px 0 0;
        .code-listing {
          @extend .pulls-#{$textDirection};
          width: 60%;
          @extend .no-padding;
          @include set-padding(30px,#{$textDirection});
          @extend .no-margin;
          @extend .list-none;
          li{
            &:before{
              content: "\f00c ";
              font-family: FontAwesome;
              font-style: normal;
              font-weight: normal;
              text-decoration: inherit;
              padding-right: 0.5em;
              @extend .absolute;
              top: 3px;
              #{$textDirection}: -25px;
              color: $primary-color;
              @extend .no-margin;
              font-size: 14px;
            }
            @extend .relative;
            margin: 0 0 $margin-15;
            a{
              @extend .primary-color;
            }
          }
        }
        .bar-code {
          @extend .pulls-#{$textDirectionOpposite};
          width: 39%;
          @extend .text-center;
          .bar-code-holder {
            margin: 0 auto 15px;
            max-width: 100px;
            img{
              @extend .img;
            }
          }
        }
      }
      &:after{
        @extend .shows;
        clear:both;
        content:'';
      }
    }
    .copy-right-holder {
      @extend .relative;
      color: #9c9c9c;
      font-size: 13px;
      line-height: 28px;
      @extend .text-center;
      padding: 10px 0;
      @include set-border(1px solid #ced0d3,top);
      p{
        @extend .no-margin;
      }
    }
    .find-more{
      color: $primary-color;
      text-decoration:underline;
      font-size:15px;
      line-height:19px;
    }

  }
}

/*=================================== cms section start ====================================*/

.rent-car{
  iframe{
    width: 100%;
    height: 992px;
  }
}
/*=================================== cms section end ====================================*/
/*==============================Media Queries=================================*/
@import "../mediaqueries";