@keyframes shake {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px);
  }
  20%, 60% {
    transform: translate3d(-5px, 0px, 0px);
  }
  40%, 80% {
    transform: translate3d(5px, 0px, 0px);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px);
  }
  20%, 60% {
    transform: translate3d(-5px, 0px, 0px);
  }
  40%, 80% {
    transform: translate3d(5px, 0px, 0px);
  }
}
.shake {
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-name: shake;
  animation-timing-function: ease;
  animation-delay: 1.2s;
}
@keyframes jump {
  0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
  100%  {transform: translate3d(0,10%,0) scale3d(1,1,1);}

}
.jump {
  transform-origin: 50% 50%;
  animation: jump .5s linear alternate infinite;
}
@keyframes blink {
  0% {opacity: 0}
  49%{opacity: 0}
  50% {opacity: 1}
}
@keyframes edit-in{
  from {opacity: 0; transform: rotateZ(-70deg);}
  to {opacity: 1; transform: rotateZ(0deg);}
}

@keyframes edit-out{
  from {opacity: 1; transform: rotateZ(0deg);}
  to {opacity: 0; transform: rotateZ(-70deg);}
}

@keyframes plus-in{
  from {opacity: 1; transform: rotateZ(0deg);}
  to {opacity: 0; transform: rotateZ(180deg);}
}

@keyframes plus-out{
  from {opacity: 0; transform: rotateZ(180deg);}
  to {opacity: 1; transform: rotateZ(0deg);}
}


.blink{animation: blink 3s infinite;}
.img-loader::after {
  @include width(20px);
  @include height(20px);
  @extend .relative;
  @extend .absolute;
  @include set-margin(-46px auto 0);
  content:'';
  animation: loader08 1s ease infinite;
  /*@include position(50%,initial,0,0);*/
  top:50%;
  left:0;
  right:0
}
.img-loader::after {
  transform: scale(0.6);
  -moz-transform: scale(0.6);
  -webkit-transform: scale(0.6);
}
@keyframes loader08 {
  0%, 100% {
    box-shadow: -13px 20px 0 #5badff, 13px 20px 0 rgba(0, 82, 236, 0.2), 13px 46px 0 rgba(0, 82, 236, 0.2), -13px 46px 0 rgba(0, 82, 236, 0.2); }
  25% {
    box-shadow: -13px 20px 0 rgba(0, 82, 236, 0.2), 13px 20px 0 #5badff, 13px 46px 0 rgba(0, 82, 236, 0.2), -13px 46px 0 rgba(0, 82, 236, 0.2); }
  50% {
    box-shadow: -13px 20px 0 rgba(0, 82, 236, 0.2), 13px 20px 0 rgba(0, 82, 236, 0.2), 13px 46px 0 #5badff, -13px 46px 0 rgba(0, 82, 236, 0.2); }
  75% {
    box-shadow: -13px 20px 0 rgba(0, 82, 236, 0.2), 13px 20px 0 rgba(0, 82, 236, 0.2), 13px 46px 0 rgba(0, 82, 236, 0.2), -13px 46px 0 #5badff; } }
.loading:before {

  /*animation: spin 3.45s infinite;
  -o-animation: spin 3.45s infinite;
  -ms-animation: spin 3.45s infinite;
  -webkit-animation: spin 3.45s infinite;
  -moz-animation: spin 3.45s infinite;*/
}
.loading:after {

  /*animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
    -o-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
    -ms-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
    -webkit-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
    -moz-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;*/
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  50% {
    -o-transform: rotate(360deg);
  }
  100% {
    -o-transform: rotate(1080deg);
  }
}

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  50% {
    -ms-transform: rotate(360deg);
  }
  100% {
    -ms-transform: rotate(1080deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(1080deg);
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(1080deg);
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(121, 196, 228,0.2);
  }
  13% {
    background-color: rgba(121, 196, 228,0.2);
  }
  15% {
    background-color: rgba(121, 196, 228,0.9);
  }
  28% {
    background-color: rgba(121, 196, 228,0.9);
  }
  30% {
    background-color: rgba(121, 196, 228,0.2);
  }
  43% {
    background-color: rgba(121, 196, 228,0.2);
  }
  45% {
    background-color: rgba(121, 196, 228,0.9);
  }
  70% {
    background-color: rgba(121, 196, 228,0.9);
  }
  74% {
    background-color: rgba(121, 196, 228,0.2);
  }
  100% {
    background-color: rgba(121, 196, 228,0.9);
  }
}

@-o-keyframes pulse {
  0% {
    background-color: rgba(121, 196, 228,0.2);
  }
  13% {
    background-color: rgba(121, 196, 228,0.2);
  }
  15% {
    background-color: rgba(121, 196, 228,0.9);
  }
  28% {
    background-color: rgba(121, 196, 228,0.9);
  }
  30% {
    background-color: rgba(121, 196, 228,0.2);
  }
  43% {
    background-color: rgba(121, 196, 228,0.2);
  }
  45% {
    background-color: rgba(121, 196, 228,0.9);
  }
  70% {
    background-color: rgba(121, 196, 228,0.9);
  }
  74% {
    background-color: rgba(121, 196, 228,0.2);
  }
  100% {
    background-color: rgba(121, 196, 228,0.9);
  }
}

@-ms-keyframes pulse {
  0% {
    background-color: rgba(121, 196, 228,0.2);
  }
  13% {
    background-color: rgba(121, 196, 228,0.2);
  }
  15% {
    background-color: rgba(121, 196, 228,0.9);
  }
  28% {
    background-color: rgba(121, 196, 228,0.9);
  }
  30% {
    background-color: rgba(121, 196, 228,0.2);
  }
  43% {
    background-color: rgba(121, 196, 228,0.2);
  }
  45% {
    background-color: rgba(121, 196, 228,0.9);
  }
  70% {
    background-color: rgba(121, 196, 228,0.9);
  }
  74% {
    background-color: rgba(121, 196, 228,0.2);
  }
  100% {
    background-color: rgba(121, 196, 228,0.9);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(121, 196, 228,0.2);
  }
  13% {
    background-color: rgba(121, 196, 228,0.2);
  }
  15% {
    background-color: rgba(121, 196, 228,0.9);
  }
  28% {
    background-color: rgba(121, 196, 228,0.9);
  }
  30% {
    background-color: rgba(121, 196, 228,0.2);
  }
  43% {
    background-color: rgba(121, 196, 228,0.2);
  }
  45% {
    background-color: rgba(121, 196, 228,0.9);
  }
  70% {
    background-color: rgba(121, 196, 228,0.9);
  }
  74% {
    background-color: rgba(121, 196, 228,0.2);
  }
  100% {
    background-color: rgba(121, 196, 228,0.9);
  }
}

@-moz-keyframes pulse {
  0% {
    background-color: rgba(121, 196, 228,0.2);
  }
  13% {
    background-color: rgba(121, 196, 228,0.2);
  }
  15% {
    background-color: rgba(121, 196, 228,0.9);
  }
  28% {
    background-color: rgba(121, 196, 228,0.9);
  }
  30% {
    background-color: rgba(121, 196, 228,0.2);
  }
  43% {
    background-color: rgba(121, 196, 228,0.2);
  }
  45% {
    background-color: rgba(121, 196, 228,0.9);
  }
  70% {
    background-color: rgba(121, 196, 228,0.9);
  }
  74% {
    background-color: rgba(121, 196, 228,0.2);
  }
  100% {
    background-color: rgba(121, 196, 228,0.9);
  }
}

@keyframes borderPulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228,0.8);
  }
  40% {
    box-shadow: 0 0 0 1px rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228, 0.8);
  }
  80% {
    box-shadow: 0 0 0 2px #FFF, 0 0 1px 2px rgba(121, 196, 228, 0.8);
  }
}

@-o-keyframes borderPulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228,0.8);
  }
  40% {
    box-shadow: 0 0 0 1px rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228, 0.8);
  }
  80% {
    box-shadow: 0 0 0 2px #FFF, 0 0 1px 2px rgba(121, 196, 228, 0.8);
  }
}

@-ms-keyframes borderPulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228,0.8);
  }
  40% {
    box-shadow: 0 0 0 1px rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228, 0.8);
  }
  80% {
    box-shadow: 0 0 0 2px #FFF, 0 0 1px 2px rgba(121, 196, 228, 0.8);
  }
}

@-webkit-keyframes borderPulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228,0.8);
  }
  40% {
    box-shadow: 0 0 0 1px rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228, 0.8);
  }
  80% {
    box-shadow: 0 0 0 2px #FFF, 0 0 1px 2px rgba(121, 196, 228, 0.8);
  }
}

@-moz-keyframes borderPulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228,0.8);
  }
  40% {
    box-shadow: 0 0 0 1px rgb(255,255,255), 0 0 0 1px rgba(121, 196, 228, 0.8);
  }
  80% {
    box-shadow: 0 0 0 2px #FFF, 0 0 1px 2px rgba(121, 196, 228, 0.8);
  }
}
@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}
@keyframes click-wave-right {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-right: -90px;
    margin-top: -120px;
    opacity: 0;
  }
}
//post skelton
@-webkit-keyframes shimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
@-moz-keyframes shimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
/*================= overlay popup ================*/
@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}
/*================= folding loader animation ================*/
@-webkit-keyframes foldthecube {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes  foldthecube {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
/*================= progress stripped loader animation ================*/
//progress striped animation
.progress-striped{
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress-striped{
  @extend .no-border;
  @include border-radius(3px);
  color: $white;
  @extend .inline-b;
  @extend .no-outline;
  @extend .text-center;
  -webkit-transition: .1s background-color;
  -moz-transition: .1s background-color;
  -o-transition: .1s background-color;
  transition: .1s background-color;
  @include set-padding(.375em .75em);
  @extend .full-width;
  @include height(40px);
  @include font-size($text-size);
  @include font-weight($font-bold);
}
.progress.active{
  @include _progress_active($progress-animation,$progress-duration,$progress-transition,$progress-loop);
  @include set-margin(0);
}

/*======================= listing page laoder ===========================*/
.loading-listing {
  background-color: lightgrey;
  height: 2px;
  margin: 1em;
  @extend .hidden;
  @extend .relative;
  width: 12em;
}

.loading-bar-listing {
  animation: side2side 2s ease-in-out infinite;
  background-color: $secondry-color;
  height: 100%;
  @extend .absolute;
  width: 50%;
}

@keyframes side2side {
  0%, 100% { transform: translateX(-50%); }
  50%      { transform: translateX(150%); }
}

.center {
  left: 50%;
  margin: 0;
  @extend .absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
/*--------------- bookshelf loading animation ---------------*/

@keyframes move {

  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 10px;
  }

}

@keyframes travel {

  0% {
    opacity: 0;
    transform: translateX(300px) rotateZ(0deg) scaleY(1);
  }

  6.5% {
    transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
  }

  8.8% {
    transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
  }

  10% {
    opacity: 1;
    transform: translateX(270px) rotateZ(0deg);
  }

  17.6% {
    transform: translateX(247.2px) rotateZ(-30deg);
  }

  45% {
    transform: translateX(165px) rotateZ(-30deg);
  }

  49.5% {
    transform: translateX(151.5px) rotateZ(-45deg);
  }

  61.5% {
    transform: translateX(115.5px) rotateZ(-45deg);
  }

  67% {
    transform: translateX(99px) rotateZ(-60deg);
  }

  76% {
    transform: translateX(72px) rotateZ(-60deg);
  }

  83.5% {
    opacity: 1;
    transform: translateX(49.5px) rotateZ(-90deg);
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(0px) rotateZ(-90deg);
  }

}
/*--------------- bookshelf loading animation end ---------------*/
@keyframes fadeInDown{
  0% {
    opacity: 0;
    transform: translate3d(0,-100%,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInRight{
  0% {
    opacity: 0;
    transform: translate3d(100%,0,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInLeft{
  0% {
    opacity: 0;
    transform: translate3d(-100%,0,0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeOutLeft{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%,0,0);
  }
}
@keyframes fadeOutRight{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%,0,0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}
.fadeInDown {
  animation-name: fadeInDown;
}
.fadeInRight {
  animation-name: fadeInRight;
}
.fadeInLeft {
  animation-name: fadeInLeft;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}