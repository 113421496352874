//bottom to top button css
@media only screen and (max-width: 800px) {
  /*-=-=-=-=-=-==-= calendar css start -=-=-=-=-=-=-=-*/
  .ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span{
    margin-#{$textDirectionOpposite}: -10px;
    margin-top: -10px;
  }
  .ui-icon {
    width: 20px;
    height: 22px;
  }
  .ui-icon-circle-triangle-e {
    background-position: -46px -190px;
  }
  .ui-icon-circle-triangle-w {
    background-position: -78px -190px;
  }
  /*-=-=-=-=-=-==-= calendar css end -=-=-=-=-=-=-=-*/
  .search-img{
    padding: 25%;
  }
  .loader-header{
    overflow: hidden;
  }
  .shelf {
    &:before,
    &:after {
      left: 0;
    }

    &:after {
      left: 0;
    }

  }
  .label-xs{
    display: block;
  }
  .other-user{
    width: 180px;
    float: #{$textDirectionOpposite};
  }
  @for $i from 1 through 5{
    .mb-xs-#{$i}{
      margin-bottom: #{$i}rem;
    }
  }
  .cd-top {
    #{$textDirectionOpposite}: 20px;
    bottom: 20px;
  }
  .text-xs-center{
    text-align: center;
  }
  .width-xs-inherit{
    width: inherit;
  }
  .no-xs-float{
    float: none;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile{
    #header{
      .container:first-child{
        margin-top: 30px !important;
        .logo{
          max-width: 230px;
          #{$textDirection}:-10px;
        }
      }
      .top-nav{
        float: none !important;
        text-align: center;
        position: absolute;
        #{$textDirection}: 25px;
        #{$textDirectionOpposite}: 25px;
        top: -28px;
        .log-list{
          display: none !important;
        }
        #header_currency{
          button.dropdown-toggle{
            padding: 4px 12px !important;
            font-size: 12px;
          }
        }
        #header_language{
          button.dropdown-toggle{
            padding: 4px 4px !important;
            font-size: 12px;
            @if $isRtl == true{

            }
            @else{
              i.flagstrap-icon{
                margin-#{$textDirection}: 5px;
              }
            }
          }
        }
      }
    }
    &.map-mobile{
      >.hotel-listing{
        display: none;
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    top: initial !important;
    bottom: 0;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      width: 100%;
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 10px;
      + div{
        width: 100%;
        padding-#{$textDirection}: 0;
        padding-#{$textDirectionOpposite}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-100%, 0);
      }
      @include dynamic-transition-with-condition(800,initial);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        @include dynamic-transition-with-condition(800,initial);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 100%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .recomended{
                display: none;
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }
              span.title{
                display: none;
              }
            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            @include transition-with-condition-in-mediaquery(800);
            &.sticky-filter-now{
              .container-floating-parrent {
                /*top:0;
                width: 100%;*/
                background: #79c4e4;
                z-index: 1;
                @include transition-with-condition-in-mediaquery(800,parent-transition);
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper {
            padding: 0;
            .hotel-listing-item{
              position: relative;
              padding: 0;
              background: #fff none repeat scroll 0 0;
              border: 1px solid #ced0d3;
              padding-top: 12px;
              padding-#{$textDirection}: 0px;
              margin: 0 0 20px;
              border-radius: 3px;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              .hotel-figure{
                display: inline-block;
                position: relative;
                margin: 0 0 10px;
                height: 100px;
                width: 26%;
                border-radius: 2px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                font-weight: bolder;
              }
              .hotel-item-detail.item-detail{
                float: #{$textDirection};
                width: 74%;
                border: medium none;
                margin-bottom: 15px;
                @if $isRtl == true{
                  .english_hotel_name{
                    display: block;
                  }
                }@else{
                  .english_hotel_name{
                    display: none;
                  }
                }
              }
              @if $isRtl == true{
                h2{
                  letter-spacing: 0.5px;
                  margin: 0;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  line-height: 28px;
                }
              }
              @else{
                h2{
                  letter-spacing: 0.5px;
                  margin: 0;
                  line-height: 28px;
                }
              }
              .hotel-address{
                display: none;
              }
              .hotel-item-price{
                &.hotel-price-wrap{
                  border: medium none;
                  margin: 0;
                  padding: 0;
                  width: 100%;
                }
                .price-wrap_{
                  text-align: #{$textDirection};
                  display: inline-block;
                  background-color: #3e3d3d;
                  color: #fff;
                  height: 52px;
                  margin: 0;
                  padding: 3px;
                  padding-#{$textDirection}: 15px;
                  padding-#{$textDirectionOpposite}: 0;
                  width: 70%;
                  float: #{$textDirection};
                  bottom:initial;
                }
                lebel{
                  text-transform: inherit;
                  font-weight: normal;
                  font-size: 13px;
                  margin-bottom: 5px;
                }
              }
              .price-strong{
                color: #fff;
                font-size: 20px;
                padding: 0;
              }
              .btn.unq-btn{
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                height: 52px;
                margin: 0;
                padding: 15px 0;
                bottom: initial;
                width: 30%;
              }
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section {
    .other-user .checkbox{
      .cr{
        display: inline;
      }
      .fake-label{
        display: inline;
      }
    }
    .selector .custom-select{
      z-index: 2;
      border: 1px solid $secondry-color;
    }
    .form-group .c_field .select_bootsrap .btn-default{
      border: 1px solid $secondry-color;
      border-radius: 0px;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .label-xs{
    display: none;
  }
  .cd-top {
    height: 60px;
    width: 60px;
    #{$textDirectionOpposite}: 30px;
    bottom: 30px;
  }
}
//bootstrap column ovveride for rtl support
@if $isRtl == true{
  @for $i from 1 through 12{
    .col-xs-#{$i}{
      float: $textDirection;
    }
  }
  @media (min-width: 768px){
    @for $i from 1 through 12{
      .col-sm-#{$i}{
        float: $textDirection;
      }
    }
  }
  @media (min-width: 992px){
    @for $i from 1 through 12{
      .col-md-#{$i}{
        float: #{$textDirection};
      }
    }
  }
}


/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  /* Styles */
}
/**********
iPad 3
**********/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen  and (min-width : 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  .text-xs-center{
    text-align: center;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile #header{
    .container:first-child{
      margin-top: 30px !important;
      .logo{
        max-width: 230px;
        #{$textDirection}:-10px;
      }
    }
    .top-nav{
      float: none !important;
      text-align: center;
      position: absolute;
      #{$textDirection}: 25px;
      #{$textDirectionOpposite}: 25px;
      top: -28px;
      .log-list{
        display: none !important;
      }
      #header_currency{
        button.dropdown-toggle{
          padding: 4px 12px !important;
          font-size: 12px;
        }
      }
      #header_language{
        button.dropdown-toggle{
          padding: 4px 4px !important;
          font-size: 12px;
          @if $isRtl == true{

          }
          @else{
            i.flagstrap-icon{
              margin-#{$textDirection}: 5px;
            }
          }
        }
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      width: 100%;
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 10px;
      + div{
        width: 100%;
        padding-#{$textDirection}: 0;
        padding-#{$textDirectionOpposite}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-100%, 0);
      }
      -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 92%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }
              .recomended{
                display: none;
              }
            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            -o-transition: all 0.25s ease 0s;
            -ms-transition: all 0.25s ease 0s;
            -webkit-transition: all 0.25s ease 0s;
            -moz-transition: all 0.25s ease 0s;
            transition: all 0.25s ease 0s;
            &.sticky-filter-now{
              .container-floating-parrent {
                position: fixed;
                top:0;
                width: 100%;
                background: #79c4e4;
                -o-transition: all 0.85s ease 0s;
                -ms-transition: all 0.85s ease 0s;
                -webkit-transition: all 0.85s ease 0s;
                -moz-transition: all 0.85s ease 0s;
                transition: all 0.85s ease 0s;
                padding: 5px 7px;
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper .hotel-listing-item{
            position: relative;
            padding: 0;
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ced0d3;
            padding-top: 12px;
            padding-#{$textDirection}: 10px;
            margin: 0 0 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            .hotel-figure{
              display: inline-block;
              position: relative;
              margin: 0 0 10px;
              height: 100px;
              width: 26%;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              font-weight: bolder;
            }
            .hotel-item-detail.item-detail{
              float: #{$textDirection};
              width: 74%;
              border: medium none;
              margin-bottom: 15px;
              @if $isRtl == true{
                .english_hotel_name{
                  display: block;
                }
              }@else{
                .english_hotel_name{
                  display: none;
                }
              }
            }
            @if $isRtl == true{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
              }
            }
            @else{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                line-height: 28px;
              }
            }
            .hotel-address{
              display: none;
            }
            .hotel-item-price{
              &.hotel-price-wrap{
                border: medium none;
                margin: 0;
                padding: 0;
                width: 100%;
              }
              .price-wrap_{
                text-align: #{$textDirection};
                display: inline-block;
                background-color: #3e3d3d;
                color: #fff;
                height: 52px;
                margin: 0;
                padding: 3px;
                padding-#{$textDirection}: 15px;
                padding-#{$textDirectionOpposite}: 0;
                width: 70%;
                float: #{$textDirection};
                bottom:initial;
              }
              lebel{
                text-transform: inherit;
                font-weight: normal;
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .price-strong{
              color: #fff;
              font-size: 20px;
              padding: 0;
            }
            .btn.unq-btn{
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              height: 52px;
              margin: 0;
              padding: 15px 0;
              bottom: initial;
              width: 30%;
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section .other-user .checkbox{
    .cr{
      display: inline;
    }
    .fake-label{
      display: inline;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  #listing-page{
    .listing-body .content-holder .listing-holder {
      .sort-bar{
        .sorting {
          span.title{
            display: none;
          }
        }
        .checkbox label > i.lbl-toggle {
          #{$textDirectionOpposite}:-5px;
        }
      }
      .listing-warper .hotel-listing-item h2{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .filter-open{
      .listing-body .content-holder .listing-holder{
        .sticky-filter-now{
          .sorting{
            padding: 0;
          }
          .checkbox label > i.lbl-toggle{
            #{$textDirectionOpposite}: 10px;
          }
        }
      }
    }
    .listing-body{
      .content-holder{
        .listing-holder {
          .sort-bar {
            .checkbox label > i.lbl-toggle{
              span:nth-of-type(2){
                display: none;
              }
              width: 38px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 4px;
              }
              #{$textDirectionOpposite}:4px;
              top:4px;
            }
          }
        }
      }
    }
    /*&.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      !*.listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }*!
    }*/
  }
}

/* iPhone 6 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */

  .text-xs-center{
    text-align: center;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile #header{
    .container:first-child{
      margin-top: 30px !important;
      .logo{
        max-width: 230px;
        #{$textDirection}:-10px;
      }
    }
    .top-nav{
      float: none !important;
      text-align: center;
      position: absolute;
      #{$textDirection}: 25px;
      #{$textDirectionOpposite}: 25px;
      top: -28px;
      .log-list{
        display: none !important;
      }
      #header_currency{
        button.dropdown-toggle{
          padding: 4px 12px !important;
          font-size: 12px;
        }
      }
      #header_language{
        button.dropdown-toggle{
          padding: 4px 4px !important;
          font-size: 12px;
          @if $isRtl == true{

          }
          @else{
            i.flagstrap-icon{
              margin-#{$textDirection}: 5px;
            }
          }
        }
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      width: 100%;
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 10px;
      + div{
        width: 100%;
        padding-#{$textDirection}: 0;
        padding-#{$textDirectionOpposite}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-100%, 0);
      }
      -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 92%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }
            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            -o-transition: all 0.25s ease 0s;
            -ms-transition: all 0.25s ease 0s;
            -webkit-transition: all 0.25s ease 0s;
            -moz-transition: all 0.25s ease 0s;
            transition: all 0.25s ease 0s;
            &.sticky-filter-now{
              .container-floating-parrent {
                position: fixed;
                top:0;
                width: 100%;
                background: #79c4e4;
                -o-transition: all 0.85s ease 0s;
                -ms-transition: all 0.85s ease 0s;
                -webkit-transition: all 0.85s ease 0s;
                -moz-transition: all 0.85s ease 0s;
                transition: all 0.85s ease 0s;
                padding: 5px 7px;
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper .hotel-listing-item{
            position: relative;
            padding: 0;
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ced0d3;
            padding-top: 12px;
            padding-#{$textDirection}: 10px;
            margin: 0 0 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            .hotel-figure{
              display: inline-block;
              position: relative;
              margin: 0 0 10px;
              height: 100px;
              width: 26%;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              font-weight: bolder;
            }
            .hotel-item-detail.item-detail{
              float: #{$textDirection};
              width: 74%;
              border: medium none;
              margin-bottom: 15px;
              @if $isRtl == true{
                .english_hotel_name{
                  display: block;
                }
              }@else{
                .english_hotel_name{
                  display: none;
                }
              }
            }
            @if $isRtl == true{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
              }
            }
            @else{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                line-height: 28px;
              }
            }
            .hotel-address{
              display: none;
            }
            .hotel-item-price{
              &.hotel-price-wrap{
                border: medium none;
                margin: 0;
                padding: 0;
                width: 100%;
              }
              .price-wrap_{
                text-align: #{$textDirection};
                display: inline-block;
                background-color: #3e3d3d;
                color: #fff;
                height: 52px;
                margin: 0;
                padding: 3px;
                padding-#{$textDirection}: 15px;
                padding-#{$textDirectionOpposite}: 0;
                width: 70%;
                float: #{$textDirection};
                bottom:initial;
              }
              lebel{
                text-transform: inherit;
                font-weight: normal;
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .price-strong{
              color: #fff;
              font-size: 20px;
              padding: 0;
            }
            .btn.unq-btn{
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              height: 52px;
              margin: 0;
              padding: 15px 0;
              bottom: initial;
              width: 30%;
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section .other-user .checkbox{
    .cr{
      display: inline;
    }
    .fake-label{
      display: inline;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  #listing-page .listing-body .content-holder .listing-holder {
    .sort-bar .sorting span.title{
      display: none;
    }
    .listing-warper .hotel-listing-item h2{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* iPhone 6+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  .text-xs-center{
    text-align: center;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile #header{
    .container:first-child{
      margin-top: 30px !important;
      .logo{
        max-width: 230px;
        #{$textDirection}:-10px;
      }
    }
    .top-nav{
      float: none !important;
      text-align: center;
      position: absolute;
      #{$textDirection}: 25px;
      #{$textDirectionOpposite}: 25px;
      top: -28px;
      .log-list{
        display: none !important;
      }
      #header_currency{
        button.dropdown-toggle{
          padding: 4px 12px !important;
          font-size: 12px;
        }
      }
      #header_language{
        button.dropdown-toggle{
          padding: 4px 4px !important;
          font-size: 12px;
          @if $isRtl == true{

          }
          @else{
            i.flagstrap-icon{
              margin-#{$textDirection}: 5px;
            }
          }
        }
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      width: 100%;
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 10px;
      + div{
        width: 100%;
        padding-#{$textDirection}: 0;
        padding-#{$textDirectionOpposite}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-100%, 0);
      }
      -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 92%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }
            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            -o-transition: all 0.25s ease 0s;
            -ms-transition: all 0.25s ease 0s;
            -webkit-transition: all 0.25s ease 0s;
            -moz-transition: all 0.25s ease 0s;
            transition: all 0.25s ease 0s;
            &.sticky-filter-now{
              .container-floating-parrent {
                position: fixed;
                top:0;
                width: 100%;
                background: #79c4e4;
                -o-transition: all 0.85s ease 0s;
                -ms-transition: all 0.85s ease 0s;
                -webkit-transition: all 0.85s ease 0s;
                -moz-transition: all 0.85s ease 0s;
                transition: all 0.85s ease 0s;
                padding: 5px 7px;
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper .hotel-listing-item{
            position: relative;
            padding: 0;
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ced0d3;
            padding-top: 12px;
            padding-#{$textDirection}: 10px;
            margin: 0 0 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            .hotel-figure{
              display: inline-block;
              position: relative;
              margin: 0 0 10px;
              height: 100px;
              width: 26%;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              font-weight: bolder;
            }
            .hotel-item-detail.item-detail{
              float: #{$textDirection};
              width: 74%;
              border: medium none;
              margin-bottom: 15px;
              @if $isRtl == true{
                .english_hotel_name{
                  display: block;
                }
              }@else{
                .english_hotel_name{
                  display: none;
                }
              }
            }
            @if $isRtl == true{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
              }
            }
            @else{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                line-height: 28px;
              }
            }
            .hotel-address{
              display: none;
            }
            .hotel-item-price{
              &.hotel-price-wrap{
                border: medium none;
                margin: 0;
                padding: 0;
                width: 100%;
              }
              .price-wrap_{
                text-align: #{$textDirection};
                display: inline-block;
                background-color: #3e3d3d;
                color: #fff;
                height: 52px;
                margin: 0;
                padding: 3px;
                padding-#{$textDirection}: 15px;
                padding-#{$textDirectionOpposite}: 0;
                width: 70%;
                float: #{$textDirection};
                bottom:initial;
              }
              lebel{
                text-transform: inherit;
                font-weight: normal;
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .price-strong{
              color: #fff;
              font-size: 20px;
              padding: 0;
            }
            .btn.unq-btn{
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              height: 52px;
              margin: 0;
              padding: 15px 0;
              bottom: initial;
              width: 30%;
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section .other-user .checkbox{
    .cr{
      display: inline;
    }
    .fake-label{
      display: inline;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  #listing-page .listing-body .content-holder .listing-holder {
    .sort-bar .sorting span.title{
      display: none;
    }
    .listing-warper .hotel-listing-item h2{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  .text-xs-center{
    text-align: center;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile #header{
    .container:first-child{
      margin-top: 30px !important;
      .logo{
        max-width: 230px;
        #{$textDirection}:-10px;
      }
    }
    .top-nav{
      float: none !important;
      text-align: center;
      position: absolute;
      #{$textDirection}: 25px;
      #{$textDirectionOpposite}: 25px;
      top: -28px;
      .log-list{
        display: none !important;
      }
      #header_currency{
        button.dropdown-toggle{
          padding: 4px 12px !important;
          font-size: 12px;
        }
      }
      #header_language{
        button.dropdown-toggle{
          padding: 4px 4px !important;
          font-size: 12px;
          @if $isRtl == true{

          }
          @else{
            i.flagstrap-icon{
              margin-#{$textDirection}: 5px;
            }
          }
        }
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      width: 100%;
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 10px;
      + div{
        width: 100%;
        padding-#{$textDirection}: 0;
        padding-#{$textDirectionOpposite}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-100%, 0);
      }
      -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 92%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }

            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            -o-transition: all 0.25s ease 0s;
            -ms-transition: all 0.25s ease 0s;
            -webkit-transition: all 0.25s ease 0s;
            -moz-transition: all 0.25s ease 0s;
            transition: all 0.25s ease 0s;
            &.sticky-filter-now{
              .container-floating-parrent {
                position: fixed;
                top:0;
                width: 100%;
                background: #79c4e4;
                -o-transition: all 0.85s ease 0s;
                -ms-transition: all 0.85s ease 0s;
                -webkit-transition: all 0.85s ease 0s;
                -moz-transition: all 0.85s ease 0s;
                transition: all 0.85s ease 0s;
                padding: 5px 7px;
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper .hotel-listing-item{
            position: relative;
            padding: 0;
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ced0d3;
            padding-top: 12px;
            padding-#{$textDirection}: 10px;
            margin: 0 0 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            .hotel-figure{
              display: inline-block;
              position: relative;
              margin: 0 0 10px;
              height: 100px;
              width: 26%;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              font-weight: bolder;
            }
            .hotel-item-detail.item-detail{
              float: #{$textDirection};
              width: 74%;
              border: medium none;
              margin-bottom: 15px;
              @if $isRtl == true{
                .english_hotel_name{
                  display: block;
                }
              }@else{
                .english_hotel_name{
                  display: none;
                }
              }
            }
            @if $isRtl == true{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
              }
            }
            @else{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                line-height: 28px;
              }
            }
            .hotel-address{
              display: none;
            }
            .hotel-item-price{
              &.hotel-price-wrap{
                border: medium none;
                margin: 0;
                padding: 0;
                width: 100%;
              }
              .price-wrap_{
                text-align: #{$textDirection};
                display: inline-block;
                background-color: #3e3d3d;
                color: #fff;
                height: 52px;
                margin: 0;
                padding: 3px;
                padding-#{$textDirection}: 15px;
                padding-#{$textDirectionOpposite}: 0;
                width: 70%;
                float: #{$textDirection};
                bottom:initial;
              }
              lebel{
                text-transform: inherit;
                font-weight: normal;
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .price-strong{
              color: #fff;
              font-size: 20px;
              padding: 0;
            }
            .btn.unq-btn{
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              height: 52px;
              margin: 0;
              padding: 15px 0;
              bottom: initial;
              width: 30%;
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section .other-user .checkbox{
    .cr{
      display: inline;
    }
    .fake-label{
      display: inline;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
  /* Styles */
  #listing-page .listing-body .content-holder .listing-holder {
    .sort-bar .sorting span.title{
      display: none;
    }
    .listing-warper .hotel-listing-item h2{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  .text-xs-center{
    text-align: center;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile #header{
    .container:first-child{
      margin-top: 30px !important;
      .logo{
        max-width: 230px;
        #{$textDirection}:-10px;
      }
    }
    .top-nav{
      float: none !important;
      text-align: center;
      position: absolute;
      #{$textDirection}: 25px;
      #{$textDirectionOpposite}: 25px;
      top: -28px;
      .log-list{
        display: none !important;
      }
      #header_currency{
        button.dropdown-toggle{
          padding: 4px 12px !important;
          font-size: 12px;
        }
      }
      #header_language{
        button.dropdown-toggle{
          padding: 4px 4px !important;
          font-size: 12px;
          @if $isRtl == true{

          }
          @else{
            i.flagstrap-icon{
              margin-#{$textDirection}: 5px;
            }
          }
        }
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      + div{
        width: 100%;
        padding-#{$textDirection}: 0;
        padding-#{$textDirectionOpposite}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-100%, 0);
      }
      -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 92%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }
              .recomended{
                display: none;
              }
            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            -o-transition: all 0.25s ease 0s;
            -ms-transition: all 0.25s ease 0s;
            -webkit-transition: all 0.25s ease 0s;
            -moz-transition: all 0.25s ease 0s;
            transition: all 0.25s ease 0s;
            &.sticky-filter-now{
              .container-floating-parrent {
                position: fixed;
                top:0;
                width: 100%;
                background: #79c4e4;
                -o-transition: all 0.85s ease 0s;
                -ms-transition: all 0.85s ease 0s;
                -webkit-transition: all 0.85s ease 0s;
                -moz-transition: all 0.85s ease 0s;
                transition: all 0.85s ease 0s;
                padding: 5px 7px;
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper .hotel-listing-item{
            position: relative;
            padding: 0;
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ced0d3;
            padding-top: 12px;
            padding-#{$textDirection}: 10px;
            margin: 0 0 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            .hotel-figure{
              display: inline-block;
              position: relative;
              margin: 0 0 10px;
              height: 100px;
              width: 26%;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              font-weight: bolder;
            }
            .hotel-item-detail.item-detail{
              float: #{$textDirection};
              width: 74%;
              border: medium none;
              margin-bottom: 15px;
              @if $isRtl == true{
                .english_hotel_name{
                  display: block;
                }
              }@else{
                .english_hotel_name{
                  display: none;
                }
              }
            }
            @if $isRtl == true{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
              }
            }
            @else{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                line-height: 28px;
              }
            }
            .hotel-address{
              display: none;
            }
            .hotel-item-price{
              &.hotel-price-wrap{
                border: medium none;
                margin: 0;
                padding: 0;
                width: 100%;
              }
              .price-wrap_{
                text-align: #{$textDirection};
                display: inline-block;
                background-color: #3e3d3d;
                color: #fff;
                height: 52px;
                margin: 0;
                padding: 3px;
                padding-#{$textDirection}: 15px;
                padding-#{$textDirectionOpposite}: 0;
                width: 70%;
                float: #{$textDirection};
                bottom:initial;
              }
              lebel{
                text-transform: inherit;
                font-weight: normal;
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .price-strong{
              color: #fff;
              font-size: 20px;
              padding: 0;
            }
            .btn.unq-btn{
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              height: 52px;
              margin: 0;
              padding: 15px 0;
              bottom: initial;
              width: 30%;
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section .other-user .checkbox{
    .cr{
      display: inline;
    }
    .fake-label{
      display: inline;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
  /* Styles */
  #listing-page .listing-body .content-holder .listing-holder {
    .sort-bar .sorting span.title{
      display: none;
    }
    .listing-warper .hotel-listing-item h2{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

/* Apple iphone x ----------- */
@media only screen and (device-width : 375px) and (device-height : 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio : 3){
  /* Styles */
  .text-xs-center{
    text-align: center;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    #menu{
      transform: translate(-120%, 0);
    }
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile #header{
    .container:first-child{
      margin-top: 30px !important;
      .logo{
        max-width: 230px;
        #{$textDirection}:-10px;
      }
    }
    .top-nav{
      float: none !important;
      text-align: center;
      position: absolute;
      #{$textDirection}: 25px;
      #{$textDirectionOpposite}: 25px;
      top: -28px;
      .log-list{
        display: none !important;
      }
      #header_currency{
        button.dropdown-toggle{
          padding: 4px 12px !important;
          font-size: 12px;
        }
      }
      #header_language{
        button.dropdown-toggle{
          padding: 4px 4px !important;
          font-size: 12px;
          @if $isRtl == true{

          }
          @else{
            i.flagstrap-icon{
              margin-#{$textDirection}: 5px;
            }
          }
        }
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      + div{
        width: 100%;
        padding-#{$textDirection}: 0;
        padding-#{$textDirectionOpposite}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      transform: translate(-120%, 0);
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-100%, 0);
      }
      -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 92%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }
              .recomended{
                display: none;
              }
            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            -o-transition: all 0.25s ease 0s;
            -ms-transition: all 0.25s ease 0s;
            -webkit-transition: all 0.25s ease 0s;
            -moz-transition: all 0.25s ease 0s;
            transition: all 0.25s ease 0s;
            &.sticky-filter-now{
              .container-floating-parrent {
                position: fixed;
                top:0;
                width: 100%;
                background: #79c4e4;
                -o-transition: all 0.85s ease 0s;
                -ms-transition: all 0.85s ease 0s;
                -webkit-transition: all 0.85s ease 0s;
                -moz-transition: all 0.85s ease 0s;
                transition: all 0.85s ease 0s;
                padding: 5px 7px;
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper .hotel-listing-item{
            position: relative;
            padding: 0;
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ced0d3;
            padding-top: 12px;
            padding-#{$textDirection}: 10px;
            margin: 0 0 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            .hotel-figure{
              display: inline-block;
              position: relative;
              margin: 0 0 10px;
              height: 100px;
              width: 26%;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              font-weight: bolder;
            }
            .hotel-item-detail.item-detail{
              float: #{$textDirection};
              width: 74%;
              border: medium none;
              margin-bottom: 15px;
              @if $isRtl == true{
                .english_hotel_name{
                  display: block;
                }
              }@else{
                .english_hotel_name{
                  display: none;
                }
              }
            }
            @if $isRtl == true{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
              }
            }
            @else{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                line-height: 28px;
              }
            }
            .hotel-address{
              display: none;
            }
            .hotel-item-price{
              &.hotel-price-wrap{
                border: medium none;
                margin: 0;
                padding: 0;
                width: 100%;
              }
              .price-wrap_{
                text-align: #{$textDirection};
                display: inline-block;
                background-color: #3e3d3d;
                color: #fff;
                height: 52px;
                margin: 0;
                padding: 3px;
                padding-#{$textDirection}: 15px;
                padding-#{$textDirectionOpposite}: 0;
                width: 70%;
                float: #{$textDirection};
                bottom:initial;
              }
              lebel{
                text-transform: inherit;
                font-weight: normal;
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .price-strong{
              color: #fff;
              font-size: 20px;
              padding: 0;
            }
            .btn.unq-btn{
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              height: 52px;
              margin: 0;
              padding: 15px 0;
              bottom: initial;
              width: 30%;
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section .other-user .checkbox{
    .cr{
      display: inline;
    }
    .fake-label{
      display: inline;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}

@media only screen and (device-width : 375px) and (device-height : 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio : 3){
  /* Styles */
  #listing-page .listing-body .content-holder .listing-holder {
    .sort-bar .sorting span.title{
      display: none;
    }
    .listing-warper .hotel-listing-item h2{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}


/* Samsung Galaxy S8 Plus ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 740px) and (orientation: portrait) {
  /* Styles */
  #listing-page .listing-body .content-holder .listing-holder {
    .sort-bar .sorting span.title{
      display: none;
    }
    .listing-warper .hotel-listing-item h2{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
@media only screen and (min-device-width: 360px) and (max-device-height: 740px) and (orientation: landscape) {
  /* Styles */
  .text-xs-center{
    text-align: center;
  }
  .row-xs{
    &:before,&:after{
      content: " ";
      display: table;
    }
    &:after{
      clear: both;
    }
  }
  .no-padding-xs{
    padding: 0;
  }
  /*===== loader search =====*/
  .loader-search{
    width: 90%;
  }
  /*== mobile menu style start ==*/
  .mobile-menu {
    z-index: 2;
    position: relative;
    display: block;
    #menu{
      transform: translate(-120%, 0);
    }
    &+.top-nav{
      .log-list{
        display: none !important;
      }
    }
    &.oppenned{
      .loged-user{
        .profile-holder{
          .p_image,.username{
            display: inline-block;
          }
          .username{
            width: 40%;
            color: #fff;
          }
          i{
            color: #fff;
          }
        }
        .open{
          .profile-holder{
            .username{
              color: $secondry-color;
            }
            i{
              color: $secondry-color;
            }
          }
          .line-profile{
            position: absolute;
            top: 1px;
            width: 100%;
          }
          @if $isRtl == true{
            .dropdown-menu{
              li>a{
                text-align: #{$textDirection};
              }
            }
          }
        }
      }
    }
  }
  .cookie-container-bar{
    .cookie-btn{
      a{
        display: block;
      }
    }
  }
  #main{
    padding: 0 0 30px;
  }
  .ae-mobile #header{
    .container:first-child{
      margin-top: 30px !important;
      .logo{
        max-width: 230px;
        #{$textDirection}:-10px;
      }
    }
    .top-nav{
      float: none !important;
      text-align: center;
      position: absolute;
      #{$textDirection}: 25px;
      #{$textDirectionOpposite}: 25px;
      top: -28px;
      .log-list{
        display: none !important;
      }
      #header_currency{
        button.dropdown-toggle{
          padding: 4px 12px !important;
          font-size: 12px;
        }
      }
      #header_language{
        button.dropdown-toggle{
          padding: 4px 4px !important;
          font-size: 12px;
          @if $isRtl == true{

          }
          @else{
            i.flagstrap-icon{
              margin-#{$textDirection}: 5px;
            }
          }
        }
      }
    }
  }
  #nav{display: none;}
  .search-deals .left-content,
  .search-deals .right-content,
  .listings-holder .other-packages,
  #footer .footer-holder .app-holder .form-holder,
  #footer .footer-holder .app-holder .code-listing-holder{
    width: 100%;
  }
  .selects-holder > div{
    padding: 0;
  }
  .search-content .nav-tabs li{
    width: 47%;
  }
  .can-section .coming_content h1 {
    font-size: 1.625em;
  }
  .search-deals .left-content .search-holder .search-footer{
    strong{
      font-size: 10px;
    }
    .subscribe-form .btn-signup{
      top: 2px;
    }
  }
  .search-deals .left-content .info-text-free h3 {
    font-size: 20px;
  }
  .search-deals .left-content .just-booked-holder .bx-wrapper .bx-viewport .booked-post .booked-post-content{
    h3{
      line-height: 14px;
      font-size: 10px;
    }
    p{
      font-size: 9px;
    }
  }
  .subscribe-holder{
    margin:0 auto 30px;
  }
  .subs-{
    &email,&check{
      padding: 0 5px;
    }
    &check{
      margin-bottom: 15px;
    }

  }
  .subscribe-section .btn-submit,
  .subs-btn{
    text-align: center;
    float: none;
  }
  .form-holder, .form-holder h2 {
    float: none;
    width: 100% ;
    font-size: 18px;
    text-align: center;
  }
  .love-travels-holder,
  .cities-holder,
  .discover-holder,
  .express-deals,
  .app-holder .form-holder > p,
  .app-holder .form-holder > .two-forms,
  .subscribe-nav ul{
    display: none;
  }
  .search-deals .left-content .search-holder .search-content .tab-content .tab-holder .search-form .selects-holder .select-col-childern{
    width: 23.30%;
  }
  #footer .footer-holder .copy-right-holder{
    font-size: 12px;
    line-height: 15px;
  }
  .ui-menu {
    width: 92% !important;
  }
  .ui-datepicker{
    width: 92% !important;
    .ui-datepicker-group{
      width: 100%;
    }
  }
  /*======================= hotel listing ========================*/
  .hotel-listing{
    .destination{
      padding-#{$textDirectionOpposite}: 0;
      margin-bottom: 5px;
    }
    ._check-in{
      padding-#{$textDirection}: 0;
      padding-#{$textDirectionOpposite}: 5px;
      margin-bottom: 5px;
    }
    ._check-out{
      padding-#{$textDirection}: 5px;
      padding-#{$textDirectionOpposite}: 0px;
      margin-bottom: 5px;
    }
    .room-occupanc{
      width: 25%;
      padding-#{$textDirection}: 0;
      margin-bottom: 10px;
      + div{
        width: 16.6666666667%;
        padding-#{$textDirection}: 0;
      }
    }
  }
  .search-form .top_seach_panel .room-occupanc .customize-form{
    width: 100%;
  }
  #listing-page{
    -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    .main-listing-data{
      .listing-body > .content-holder{
        width: 100%;
      }
    }
    .filter-open{

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      #container-floating{
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        float: #{$textDirectionOpposite};
        #floating-button{
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
      }
    }
    #sidebar{
      width: 100%;
      transform-origin: 0% 0%;
      @if $isRtl == true{
        transform: translate(100%, 0);
      }@else{
        transform: translate(-120%, 0);
      }
      -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      position: absolute;
      z-index: 1;
      &.hidden-filter-mobile{
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -ms-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -moz-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transform: none;
        width: 100%;
        #{$textDirection}:initial;
      }
    }
    .listing-body{
      .content-holder{
        width: 100%;
        .visiting-reasons{
          img{
            height: auto !important;
            max-width: 92%;
            display: block;
            margin: 0 auto;
          }
        }
        .listing-holder {
          .sort-bar {
            .sorting{
              @if $isRtl == true{
                float: #{$textDirection};
              }
              .sort-btn > a{
                padding: 7px;
                margin: 0;
              }
            }
            .checkbox label > i.lbl-toggle{
              width: 80px;
              height: 32px;
              @if $isRtl == true{
                background-position: 4px;
              }@else{
                background-position: 45px;
              }
              #{$textDirectionOpposite}:12px;
              top:4px;
            }
            -o-transition: all 0.25s ease 0s;
            -ms-transition: all 0.25s ease 0s;
            -webkit-transition: all 0.25s ease 0s;
            -moz-transition: all 0.25s ease 0s;
            transition: all 0.25s ease 0s;
            &.sticky-filter-now{
              .container-floating-parrent {
                position: fixed;
                top:0;
                width: 100%;
                background: #79c4e4;
                -o-transition: all 0.85s ease 0s;
                -ms-transition: all 0.85s ease 0s;
                -webkit-transition: all 0.85s ease 0s;
                -moz-transition: all 0.85s ease 0s;
                transition: all 0.85s ease 0s;
                padding: 5px 7px;
                z-index:2;
                #{$textDirection}: 0;
                & > #container-floating #floating-button{
                  top: 0;
                }
              }
            }
          }
          .listing-warper .hotel-listing-item{
            position: relative;
            padding: 0;
            background: #fff none repeat scroll 0 0;
            border: 1px solid #ced0d3;
            padding-top: 12px;
            padding-#{$textDirection}: 10px;
            margin: 0 0 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            .hotel-figure{
              display: inline-block;
              position: relative;
              margin: 0 0 10px;
              height: 100px;
              width: 26%;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              font-weight: bolder;
            }
            .hotel-item-detail.item-detail{
              float: #{$textDirection};
              width: 74%;
              border: medium none;
              margin-bottom: 15px;
              @if $isRtl == true{
                .english_hotel_name{
                  display: block;
                }
              }@else{
                .english_hotel_name{
                  display: none;
                }
              }
            }
            @if $isRtl == true{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
              }
            }
            @else{
              h2{
                letter-spacing: 0.5px;
                margin: 0;
                line-height: 28px;
              }
            }
            .hotel-address{
              display: none;
            }
            .hotel-item-price{
              &.hotel-price-wrap{
                border: medium none;
                margin: 0;
                padding: 0;
                width: 100%;
              }
              .price-wrap_{
                text-align: #{$textDirection};
                display: inline-block;
                background-color: #3e3d3d;
                color: #fff;
                height: 52px;
                margin: 0;
                padding: 3px;
                padding-#{$textDirection}: 15px;
                padding-#{$textDirectionOpposite}: 0;
                width: 70%;
                float: #{$textDirection};
                bottom:initial;
              }
              lebel{
                text-transform: inherit;
                font-weight: normal;
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .price-strong{
              color: #fff;
              font-size: 20px;
              padding: 0;
            }
            .btn.unq-btn{
              border-radius: 0;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              height: 52px;
              margin: 0;
              padding: 15px 0;
              bottom: initial;
              width: 30%;
            }
          }
          .applywrapper .seached_tag > span > .close {
            float: #{$textDirectionOpposite};
          }
        }
      }
    }
    &.map_view{
      aside.filter-warper.hidden-filter .filter-toggle-btn{
        display: none;
      }
      .checkbox label > i.lbl-toggle{
        width: 90px;
        height: 32px;
        @if $isRtl ==true{
          background-position: 4px;
        }@else{
          background-position: 55px;
        }
      }
      .listing-body{
        &.withoutfilter{
          .map-view-wrap{
            width: 100%;
          }
          .sort-bar .sorting > .sorting-mobile{
            display: none;
          }
          .listing-warper{
            display: none;
          }
        }
        @if $isRtl == false{
          .listing-warper{
            display: none;
          }
        }
      }
    }
  }
  /*======================= hotel page ========================*/

  .hotel-page{
    .hotel-title{
      text-align: center;
    }
    .address-loc{
      text-align: center;
    }
    .pagecontainer2 {
      .cstyle10{
        height:99px;
      }
      .sticky-book-now > li {
        width: 33.33%;
        text-align: center;
      }
      .nav-tabs > li{
        width: 33.33%;
        text-align: center;
        white-space: nowrap;
      }
      .text-show,.text-show ~ span{
        color: #00349a;
        font-weight: bold;
      }
    }
    .total-value{
      float: #{$textDirection};
      margin-top: 2px;
    }
  }
  .ae-mobile{
    .arabic-row{
      div:nth-of-type(2){
        padding:0;
      }
      .room.row{
        >div:first-child{
          padding-#{$textDirectionOpposite}: 0;
          padding-#{$textDirection}: 7px;
        }
      }
    }
    .hotel-page {
      .pagecontainer2{
        .has-value{
          .booknow-new{
            width: 50%;
            &.progress-striped {
              height: 60px;
            }
            a .badge-container .counter{
              margin-#{$textDirection}: 0px;
            }
          }
        }
        .booknow-new{
          @if $isRtl == true{
            padding: 5px 15px 0px;
          }
          a {
            @if $isRtl == true{
              display: inline-block;
            }
            .badge-container .counter{
              margin-#{$textDirection}: 6px;
            }
          }
        }
        .tab-content .new-room-table .arabic-row > div:nth-of-type(2) .room.row {
          .room-grouping-whats-included ul{
            width: 100%;
          }
          .booking-types {
            padding-#{$textDirection}: 0;
            ul > li{
              width: 100%;
            }
          }
          div:nth-of-type(4){
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  /*======================= booking page ========================*/
  .tabs-steps form .content-wrap section .booking-wraper .booking-section .other-user .checkbox{
    .cr{
      display: inline;
    }
    .fake-label{
      display: inline;
    }
  }
  .tabs-steps nav ul li.tab-current{
    z-index: 1;
  }
}
